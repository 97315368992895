@charset "utf-8";

/*=================================
  pager
=================================*/
.pager {
  padding: 30px 0 0;
  &List {
    display: flex;
    justify-content: center;
    &__item {
      margin-right: 6px;
      a,span {
        font-size: 1.5rem;
        line-height: 1;
        padding: 13px 16px;
        display: block;
      }
      a {
        color: $color-theme;
        text-decoration: none;
        background: $color-theme-light;
        @include mq-desktop {
          &:hover {
            color: #fff;
            background: $color-theme;
          }
        }
      }
      span {
        color: #fff;
        background: $color-theme;
      }
    }
  }
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    .pages {
      font-size: 1.5rem;
      line-height: 1;
      padding: 13px 16px;
      margin-right: 6px;
      border: 1px solid $color-theme;
    }
    .current {
      font-size: 1.5rem;
      line-height: 1;
      color: #fff;
      padding: 13px 16px;
      background: $color-theme;
      display: block;
      text-decoration: none;
      border: 1px solid $color-theme;
    }
    .page {
      font-size: 1.5rem;
      color: $color-theme;
      line-height: 1;
      padding: 13px 16px;
      display: block;
      background: $color-theme-light;
      text-decoration: none;
      margin-left: 6px;
      border: 1px solid $color-theme-light;
      @include mq-desktop {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
    .nextpostslink {
      font-size: 1.5rem;
      color: $color-theme;
      line-height: 1;
      padding: 13px 16px;
      display: block;
      background: #fff;
      text-decoration: none;
      margin-left: 6px;
      border: 1px solid $color-theme;
      @include mq-desktop {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
    .previouspostslink {
      font-size: 1.5rem;
      color: $color-theme;
      line-height: 1;
      padding: 13px 16px;
      display: block;
      background: #fff;
      text-decoration: none;
      border: 1px solid $color-theme;
      @include mq-desktop {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    &List {
      justify-content: center;
      &__item {
        margin-right: 8px;
        a,span {
          padding: 11px 14px;
        }
      }
    }
    .wp-pagenavi {
      .pages {
        padding: 11px 14px;
        margin-right: 8px;
      }
      .current {
        padding: 11px 14px;
      }
      .page {
        padding: 11px 14px;
        margin-left: 8px;
      }
      .nextpostslink {
        padding: 11px 14px;
        margin-left: 8px;
      }
      .previouspostslink {
        padding: 11px 14px;
      }
    }
  }
}