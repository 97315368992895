@charset "utf-8";
/*=================================
  page--article
=================================*/
@mixin singleStyle-base {
  @for $i from 1 through 6 {
    h#{$i}{
      clear: both;
    }
  }
  p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.625;
    & + p:not([class]) {
      margin-top: 20px;
    }
  }
  h1:not([class]) {
    font-size: 3.4rem;
    font-weight: bold;
    line-height: 1.47058;
    margin-top: 80px;
    margin-bottom: 30px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 40px;
      display: inline-block;
      background: $color-theme;
      background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  h2:not([class]) {
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 1.47058;
    margin-top: 80px;
    margin-bottom: 30px;
    padding: 31px 30px;
    background: $color-theme-light;
    width: calc(100% + vw(80));
    margin-left: vw(-40);
    box-sizing: border-box;
  }
  h3:not([class]) {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.47058;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 40px;
      display: inline-block;
      background: $color-theme;
      background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  h4:not([class]) {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    padding-bottom: 18px;
    margin-top: 60px;
    margin-bottom: 30px;
    border-bottom: 1px dashed #D2D2D2;
    position: relative;
    &::after {
      content: "";
      width: 27%;
      height: 3px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      bottom: -1px;
    }
  }
  h5:not([class]) {
    font-size: 1.8rem;
    font-weight: bold;
    color: $color-theme;
    line-height: 1.44444;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  h6:not([class]) {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.363636;
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-left: 5px solid $color-theme;
  }
  blockquote {
    position: relative;
    padding: 40px 20px 20px;
    margin: 50px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid $color-theme-light;
    clear: both;
    &::before {
      content: "“";
      font-size: 7rem;
      font-weight: bold;
      color: $color-theme;
      display: inline-block;
      font-style: normal;
      width: 50px;
      height: 50px;
      line-height: 1.3;
      text-align: center;
      background: $color-theme-light;
      border-radius: 50px;
      position: absolute;
      top: -25px;
      left: 15px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        margin: 0 0 20px!important;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: .06em;
        position: relative;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]) {
    display: block;
    margin: 15px auto 20px;
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
    &.aligncenter {
      margin: 30px auto;
      display: block;
    }
  }
  ul:not([class]) {
    margin: 60px 0 36px;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.625;
      padding-left: 40px;
      position: relative;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: inline-block;
        background: url(../img/icon/icon_li.png) no-repeat center center $color-theme-light;
        background-size: 7px auto;
        background-position: 12px 10px;
        border-radius: 50px;
        position: absolute;
        top: -1px;
        left: 0;
      }
      & + li {
        margin-top: 26px;
      }
    }
    h6::before {
      content: none;
    }
  }
  ol:not([class]) {
    margin: 60px 0 36px;
    counter-reset: listNum;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.625;
      padding: 17px 10px 17px 76px;
      background: #fff;
      box-shadow: 0 10px 26px rgba(0, 0, 0, .06);
      position: relative;
      &::before {
        counter-increment: listNum;
        content: "0"counter(listNum);
        font-size: 1.6rem;
        font-weight: 400;
        color: $color-theme;
        width: 60px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1;
      }
      &::after {
        content: "";
        width: 60px;
        height: 100%;
        display: inline-block;
        background: $color-theme-light;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        z-index: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
    h6::before {
      content: none;
    }
  }
  & > table {
    table-layout: fixed;
  }
  @include mq-sp {
    p:not([class]) {
      font-size: 1.6rem;
      line-height: 1.75;
      & + p:not([class]) {
        margin-top: 25px;
      }
    }
    h1:not([class]) {
      font-size: 2.2rem;
      line-height: 1.27272;
      margin-top: 40px;
      margin-bottom: 20px;
      &::before {
        width: 4px;
        height: 30px;
        top: -2px;
      }
    }
    h2:not([class]) {
      font-size: 2rem;
      line-height: 1.4;
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 20px per(15);
      width: calc(100% + per(30) + 2px);
      margin-left: calc(per(-15) - 1px);
    }
    h3:not([class]) {
      font-size: 2rem;
      line-height: 1.27272;
      margin-top: 40px;
      margin-bottom: 20px;
      &::before {
        width: 4px;
        height: 30px;
        top: -2px;
      }
    }
    h4:not([class]) {
      font-size: 1.8rem;
      line-height: 1.27272;
      padding-bottom: 12px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    h5:not([class]) {
      font-size: 1.7rem;
      line-height: 1.27272;
      margin-bottom: 20px;
    }
    h6:not([class]) {
      font-size: 1.6rem;
      padding-left: 8px;
      border-left: 3px solid $color-theme;
    }
    blockquote {
      padding: 50px per(15) 20px;
      margin: 40px 0 30px;
      clear: both;
      &::before {
        font-size: 5rem;
        width: 40px;
        height: 40px;
        top: -20px;
        left: per(15);
      }
    }
    img {
      &.alignleft {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
      &.alignright {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
      &.aligncenter {
        margin: 15px auto 20px;
      }
    }
    ul:not([class]) {
      margin: 40px 0 20px;
      li {
        font-size: 1.4rem;
        padding-left: 35px;
        &::before {
          width: 25px;
          height: 25px;
          background-size: 7px auto;
          background-position: 10px 8px;
        }
        & + li {
          margin-top: 20px;
        }
      }
    }
    ol:not([class]) {
      margin: 40px 0 20px;
      li {
        font-size: 1.4rem;
        padding: 11px 10px 11px 57px;
        box-shadow: 0 6px 16px rgba(0, 0, 0, .03);
        &::before {
          font-size: 1.4rem;
          width: 45px;
        }
        &::after {
          width: 45px;
        }
        & + li {
          margin-top: 7px;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}
@mixin singleStyle-parts {
  /*---------------------
    メインビジュアル
  ---------------------*/
  .articleMv {
    margin: 30px auto 40px;
    text-align: center;
  }
  /*---------------------
    目次
  ---------------------*/
  .tocBox {
    margin: 40px auto 60px;
    &__inner {
      background: $bg-color;
      padding: vw(30);
    }
    &__ttl {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 30px;
      text-align: center;
      &::before {
        content: "";
        width: 5px;
        height: 24px;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        vertical-align: middle;
        margin: -2px 11px 0 0;
      }
    }
    .tocList {
      &__item {
        position: relative;
        &::before {
          content: "";
          width: 9px;
          height: 14px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 6px;
          left: 0;
        }
        a {
          font-size: 1.6rem;
          font-weight: bold;
          text-decoration: none;
          display: inline-block;
          padding: 0 0 0 20px;
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
              color: $color-theme;
            }
          }
        }
        & + .tocList__item {
          margin-top: 10px;
        }
        .tocList {
          padding-left: 26px;
          margin-top: 10px;
          &__item {
            & + .tocList__item {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .tocMore {
      font-size: 1.5rem;
      font-weight: 500;
      color: #fff;
      background: $color-theme;
      text-align: center;
      margin-top: 20px;
      padding: 8px 5px;
      cursor: pointer;
      &::after {
        content: "";
        width: 9px;
        height: 14px;
        display: inline-block;
        background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
        background-size: contain;
        margin-left: 10px;
        transform: translateY(2px) rotate(-90deg);
      }
      @include mq-desktop {
        &:hover {
          color: $color-theme;
          background: #fff;
          &::after {
            width: 14px;
            height: 9px;
            background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
            background-size: contain;
            transform: rotate(-180deg);
          }
        }
      }
    }
    &:not(.open) {
      .tocBox__inner {
        > .tocList > .tocList__item:nth-child(n + 4) {
          display: none;
        }
      }
      .tocMore {
        &::after {
          transform: translateY(2px) rotate(90deg);
        }
        @include mq-desktop {
          &:hover {
            &::after {
              transform: translateY(0px);
            }
          }
        }
      }
    }
  }
  /*---------------------
    こちらも読まれています
  ---------------------*/
  .relrated {
    margin: 60px 0;
    &__ttl {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 20px;
      padding-left: 17px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 24px;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
    &List {
      &__item {
        background: #fff;
        box-shadow: 0 10px 26px rgba(0, 0, 0, .05);
        .itemWrap {
          padding: 30px;
          display: flex;
          align-items: flex-start;
          @include mq-tab {
            padding: vw(30);
          }
          .thumb {
            width: 33%;
            max-width: 260px;
            margin-right: 30px;
            @include mq-tab {
              margin-right: vw(30);
            }
            img {
              margin: 0 auto;
            }
            & + .textWrap {
              width: calc(100% - (33% + 30px));
              @include mq-tab {
                width: calc(100% - (33% + vw(30)));
              }
            }
          }
          .textWrap {
            width: 100%;
            &__ttl {
              font-size: 2rem;
              font-weight: bold;
              line-height: 1.5;
              text-decoration: none;
              display: block;
              margin-bottom: 5px;
            }
            &__text {
              font-size: 1.6rem;
              line-height: 1.625;
            }
          }
        }
        @include mq-desktop {
          &:hover {
            background: $color-theme-light;
          }
        }
        & + .relratedList__item {
          margin-top: 10px;
        }
      }
    }
  }
  /*---------------------
    チェックポイント
  ---------------------*/
  .checkPoint {
    border: 1px dashed #D2D2D2;
    background: #fff;
    padding: 40px vw(40) 30px;
    margin: 60px 0;
    ul, ol {
      margin: 0;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        font-size: 1.8rem;
        font-weight: bold;
        color: $color-theme;
        line-height: 1.44444;
        margin: 0 0 20px;
        padding: 0;
        background: transparent;
        border: none;
        &::before,&::after {
          content: none;
        }
      }
    }
  }
  @include mq-sp {
    /*---------------------
      メインビジュアル
    ---------------------*/
    .articleMv {
      margin: 20px auto;
    }
    /*---------------------
      目次
    ---------------------*/
    .tocBox {
      margin: 40px auto;
      &__inner {
        padding: 30px per(17);
      }
      &__ttl {
        font-size: 1.8rem;
        line-height: 1;
        margin-bottom: 20px;
        &::before {
          height: 22px;
        }
      }
      .tocMore {
        font-size: 1.4rem;
      }
    }
    /*---------------------
    こちらも読まれています
    ---------------------*/
    .relrated {
      margin: 60px 0;
      &__ttl {
        font-size: 2rem;
        line-height: 1.27272;
        margin-top: 40px;
        margin-bottom: 20px;
        &::before {
          width: 4px;
          height: 30px;
          top: -2px;
        }
      }
      &List {
        &__item {
          box-shadow: 0 6px 16px rgba(0, 0, 0, .06);
          .itemWrap {
            padding: 15px per(14);
            .thumb {
              width: 37%;
              max-width: 140px;
              margin-right: per(20);
              & + .textWrap {
                width: calc(100% - (37% + per(20)));
              }
            }
            .textWrap {
              &__ttl {
                font-size: 1.6rem;
                margin-bottom: 10px;
              }
              &__text {
                font-size: 1.3rem;
              }
              &__date {
                font-size: 1.2rem;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    /*---------------------
      チェックポイント
    ---------------------*/
    .checkPoint {
      padding: 25px per(14) 30px;
      margin: 30px auto;
    }
  }
}

/* 記事一覧 */
.articleList {
  border-top: 1px dashed #D2D2D2;
  &__item {
    border-bottom: 1px dashed #D2D2D2;
    @include mq-desktop {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    .itemWrap {
      padding: 30px 40px 30px 0;
      display: flex;
      align-items: flex-start;
      @include mq-tab {
        padding: 30px vw(40) 30px 0;
      }
      .thumb {
        width: 34%;
        max-width: 300px;
        margin-right: 40px;
        @include mq-tab {
          margin-right: vw(40);
        }
        img {
          margin: 0 auto;
        }
        & + .textWrap {
          width: calc(100% - (34% + 40px));
          @include mq-tab {
            width: calc(100% - (34% + vw(40)));
          }
        }
      }
      .textWrap {
        width: 100%;
        &__ttl {
          font-size: 2rem;
          font-weight: bold;
          line-height: 1.5;
          text-decoration: none;
          display: block;
          margin-bottom: 20px;
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.625;
        }
        &__date {
          font-size: 1.6rem;
          color: #D2D2D2;
          display: block;
          text-align: right;
          margin-top: 10px;
        }
      }
    }
  }
}
.articleListWrap {
  padding: 18px 0 30px;
  @include singleStyle-base;
  @for $i from 1 through 6 {
    & > h#{$i}:first-child {
      margin-top: 0!important;
    }
  }
  &__in {
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
  }
}

/* singlePage */
.singlePage {
  padding: 17px 0 90px;
  @include singleStyle-base;
  @include singleStyle-parts;
  @for $i from 1 through 6 {
    & > h#{$i}:first-child {
      margin-top: 0!important;
    }
  }
  &__in {
    padding: 40px vw(40) 60px;
    background: #fff;
    border: 1px solid #CCC;
    border-top: 6px solid $color-theme;
    .clinicWrap,.areaBox {
      width: calc(100% + vw(80));
      margin-left: vw(-40);
    }
  }
}

/* 記事詳細ページ */
.articleDetail {
  padding: 40px vw(40) 40px;
  background: #fff;
  border: 1px solid #CCCCCC;
  border-top: 6px solid $color-theme;
  &__in {
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
  .clinicWrap,.areaBox {
    width: calc(100% + vw(80));
    margin-left: vw(-40);
  }
  @include singleStyle-parts;
}

@media screen and (max-width: $display-width-s) {
  /* singlePage */
  .singlePage {
    padding: 0;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        width: calc(100% - per(30));
        margin: 0 auto 20px;
      }
    }
    & > h2:first-child {
      width: 100%;
      margin-left: 0;
    }
    &__in {
      padding: 30px per(15) 40px;
      .clinicWrap,.areaBox {
        width: calc(100% + per(30) + 2px);
        margin-left: calc(per(-15) - 1px);
      }
    }
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 30px per(15) 40px;
    .clinicWrap,.areaBox {
      width: calc(100% + per(30) + 2px);
      margin-left: calc(per(-15) - 1px);
    }
    .clinicWrap {
      .btn--search {
        width: calc(100% - per(30));
        margin: 0 auto;
      }
    }
  }

  /* 記事一覧 */
  .articleList {
    &__item {
      .itemWrap {
        padding: 20px 0;
        .thumb {
          width: 37%;
          max-width: 140px;
          margin-right: per(20);
          img {
            margin: 0 auto;
          }
          & + .textWrap {
            width: calc(100% - (37% + per(20)));
          }
        }
        .textWrap {
          &__ttl {
            font-size: 1.6rem;
            margin-bottom: 10px;
          }
          &__text {
            font-size: 1.3rem;
          }
          &__date {
            font-size: 1.2rem;
            margin-top: 8px;
          }
        }
      }
    }
  }
  /* 記事一覧ページ */
  .articleListWrap {
    padding: 0 per(15);
    &__in {
      .pager {
        @for $i from 1 through 6 {
          & + h#{$i}{
            margin-top: 50px;
          }
        }
      }
    }
  }
}