@charset "utf-8";
/*=================================
  clinic
=================================*/
.clinicList {
  &__item {
    border-top: 6px solid $color-theme;
    background: #fff;
    padding: 45px vw(40);
    .tagList {
      display: flex;
      flex-wrap: wrap;
      &__item {
        font-size: 1.3rem;
        font-weight: 400;
        color: $color-theme;
        padding: 3px 11px;
        border: 1px solid $color-theme;
        border-radius: 3px;
        margin: 0 10px 14px 0;
        &.menu {
          color: #fff;
          background-color: $color-theme;
        }
      }
      & + .caption {
        margin-top: 10px;
      }
    }
    .caption {
      font-size: 1.6rem;
      margin-bottom: 10px;
    }
    .name {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.26666;
      a {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.26666;
        text-decoration: none;
        @include mq-desktop {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .label {
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: .04em;
      color: #fff;
      background: $color-red;
      display: inline-block;
      padding: 2px 6px 0px 8px;
      margin-left: 4px;
      vertical-align: top;
    }
    .contWrap {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      &__left {
        width: 46%;
        max-width: 385px;
      }
      &__right {
        width: calc(55% - vw(40));
        margin-left: vw(40);
      }
      .thumb {
        margin-bottom: 20px;
        img {
          margin: 0 auto;
        }
      }
      .contact {
        &__ttl {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 10px;
          padding-left: 9px;
          position: relative;
          &::before {
            content: "";
            width: 4px;
            height: 18px;
            display: inline-block;
            background: $color-theme;
            background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
            background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
            background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
            position: absolute;
            top: -2px;
            left: 0;
          }
        }
        .tel {
          font-size: 3.8rem;
          line-height: 1;
          font-weight: bold;
          text-decoration: none;
          text-align: center;
          display: block;
          background: $bg-color;
          padding: 15px 5px 5px;
          @include mq-tab {
            font-size: vw(38);
          }
          @include mq-desktop {
            pointer-events: none;
          }
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            display: inline-block;
            background: url(../img/icon/icon_tel.png) no-repeat center center;
            background-size: contain;
            margin: 0 14px 0 0;
            @include mq-tab {
              width: vw(24);
              height: vw(24);
              margin: 0 vw(14) 0 0;
            }
          }
        }
        .receptionTime {
          font-size: 1.3rem;
          display: flex;
          justify-content: center;
          background: $bg-color;
          padding: 5px 30px 13px;
          dt {
            font-weight: bold;
            width: 5em;
          }
          dd {
            width: calc(100% - 5em);
          }
        }
      }
      .feature {
        background: #fff;
        margin: 0 0 20px;
        &__row {
          border: 1px solid #D2D2D2;
        }
        &__ttl {
          font-size: 1.7rem;
          font-weight: bold;
          color: $color-red;
          background: #FBF2F2;
          width: 120px;
          text-align: center;
          padding: 12px 10px;
          border: none;
        }
        &__item {
          font-size: 1.8rem;
          font-weight: bold;
          color: $color-red;
          width: calc(100% - 120px);
          text-align: center;
          padding: 12px 10px;
          border: none;
        }
      }
      .info {
        background: #fff;
        border: none;
        margin: 0;
        &__row {
          border-bottom: 1px dashed #D2D2D2;
        }
        &__ttl {
          font-weight: 400;
          color: $font-color-base;
          background: $bg-color;
          width: 120px;
          text-align: center;
          padding: 12px 10px;
          border: none;
        }
        &__item {
          width: calc(100% - 120px);
          text-align: left;
          padding: 12px 10px;
          border: none;
        }
      }
      .btnWrap {
        margin: 20px 0 0;
        @include mq-tab {
          flex-wrap: wrap;
          > a {
            width: 100%;
            + a {
              margin: 10px 0 0;
            }
          }
        }
      }
      .btn--more {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding: 20px 10px 16px;
        border-radius: 4px;
        background: rgb(54,99,154);
        background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
        box-shadow: 0px 4px 0 0px #26456B;
        width: 45%;
        display: block;
        @include animation-base(all,0s);
        &::before {
          content: "";
          width: 8px;
          height: 13px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
          background-size: contain;
          margin: 0 10px -2px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
      .btn--mail {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding: 20px 10px 16px;
        border-radius: 4px;
        background: rgb(171,155,78);
        background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
        box-shadow: 0px 4px 0 0px #776D37;
        width: 55%;
        display: block;
        @include animation-base(all,0s);
        &::before {
          content: "";
          width: 18px;
          height: 13px;
          display: inline-block;
          background: url(../img/icon/icon_mail.png) no-repeat center center;
          background-size: contain;
          margin: 0 14px -2px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
      .btn--reserve {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding: 20px 10px 16px;
        border-radius: 4px;
        background: rgb(54,99,154);
        background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
        box-shadow: 0px 4px 0 0px #26456B;
        width: 45%;
        display: block;
        @include animation-base(all,0s);
        &::before {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url(../img/icon/icon_calendar.png) no-repeat center center;
          background-size: contain;
          margin: 0 10px -2px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $display-width-s) and (max-width: 1020px) {
  /*=================================
    clinic
  =================================*/
  .clinicList {
    &__item {
      .tagList {
        &__item {
          padding: 3px 8px;
          margin: 0 8px 10px 0;
        }
      }
      .caption {
        font-size: 1.5rem;
      }
      .contWrap {
        margin-top: 20px;
        display: block;
        &__left {
          width: 100%;
          max-width: initial;
        }
        &__right {
          width: 100%;
          margin: 20px 0 0;
        }
        .thumb {
          text-align: center;
        }
        .btn--mail {
          &::before {
            margin: 0 10px -2px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    clinic
  =================================*/
  .clinicList {
    &__item {
      padding: 20px per(15);
      .tagList {
        &__item {
          font-size: 1.2rem;
          padding: 2px 9px;
          border-radius: 2px;
          margin: 0 8px 7px 0;
        }
        & + .caption {
          margin-top: 20px;
        }
      }
      .caption {
        font-size: 1.4rem;
      }
      .name {
        font-size: 2.2rem;
        a {
          font-size: 2.2rem;
        }
      }
      .label {
        margin-left: 8px;
      }
      .contWrap {
        margin-top: 27px;
        display: block;
        &__left {
          width: 100%;
          max-width: initial;
          overflow: hidden;
        }
        &__right {
          width: 100%;
          margin: 20px 0 0;
        }
        .thumb {
          margin: 0 0 20px;
        }
        .contact {
          &__ttl {
            font-size: 1.6rem;
            margin-bottom: 10px;
          }
          .tel {
            font-size: vw(32,$sp-width);
            background: transparent;
            padding: 0;
            text-align: left;
            &::before {
              width: vw(28,$sp-width);
              height: vw(28,$sp-width);
              margin: 0 3px 0 0;
            }
          }
          .receptionTime {
            justify-content: flex-start;
            background: transparent;
            padding: 0;
            width: 100%;
            margin-top: 14px;
          }
        }
        .feature {
          display: table;
          white-space: initial;
          margin: 0 0 15px;
          &__ttl {
            font-size: 1.4rem;
            width: 92px;
            padding: 10px 10px;
          }
          &__item {
            width: calc(100% - 92px);
            padding: 10px 10px;
          }
        }
        .info {
          display: table;
          white-space: initial;
          &__ttl {
            font-size: 1.4rem;
            width: 92px;
            padding: 12px 10px;
            vertical-align: top;
          }
          &__item {
            font-size: 1.4rem;
            width: calc(100% - 92px);
            padding: 12px 15px;
          }
        }
        .btnWrap {
          margin: 30px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn--more {
          font-size: 1.4rem;
          padding: 19px 5px 15px;
          width: 100%;
          max-width: 48%;
          &::before {
            width: 8px;
            height: 13px;
            margin: 0 7px -2px 0;
          }
          & + .btn--mail {
            width: calc(100% - (48% + per(10)));
            margin: 0 0 0 per(10);
          }
        }
        .btn--mail {
          font-size: 1.4rem;
          padding: 19px 5px 15px;
          width: 100%;
          max-width: 52%;
          &::before {
            width: 18px;
            height: 13px;
            margin: 0 5px -2px 0;
          }
          & + .btn--reserve {
            width: calc(100% - (48% + per(10)));
            margin: 0 0 0 per(10);
          }
        }
        .btn--reserve {
          font-size: 1.4rem;
          padding: 19px 5px 15px;
          width: 100%;
          max-width: 48%;
          &::before {
            width: 14px;
            height: 14px;
            margin: 0 7px -2px 0;
          }
          & + .btn--mail {
            width: calc(100% - (48% + per(10)));
            margin: 0 0 0 per(10);
          }
        }
      }
    }
  }
}