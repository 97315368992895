@charset "utf-8";
/*=================================
footer
=================================*/
.footer {
  background: $color-theme;
  padding: 57px 1% 34px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    background: url(../img/bg_footer.png) no-repeat right center;
    background-size: contain;
    padding-top: 20%;
    display: flex;
  }
  &__add {
    width: 400px;
    .logo {
      max-width: 290px;
      margin-bottom: 34px;
      display: block;
    }
    .add {
      font-size: 1.4rem;
      line-height: 2;
      color: #fff;
    }
  }
  &__cont {
    width: calc(100% - 400px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__btn {
    margin-bottom: 10px;
    a {
      font-size: 1.7rem;
      font-weight: bold;
      color: $color-theme;
      text-decoration: none;
      background: #fff;
      box-shadow: 0 4px 0 #ccc;
      border-radius: 4px;
      display: inline-block;
      padding: 25px 68px 21px;
      @include animation-base(all,0s);
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
        background: url(../img/icon/icon_search_n.png) no-repeat center center;
        background-size: contain;
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(4px);
        }
      }
    }
  }
  .fNavList {
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    &__item {
      width: calc(100% / 2);
      a {
        font-size: 1.4rem;
        line-height: 2.857;
        color: #fff;
        text-decoration: none;
        @include mq-desktop {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .copy {
    font-size: 1.4rem;
    color: #fff;
    width: 100%;
    text-align: right;
  }
}
.pageTop {
  width: 59px;
  cursor: pointer;
  position: absolute;
  bottom: 90px;
  right: 3%;
  @include animation-base;
  @include mq-desktop {
    &:hover {
      transform: translateY(-10%);
    }
  }
  &.float {
    position: fixed;
    bottom: 40px;
    right: 3%;
    z-index: 5000;
  }
}
.fixBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  padding: 20px;
  max-width: 500px;
  position: fixed;
  right: -100%;
  bottom: 120px;
  z-index: 9999;
  transition: right 1.2s ease-out;
  &__ttl {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    &::before {
      content: "";
      width: 5px;
      height: 24px;
      display: inline-block;
      margin: 0 10px -5px 0;
      background: $color-theme;
      background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
    }
  }
  .contact {
    display: flex;
    justify-content: space-between;
    &__wrap {
      width: 48%;
      .contact__btn {
        font-size: 2rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding: 12px 10px;
        border-radius: 4px;
        text-align: center;
        display: block;
      }
      .contact__text {
        font-size: 1.2rem;
        line-height: 1.454545;
        margin-top: 10px;
      }
      &.tel {
        .contact__btn {
          background: rgb(54,99,154);
          background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
          box-shadow: 0px 4px 0 0px #26456B;
          @include animation-base(all, 0s);
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/icon_tel_w.png) no-repeat center center;
            background-size: contain;
            margin: 0 6px -3px 0;
          }
          @include mq-desktop {
            pointer-events: none;
            &:hover {
              box-shadow: none;
              transform: translateY(4px);
            }
          }
        }
      }
      &.mail {
        .contact__btn {
          background: rgb(171,155,78);
          background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
          box-shadow: 0px 4px 0 0px #776D37;
          @include animation-base(all,0s);
          &::before {
            content: "";
            width: 20px;
            height: 15px;
            display: inline-block;
            background: url(../img/icon/icon_mail.png) no-repeat center center;
            background-size: contain;
            margin: 0 8px -2px 0;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(4px);
            }
          }
        }
      }
    }
  }
  &.show {
    right: 0;
  }
}
@media screen and (min-width: $display-width-s) and (max-width: 1230px) {
  /*=================================
  footer
  =================================*/
  .footer {
    &__btn {
      margin-bottom: 20px;
      a {
        padding: 25px 20px 21px;
      }
    }
    .fNavList {
      max-width: 100%;
      &__item {
        width: auto;
        margin-right: 15px;
        a {
          font-size: 1.4rem;
          line-height: 2.857;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
  footer
  =================================*/
  .footer {
    padding: 60px 0 43px;
    &__inner {
      background: none;
      padding: 0;
      display: block;
    }
    &__add {
      width: 100%;
      padding: 0 per(15);
      .logo {
        max-width: 230px;
        margin-bottom: 20px;
      }
    }
    &__cont {
      width: 100%;
      display: block;
      background: url(../img/bg_footer.png) no-repeat right -120px top;
      background-size: contain;
      padding: 0 per(15);
    }
    &__btn {
      margin: 40px 0 0;
      a {
        font-size: 1.8rem;
        padding: 25px 10px 21px;
        display: block;
        text-align: center;
      }
    }
    .fNavList {
      max-width: 100%;
      justify-content: space-between;
      margin: 40px 0 0;
      &__item {
        a {
          font-size: 1.3rem;
        }
      }
    }
    .copy {
      font-size: 1.3rem;
      text-align: center;
      display: block;
      margin-top: 43px;
    }
  }
  .pageTop {
    &.float {
      bottom: 3%;
      right: 3%;
    }
  }
  .fixBnr {
    box-shadow: none;
    max-width: initial;
    padding: 9px vw(11,$sp-width) 5px;
    width: 100%;
    right: 0;
    left: 0;
    bottom: -100%;
    transition: bottom 1s ease-out;
    z-index: 9000;
    &__ttl {
      font-size: 1.6rem;
      margin-bottom: 9px;
      &::before {
        width: 5px;
        height: 22px;
        margin: 0 10px -5px 0;
      }
    }
    .contact {
      &__wrap {
        .contact__btn {
          font-size: vw(16,$sp-width);
          padding: 12px 3px;
          border-radius: 3px;
        }
        .contact__text {
          font-size: 1.1rem;
          margin-top: 9px;
        }
        &.tel {
          width: 52%;
          .contact__btn {
            font-size: vw(18,$sp-width);
            padding: 12px 3px 7px;
            &::before {
              width: vw(17,$sp-width);
              height: vw(17,$sp-width);
              margin: 0 3px -2px 0;
            }
          }
        }
        &.mail {
          width: 46%;
          .contact__btn {
            font-size: vw(16,$sp-width);
            padding: 13px 3px 9px;
            &::before {
              width: vw(15,$sp-width);
              height: vw(11,$sp-width);
              margin: 0 4px 0 0;
            }
          }
        }
      }
    }
    &.show {
      bottom: 0;
      right: inherit;
    }
  }
}