@charset "utf-8";

/* ---------------------------------
  modal
--------------------------------- */
.searchModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .modalBg {
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, .6);
  }
  .modalWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    width: 70%;
    max-width: 960px;
    padding: 40px;
    background: #fff;
    border-top: 6px solid $color-theme;
    box-shadow: 0 10px 36px rgba(0, 0, 0, .18);
    max-height: 80vh;
    overflow-y: auto;
    @include mq-tab {
      width: 80%;
      padding: vw(40);
    }
  }
  .closeModal {
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-theme;
    display: inline-block;
    cursor: pointer;
    background: $bg-color;
    border-radius: 4px;
    box-shadow: 0 4px 0 #CCCCCC;
    margin-top: 19px;
    width: 100%;
    max-width: 300px;
    padding: 15px 10px 11px;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    @include mq-desktop {
      &:hover {
        box-shadow: none;
        transform: translate(-50%, 4px);
      }
    }
    &::before {
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      background: url(../img/icon/icon_close.png) no-repeat center center;
      background-size: contain;
      margin: 0 5px -3px 0;
    }
  }
  .area {
    &__item {
      display: inline-block;
      margin: 0 3px 7px 0;
      span {
        font-size: 1.6rem;
        font-weight: bold;
        color: #D2D2D2;
        line-height: 1;
        display: block;
        padding: 17px 25px 15px;
        background: $bg-color;
        background: -moz-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        background: linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
        border-radius: 2px;
        border: 1px solid #D2D2D2;
        border-bottom: 4px solid #D2D2D2;
      }
      a {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1;
        display: block;
        text-decoration: none;
        padding: 17px 25px 15px;
        background: rgb(251,251,251);
        background: -moz-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        background: linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
        border-radius: 2px;
        border: 1px solid #D2D2D2;
        border-bottom: 4px solid #D2D2D2;
        @include animation-base(all,0s);
      }
      @include mq-desktop {
        &:hover {
          a {
            background: rgb(240,240,240);
            background: -moz-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
            background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
            border-bottom: 1px solid #D2D2D2;
            transform: translateY(3px);
          }
        }
      }
      &.n {
        display: none;
      }
    }
  }
}
.js-modalSwitch {
  cursor: pointer;
}
.searchModal {
  &__ttl {
    font-size: 2.4rem;
    font-weight: bold;
    color: $color-theme;
    text-align: center;
    margin-bottom: 45px;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      background: url(../img/icon/icon_search_n.png) no-repeat center center;
      background-size: contain;
      margin: 0 7px -2px 0;
    }
    &--sub {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 20px;
      padding-left: 18px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 24px;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* ---------------------------------
    modal
  --------------------------------- */
  .searchModal {
    .modalWrap {
      width: calc(100% - per(40));
      padding: 0;
      border-top: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .18);
    }
    .closeModal {
      font-size: 1.7rem;
      color: $font-color-base;
      display: block;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      margin-top: 0;
      width: 100%;
      max-width: initial;
      padding: 13px per(14);
      left: auto;
      transform: translateX(0);
      &::before {
        margin: 0 5px -3px 0;
      }
    }
    .area {
      background: $bg-color;
      padding: 14px per(14);
      border-top: 1px solid #D2D2D2;
      border-bottom: 1px solid #D2D2D2;
      &__item {
        display: inline-block;
        margin: 0 5px 10px 0;
        span {
          padding: 10px 8px 11px;
          box-shadow: 0 0 2px rgba(0, 0, 0, .16);
        }
        a {
          padding: 10px 8px 11px;
          box-shadow: 0 0 2px rgba(0, 0, 0, .16);
        }
      }
    }
  }
  .searchModal {
    &__ttl {
      font-size: 1.7rem;
      color: #fff;
      background: $color-theme;
      margin-bottom: 0;
      padding: 8px per(14);
      &::before {
        width: 16px;
        height: 16px;
        background: url(../img/icon/icon_search.png) no-repeat center center;
        background-size: contain;
        margin: 0 10px -2px 0;
      }
      &--sub {
        font-size: 1.7rem;
        margin-bottom: 0;
        padding: 15px per(14);
        position: relative;
        &::before {
          content: none;
        }
      }
    }
  }
}