@charset "utf-8";
/*=================================
  topPage
=================================*/
.home {
  .layout--2col {
    padding-top: 57px;
  }
  .clinicList {
    &__item {
      border-left: 1px solid #CCCCCC;
      border-right: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      & + .clinicList__item {
        margin-top: 20px;
      }
    }
  }
  .catArticle {
    display: flex;
    flex-wrap: wrap;
    &__ttl {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 20px;
      padding-left: 17px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 24px;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
    &__item {
      width: calc((100% - vw(40)) / 2);
      margin-top: 60px;
      &:nth-child(2n) {
        margin-left: vw(40);
      }
    }
    .articleList {
      &__item {
        .itemWrap {
          padding: 20px 0;
          .thumb {
            width: 45%;
            max-width: 200px;
            margin-right: 20px;
            @include mq-tab {
              margin-right: vw(20);
            }
            & + .textWrap {
              width: calc(100% - (45% + 20px));
              @include mq-tab {
                width: calc(100% - (45% + vw(20)));
              }
            }
          }
          .textWrap {
            &__ttl {
              font-size: 1.6rem;
              line-height: 1.625;
              margin-bottom: 0;
            }
            &__date {
              font-size: 1.3rem;
              text-align: left;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .secWrap {
    padding: 90px 1% 60px;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
      position: relative;
    }
    &.pickUp {
      background: $bg-color;
      @include mq-tab {
        padding-top: vw(140);
      }
      .pickUpList {
        &:not(.slick-slider) {
          display: flex;
          .pickUpList__item {
            width: calc(100% / 3);
          }
        }
        &__item {
          background: $bg-color;
          border: 1px solid #CCCCCC;
          box-shadow: 0 10px 26px rgba(0, 0, 0, .06);
          @include mq-desktop {
            &:hover {
              border-color: $color-theme;
            }
          }
          .thumb {
            position: relative;
            .tagList {
              padding: 12px 20px;
              background: rgba(54, 99, 154, .8);
              text-align: center;
              max-width: 65%;
              position: absolute;
              right: 0;
              bottom: 0;
              @include mq-tab {
                padding: vw(12) vw(25);
                max-width: 85%;
              }
              &__item {
                font-size: 1.3rem;
                font-weight: 400;
                color: #fff;
                margin: 0 3px;
                display: inline-block;
              }
            }
          }
          .textWrap {
            background: #fff;
            padding: 50px 28px;
            text-align: center;
            .name {
              font-size: 2.2rem;
              font-weight: bold;
              position: relative;
              a {
                font-size: 2.2rem;
                font-weight: bold;
                text-decoration: none;
              }
              .label {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1;
                color: #fff;
                background: $color-red;
                padding: 2px 7px;
                vertical-align: top;
                margin-left: 5px;
                margin-right: -37px;
              }
            }
            .text {
              font-size: 1.4rem;
              line-height: 1.71428;
              margin-top: 20px;
            }
          }
          .add {
            font-size: 1.4rem;
            font-weight: bold;
            text-align: center;
            background: $bg-color;
            padding: 30px  15px;
            &::before {
              content: "";
              width: 14px;
              height: 20px;
              display: inline-block;
              background: url(../img/icon/icon_pin.png) no-repeat center center;
              background-size: contain;
              margin: 0 12px -5px 0;
            }
          }
          & + .pickUpList__item {
            margin-left: 34px;
          }
        }
      }
      .js-sliderAppends {
        display: none;
      }
      .js-slider--pickUp {
        &.slick-slider {
          width: calc(100% + 30px);
          margin-left: -22px;
          margin-bottom: 0;
          padding-bottom: 10px;
          .slick-slide {
            margin: 0 17px;
            > div {
              padding: 0 5px 24px;
            }
          }
          & + .js-sliderAppends {
            display: block;
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translateX(-50%);
            z-index: 1;
            /* Arrows */
            .js-sliderArrows {
              display: flex;
              justify-content: center;
              align-items: center;
              .slick-prev,
              .slick-next {
                position: static;
                width: 10px;
                height: 15px;
                transform: translate(0, 0);
              }
              .slick-prev.slick-disabled:before,
              .slick-next.slick-disabled:before {
                opacity: 1;
              }
              .slick-prev:before,
              .slick-next:before {
                width: 10px;
                height: 15px;
                display: inline-block;
                background: url(../img/slider_arrow.png) no-repeat center center;
                background-size: contain;
              }
              .slick-prev {
                  left: 0;
              }
              [dir='rtl'] .slick-prev {
                right: 0;
                left: auto;
              }
              .slick-prev:before {
                content: "";
              }
              [dir='rtl'] .slick-prev:before {
              content: "";
              transform: rotate(180deg);
              }
              .slick-next {
                right: 0;
              }
              [dir='rtl'] .slick-next {
                right: auto;
                left: 0;
              }
              .slick-next:before {
                content: "";
                transform: rotate(180deg);
              }
              [dir='rtl'] .slick-next:before {
                content: "";
              }
            }
            /* Dots */
            .js-sliderDots {
              margin: 0 25px;
              .slick-dots {
                position: static;
                width: auto;
                display: flex!important;
                align-items: center;
              }
              .slick-dots li {
                width: 40px;
                height: 5px;
                margin: 0 5px;
                padding: 0;
              }
              .slick-dots li button {
                width: 40px;
                height: 5px;
                padding: 0;
              }
              .slick-dots li button:hover:before,
              .slick-dots li button:focus:before {
                opacity: 1;
              }
              .slick-dots li button:before {
                content: "";
                width: 40px;
                height: 5px;
                opacity: 1;
                background: #D2D2D2;
                display: inline-block;
              }
              .slick-dots li.slick-active button:before {
                opacity: 1;
                background: $color-theme;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    topPage
  =================================*/
  .home {
    .layout--2col {
      padding-top: 43px;
    }
    .sideNav {
      padding: 0 0 40px;
    }
    .catArticle {
      display: block;
      &__ttl {
        padding-left: 18px;
        &::before {
          width: 4px;
          height: 30px;
          top: -5px;
        }
      }
      &__item {
        width: 100%;
        margin-top: 40px;
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
      .articleList {
        &__item {
          .itemWrap {
            padding: 20px 0;
            .thumb {
              width: 37%;
              max-width: 140px;
              margin-right: per(20);
              & + .textWrap {
                width: calc(100% - (37% + per(20)));
              }
            }
            .textWrap {
              &__ttl {
                line-height: 1.5;
              }
              &__date {
                font-size: 1.2rem;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
    .secWrap {
      padding: 40px per(15) 40px;
      &.pickUp {
        .pickUpList {
          &:not(.slick-slider) {
            .pickUpList__item {
              width: 100%;
            }
          }
          &__item {
            .thumb {
              .tagList {
                padding: 12px 9px;
                max-width: 90%;
                &__item {
                  font-size: 1.2rem;
                }
              }
            }
            .textWrap {
              padding: 35px per(15) 28px;
              .name {
                font-size: 1.8rem;
                position: relative;
                a {
                  font-size: 1.8rem;
                }
                .label {
                  padding: 2px 5px;
                  margin: 0;
                  position: absolute;
                  top: -1.5rem;
                  right: 0;
                }
              }
              .text {
                margin-top: 11px;
              }
            }
            .add {
              font-size: 1.2rem;
              padding: 22px per(15);
              &::before {
                width: 10px;
                height: 15px;
                margin: 0 10px -3px 0;
              }
            }
            & + .pickUpList__item {
              margin-left: 0px;
            }
          }
        }
        .js-slider--pickUp {
          &.slick-slider {
            width: calc(100% + per(30) + 2px);
            margin-left: calc(per(-15) - 1px);
            .slick-slide {
              margin: 0 5px;
            }
            & + .js-sliderAppends {
              width: 78%;
              bottom: -10px;
              /* Arrows */
              .js-sliderArrows {
                .slick-prev,
                .slick-next {
                  width: 16px;
                  height: 25px;
                }
                .slick-prev:before,
                .slick-next:before {
                  width: 16px;
                  height: 25px;
                }
              }
              /* Dots */
              .js-sliderDots {
                margin: 0 25px;
                .slick-dots {
                  width: 100%!important;
                  flex-wrap: wrap;
                  justify-content: center;
                }
                .slick-dots li {
                  width: vw(50,$sp-width);
                  max-width: 60px;
                  height: 8px;
                  margin: 5px 5px;
                }
                .slick-dots li button {
                  width: vw(50,$sp-width);
                  max-width: 60px;
                  height: 8px;
                }
                .slick-dots li button:before {
                  width: vw(50,$sp-width);
                  max-width: 60px;
                  height: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}