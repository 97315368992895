@charset "utf-8";

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0;
  width: 100%;
  border: 1px solid #D2D2D2;
  caption {
    font-size: 122%;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
    color: $color-gold;
    caption-side: top;
  }
  th {
    font-weight: bold;
    color: $color-theme;
    background: $color-theme-light;
  }
  td {
    font-weight: 400;
    line-height: 1.25;
    
  }
  th,td {
    font-size: 1.6rem;
    padding: 20px 20px;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px dashed #D2D2D2;
    }
  }
  thead {
    th {
      background: $color-theme;
      color: #fff;
    }
    td {
      border-bottom: 1px solid #D2D2D2;
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #D2D2D2;
    }
    th {
      border-bottom: 1px solid #D2D2D2;
    }
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: $bg-color;
    }
    ::-webkit-scrollbar-thumb {
      background: #D2D2D2;
    }
  }
}
.scrollAnnounce {
  display: none;
}
@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
    margin: 30px 0;
    thead {
      border: 1px solid #D2D2D2;
    }
    tbody {
      border: 1px solid #D2D2D2;
    }
    caption {
      font-size: 1.5rem;
      margin: 0 0 15px;
    }
    th {
      font-size: 1.4rem;
      padding: 18px;
    }
    td {
      font-size: 1.4rem;
      padding: 18px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: none;
      background: #D2D2D2;
    }
    /* スクロールのつまみ部分の設定 */
    &::-webkit-scrollbar-thumb {
      background: $color-theme;
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    margin-top: 30px;
    .text {
      font-size: 1.3rem;
      font-weight: 600;
      color: #fff;
      background: $color-theme;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: $color-theme transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}