@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    top: -20%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: #fff;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__pickUp {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__pickUp::before {
    content: "続きを読む";
    font-size: 1.2rem;
    font-weight: 400;
    width: 72px;
    line-height: 72px;
    text-align: center;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "記事を見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(54, 99, 154, 0.5);
    color: #fff;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__pickUp::before {
    opacity: 1;
    top: 50%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #ECF5FF;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}
.btnWrap .btn--gold {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 18px 14px 16px;
  border-radius: 5px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 5px 0 0px #776D37;
}
.btnWrap .btn--gold::after {
  content: "";
  width: 9px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  margin: 0 0 0 8px;
}
.btnWrap .btn--blue {
  font-size: 1.9rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 27px 14px 22px;
  border-radius: 4px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 5px 0 0px #26456B;
  width: 100%;
  display: block;
  text-align: center;
}
.btnWrap .btn--gray {
  font-size: 1.4rem;
  font-weight: 400;
  color: #36639A;
  text-decoration: none;
  padding: 22px 3.5897435897% 18px;
  border-radius: 4px;
  background: #FBFBFB;
  box-shadow: 0px 4px 0 0px #CCCCCC;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.btnWrap .btn--gray::before {
  content: "";
  width: 10px;
  height: 15px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  margin: 0 13px -1px 0;
  transform: rotate(180deg);
}
.btnWrap .btn--search {
  font-size: 1.9rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 27px 14px 22px;
  border-radius: 4px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 5px 0 0px #26456B;
  width: 100%;
  display: block;
}
.btnWrap .btn--search::before {
  content: "";
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/icon_search.png) no-repeat center center;
  background-size: contain;
  margin: 0 8px -3px 0;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  .btnWrap > * + * {
    margin: 0 0 0 20px;
  }
  .btnWrap .btn--gold {
    font-size: 2rem;
    padding: 22px 20px 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 0 0px #776D37;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btnWrap .btn--gold::after {
    width: 12px;
    height: 16px;
    margin: 0 0 0 10px;
  }
}
@media screen and (min-width: 767px) and (min-width: 767px) {
  .btnWrap .btn--gold:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
@media screen and (min-width: 767px) {
  .btnWrap .btn--blue {
    font-size: 2rem;
    padding: 35px 20px 28px;
    border-radius: 7px;
    box-shadow: 0px 7px 0 0px #26456B;
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
}
@media screen and (min-width: 767px) and (min-width: 767px) {
  .btnWrap .btn--blue:hover {
    box-shadow: none;
    transform: translateY(7px);
  }
}
@media screen and (min-width: 767px) {
  .btnWrap .btn--gray {
    font-size: 1.6rem;
    padding: 27px 14px 22px;
    max-width: 476px;
    display: block;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btnWrap .btn--gray::before {
    width: 11px;
    height: 17px;
    margin: 0 15px -2px 0;
    transform: rotate(180deg);
  }
}
@media screen and (min-width: 767px) and (min-width: 767px) {
  .btnWrap .btn--gray:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
@media screen and (min-width: 767px) {
  .btnWrap .btn--search {
    font-size: 2rem;
    padding: 35px 20px 28px;
    border-radius: 7px;
    box-shadow: 0px 7px 0 0px #26456B;
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btnWrap .btn--search::before {
    width: 21px;
    height: 21px;
    margin: 0 10px -3px 0;
  }
}
@media screen and (min-width: 767px) and (min-width: 767px) {
  .btnWrap .btn--search:hover {
    box-shadow: none;
    transform: translateY(7px);
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-bottom: 40px;
  padding-left: 20px;
  position: relative;
}
.ttl--base::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    margin-bottom: 22px;
    padding-left: 18px;
  }
  .ttl--base::before {
    width: 4px;
    height: 30px;
    top: 0;
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #333333;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #36639A;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #ECF5FF;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 4.358974359%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/* ---------------------------------
  modal
--------------------------------- */
.searchModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.searchModal .modalBg {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.6);
}
.searchModal .modalWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 960px;
  padding: 40px;
  background: #fff;
  border-top: 6px solid #36639A;
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.18);
  max-height: 80vh;
  overflow-y: auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .searchModal .modalWrap {
    width: 80%;
    padding: 2.9282576867vw;
  }
}
.searchModal .closeModal {
  font-size: 1.6rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  cursor: pointer;
  background: #FBFBFB;
  border-radius: 4px;
  box-shadow: 0 4px 0 #CCCCCC;
  margin-top: 19px;
  width: 100%;
  max-width: 300px;
  padding: 15px 10px 11px;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 767px) {
  .searchModal .closeModal:hover {
    box-shadow: none;
    transform: translate(-50%, 4px);
  }
}
.searchModal .closeModal::before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../img/icon/icon_close.png) no-repeat center center;
  background-size: contain;
  margin: 0 5px -3px 0;
}
.searchModal .area__item {
  display: inline-block;
  margin: 0 3px 7px 0;
}
.searchModal .area__item span {
  font-size: 1.6rem;
  font-weight: bold;
  color: #D2D2D2;
  line-height: 1;
  display: block;
  padding: 17px 25px 15px;
  background: #FBFBFB;
  background: -moz-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: -webkit-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
  border-radius: 2px;
  border: 1px solid #D2D2D2;
  border-bottom: 4px solid #D2D2D2;
}
.searchModal .area__item a {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  display: block;
  text-decoration: none;
  padding: 17px 25px 15px;
  background: rgb(251, 251, 251);
  background: -moz-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: -webkit-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
  border-radius: 2px;
  border: 1px solid #D2D2D2;
  border-bottom: 4px solid #D2D2D2;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .searchModal .area__item:hover a {
    background: rgb(240, 240, 240);
    background: -moz-linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    background: -webkit-linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
    border-bottom: 1px solid #D2D2D2;
    transform: translateY(3px);
  }
}
.searchModal .area__item.n {
  display: none;
}

.js-modalSwitch {
  cursor: pointer;
}

.searchModal__ttl {
  font-size: 2.4rem;
  font-weight: bold;
  color: #36639A;
  text-align: center;
  margin-bottom: 45px;
}
.searchModal__ttl::before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_search_n.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
.searchModal__ttl--sub {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-left: 18px;
  position: relative;
}
.searchModal__ttl--sub::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  /* ---------------------------------
    modal
  --------------------------------- */
  .searchModal .modalWrap {
    width: 89.7435897436%;
    padding: 0;
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
  }
  .searchModal .closeModal {
    font-size: 1.7rem;
    color: #333333;
    display: block;
    background: #fff;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
    width: 100%;
    max-width: initial;
    padding: 13px 3.5897435897%;
    left: auto;
    transform: translateX(0);
  }
  .searchModal .closeModal::before {
    margin: 0 5px -3px 0;
  }
  .searchModal .area {
    background: #FBFBFB;
    padding: 14px 3.5897435897%;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
  }
  .searchModal .area__item {
    display: inline-block;
    margin: 0 5px 10px 0;
  }
  .searchModal .area__item span {
    padding: 10px 8px 11px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  }
  .searchModal .area__item a {
    padding: 10px 8px 11px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  }
  .searchModal__ttl {
    font-size: 1.7rem;
    color: #fff;
    background: #36639A;
    margin-bottom: 0;
    padding: 8px 3.5897435897%;
  }
  .searchModal__ttl::before {
    width: 16px;
    height: 16px;
    background: url(../img/icon/icon_search.png) no-repeat center center;
    background-size: contain;
    margin: 0 10px -2px 0;
  }
  .searchModal__ttl--sub {
    font-size: 1.7rem;
    margin-bottom: 0;
    padding: 15px 3.5897435897%;
    position: relative;
  }
  .searchModal__ttl--sub::before {
    content: none;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0;
  width: 100%;
  border: 1px solid #D2D2D2;
}
table caption {
  font-size: 122%;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  color: #AB9B4E;
  caption-side: top;
}
table th {
  font-weight: bold;
  color: #36639A;
  background: #ECF5FF;
}
table td {
  font-weight: 400;
  line-height: 1.25;
}
table th, table td {
  font-size: 1.6rem;
  padding: 20px 20px;
  text-align: center;
}
table th:not(:last-child), table td:not(:last-child) {
  border-right: 1px dashed #D2D2D2;
}
table thead th {
  background: #36639A;
  color: #fff;
}
table thead td {
  border-bottom: 1px solid #D2D2D2;
}
table tbody td {
  border-bottom: 1px solid #D2D2D2;
}
table tbody th {
  border-bottom: 1px solid #D2D2D2;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #FBFBFB;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #D2D2D2;
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
    margin: 30px 0;
    /* スクロールのつまみ部分の設定 */
  }
  table thead {
    border: 1px solid #D2D2D2;
  }
  table tbody {
    border: 1px solid #D2D2D2;
  }
  table caption {
    font-size: 1.5rem;
    margin: 0 0 15px;
  }
  table th {
    font-size: 1.4rem;
    padding: 18px;
  }
  table td {
    font-size: 1.4rem;
    padding: 18px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #D2D2D2;
  }
  table::-webkit-scrollbar-thumb {
    background: #36639A;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    margin-top: 30px;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #36639A;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #36639A transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  padding: 0 1% 12px;
}
.breadcrumb ul {
  line-height: 1;
}
.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  padding-right: 30px;
  display: inline-block;
  position: relative;
}
.breadcrumb ul li:not(:last-child)::before {
  content: "";
  width: 9px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_g.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
}
.breadcrumb ul li a, .breadcrumb ul li span {
  font-size: 1.4rem;
  line-height: 1;
  display: block;
}

@media screen and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 4.358974359%;
  }
  .breadcrumb ul {
    margin-bottom: 0;
  }
  .breadcrumb ul li {
    font-size: 1.2rem;
  }
  .breadcrumb ul li a, .breadcrumb ul li span {
    font-size: 1.2rem;
    padding: 8px 6px 10px;
  }
  .breadcrumb ul li + li::before {
    font-size: 1rem;
  }
}
/*=================================
  pager
=================================*/
.pager {
  padding: 30px 0 0;
}
.pagerList {
  display: flex;
  justify-content: center;
}
.pagerList__item {
  margin-right: 6px;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  display: block;
}
.pagerList__item a {
  color: #36639A;
  text-decoration: none;
  background: #ECF5FF;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #36639A;
  }
}
.pagerList__item span {
  color: #fff;
  background: #36639A;
}
.pager .wp-pagenavi {
  display: flex;
  justify-content: center;
}
.pager .wp-pagenavi .pages {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  margin-right: 6px;
  border: 1px solid #36639A;
}
.pager .wp-pagenavi .current {
  font-size: 1.5rem;
  line-height: 1;
  color: #fff;
  padding: 13px 16px;
  background: #36639A;
  display: block;
  text-decoration: none;
  border: 1px solid #36639A;
}
.pager .wp-pagenavi .page {
  font-size: 1.5rem;
  color: #36639A;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #ECF5FF;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #ECF5FF;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .page:hover {
    color: #fff;
    background: #36639A;
  }
}
.pager .wp-pagenavi .nextpostslink {
  font-size: 1.5rem;
  color: #36639A;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #36639A;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .nextpostslink:hover {
    color: #fff;
    background: #36639A;
  }
}
.pager .wp-pagenavi .previouspostslink {
  font-size: 1.5rem;
  color: #36639A;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  border: 1px solid #36639A;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .previouspostslink:hover {
    color: #fff;
    background: #36639A;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 8px;
  }
  .pagerList__item a, .pagerList__item span {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .pages {
    padding: 11px 14px;
    margin-right: 8px;
  }
  .pager .wp-pagenavi .current {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .page {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .nextpostslink {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .previouspostslink {
    padding: 11px 14px;
  }
}
/*=================================
  topPage
=================================*/
.home .layout--2col {
  padding-top: 57px;
}
.home .clinicList__item {
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
.home .clinicList__item + .clinicList__item {
  margin-top: 20px;
}
.home .catArticle {
  display: flex;
  flex-wrap: wrap;
}
.home .catArticle__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  padding-left: 17px;
  position: relative;
}
.home .catArticle__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: -2px;
  left: 0;
}
.home .catArticle__item {
  width: calc((100% - 2.9282576867vw) / 2);
  margin-top: 60px;
}
.home .catArticle__item:nth-child(2n) {
  margin-left: 2.9282576867vw;
}
.home .catArticle .articleList__item .itemWrap {
  padding: 20px 0;
}
.home .catArticle .articleList__item .itemWrap .thumb {
  width: 45%;
  max-width: 200px;
  margin-right: 20px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .catArticle .articleList__item .itemWrap .thumb {
    margin-right: 1.4641288433vw;
  }
}
.home .catArticle .articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (45% + 20px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .catArticle .articleList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (45% + 1.4641288433vw));
  }
}
.home .catArticle .articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  line-height: 1.625;
  margin-bottom: 0;
}
.home .catArticle .articleList__item .itemWrap .textWrap__date {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 8px;
}
.home .secWrap {
  padding: 90px 1% 60px;
}
.home .secWrap__inner {
  max-width: 1286px;
  margin: 0 auto;
  position: relative;
}
.home .secWrap.pickUp {
  background: #FBFBFB;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.pickUp {
    padding-top: 10.2489019034vw;
  }
}
.home .secWrap.pickUp .pickUpList:not(.slick-slider) {
  display: flex;
}
.home .secWrap.pickUp .pickUpList:not(.slick-slider) .pickUpList__item {
  width: 33.3333333333%;
}
.home .secWrap.pickUp .pickUpList__item {
  background: #FBFBFB;
  border: 1px solid #CCCCCC;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
}
@media screen and (min-width: 767px) {
  .home .secWrap.pickUp .pickUpList__item:hover {
    border-color: #36639A;
  }
}
.home .secWrap.pickUp .pickUpList__item .thumb {
  position: relative;
}
.home .secWrap.pickUp .pickUpList__item .thumb .tagList {
  padding: 12px 20px;
  background: rgba(54, 99, 154, 0.8);
  text-align: center;
  max-width: 65%;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.pickUp .pickUpList__item .thumb .tagList {
    padding: 0.878477306vw 1.8301610542vw;
    max-width: 85%;
  }
}
.home .secWrap.pickUp .pickUpList__item .thumb .tagList__item {
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  margin: 0 3px;
  display: inline-block;
}
.home .secWrap.pickUp .pickUpList__item .textWrap {
  background: #fff;
  padding: 50px 28px;
  text-align: center;
}
.home .secWrap.pickUp .pickUpList__item .textWrap .name {
  font-size: 2.2rem;
  font-weight: bold;
  position: relative;
}
.home .secWrap.pickUp .pickUpList__item .textWrap .name a {
  font-size: 2.2rem;
  font-weight: bold;
  text-decoration: none;
}
.home .secWrap.pickUp .pickUpList__item .textWrap .name .label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  background: #C62828;
  padding: 2px 7px;
  vertical-align: top;
  margin-left: 5px;
  margin-right: -37px;
}
.home .secWrap.pickUp .pickUpList__item .textWrap .text {
  font-size: 1.4rem;
  line-height: 1.71428;
  margin-top: 20px;
}
.home .secWrap.pickUp .pickUpList__item .add {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  background: #FBFBFB;
  padding: 30px 15px;
}
.home .secWrap.pickUp .pickUpList__item .add::before {
  content: "";
  width: 14px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_pin.png) no-repeat center center;
  background-size: contain;
  margin: 0 12px -5px 0;
}
.home .secWrap.pickUp .pickUpList__item + .pickUpList__item {
  margin-left: 34px;
}
.home .secWrap.pickUp .js-sliderAppends {
  display: none;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider {
  width: calc(100% + 30px);
  margin-left: -22px;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider .slick-slide {
  margin: 0 17px;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider .slick-slide > div {
  padding: 0 5px 24px;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  z-index: 1;
  /* Arrows */
  /* Dots */
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next {
  position: static;
  width: 10px;
  height: 15px;
  transform: translate(0, 0);
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev.slick-disabled:before,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next.slick-disabled:before {
  opacity: 1;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev:before,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next:before {
  width: 10px;
  height: 15px;
  display: inline-block;
  background: url(../img/slider_arrow.png) no-repeat center center;
  background-size: contain;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev {
  left: 0;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows [dir=rtl] .slick-prev {
  right: 0;
  left: auto;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev:before {
  content: "";
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows [dir=rtl] .slick-prev:before {
  content: "";
  transform: rotate(180deg);
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next {
  right: 0;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows [dir=rtl] .slick-next {
  right: auto;
  left: 0;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next:before {
  content: "";
  transform: rotate(180deg);
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows [dir=rtl] .slick-next:before {
  content: "";
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots {
  margin: 0 25px;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots {
  position: static;
  width: auto;
  display: flex !important;
  align-items: center;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li {
  width: 40px;
  height: 5px;
  margin: 0 5px;
  padding: 0;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button {
  width: 40px;
  height: 5px;
  padding: 0;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button:hover:before,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button:focus:before {
  opacity: 1;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button:before {
  content: "";
  width: 40px;
  height: 5px;
  opacity: 1;
  background: #D2D2D2;
  display: inline-block;
}
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li.slick-active button:before {
  opacity: 1;
  background: #36639A;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home .layout--2col {
    padding-top: 43px;
  }
  .home .sideNav {
    padding: 0 0 40px;
  }
  .home .catArticle {
    display: block;
  }
  .home .catArticle__ttl {
    padding-left: 18px;
  }
  .home .catArticle__ttl::before {
    width: 4px;
    height: 30px;
    top: -5px;
  }
  .home .catArticle__item {
    width: 100%;
    margin-top: 40px;
  }
  .home .catArticle__item:nth-child(2n) {
    margin-left: 0;
  }
  .home .catArticle .articleList__item .itemWrap {
    padding: 20px 0;
  }
  .home .catArticle .articleList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .home .catArticle .articleList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .home .catArticle .articleList__item .itemWrap .textWrap__ttl {
    line-height: 1.5;
  }
  .home .catArticle .articleList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 6px;
  }
  .home .secWrap {
    padding: 40px 3.8461538462% 40px;
  }
  .home .secWrap.pickUp .pickUpList:not(.slick-slider) .pickUpList__item {
    width: 100%;
  }
  .home .secWrap.pickUp .pickUpList__item .thumb .tagList {
    padding: 12px 9px;
    max-width: 90%;
  }
  .home .secWrap.pickUp .pickUpList__item .thumb .tagList__item {
    font-size: 1.2rem;
  }
  .home .secWrap.pickUp .pickUpList__item .textWrap {
    padding: 35px 3.8461538462% 28px;
  }
  .home .secWrap.pickUp .pickUpList__item .textWrap .name {
    font-size: 1.8rem;
    position: relative;
  }
  .home .secWrap.pickUp .pickUpList__item .textWrap .name a {
    font-size: 1.8rem;
  }
  .home .secWrap.pickUp .pickUpList__item .textWrap .name .label {
    padding: 2px 5px;
    margin: 0;
    position: absolute;
    top: -1.5rem;
    right: 0;
  }
  .home .secWrap.pickUp .pickUpList__item .textWrap .text {
    margin-top: 11px;
  }
  .home .secWrap.pickUp .pickUpList__item .add {
    font-size: 1.2rem;
    padding: 22px 3.8461538462%;
  }
  .home .secWrap.pickUp .pickUpList__item .add::before {
    width: 10px;
    height: 15px;
    margin: 0 10px -3px 0;
  }
  .home .secWrap.pickUp .pickUpList__item + .pickUpList__item {
    margin-left: 0px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider {
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider .slick-slide {
    margin: 0 5px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends {
    width: 78%;
    bottom: -10px;
    /* Arrows */
    /* Dots */
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next {
    width: 16px;
    height: 25px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-prev:before,
.home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderArrows .slick-next:before {
    width: 16px;
    height: 25px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots {
    margin: 0 25px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots {
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li {
    width: 12.8205128205vw;
    max-width: 60px;
    height: 8px;
    margin: 5px 5px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button {
    width: 12.8205128205vw;
    max-width: 60px;
    height: 8px;
  }
  .home .secWrap.pickUp .js-slider--pickUp.slick-slider + .js-sliderAppends .js-sliderDots .slick-dots li button:before {
    width: 12.8205128205vw;
    max-width: 60px;
    height: 8px;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  border-top: 1px dashed #D2D2D2;
}
.articleList__item {
  border-bottom: 1px dashed #D2D2D2;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  padding: 30px 40px 30px 0;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleList__item .itemWrap {
    padding: 30px 2.9282576867vw 30px 0;
  }
}
.articleList__item .itemWrap .thumb {
  width: 34%;
  max-width: 300px;
  margin-right: 40px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleList__item .itemWrap .thumb {
    margin-right: 2.9282576867vw;
  }
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (34% + 40px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (34% + 2.9282576867vw));
  }
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
.articleList__item .itemWrap .textWrap__date {
  font-size: 1.6rem;
  color: #D2D2D2;
  display: block;
  text-align: right;
  margin-top: 10px;
}

.articleListWrap {
  padding: 18px 0 30px;
}
.articleListWrap h1 {
  clear: both;
}
.articleListWrap h2 {
  clear: both;
}
.articleListWrap h3 {
  clear: both;
}
.articleListWrap h4 {
  clear: both;
}
.articleListWrap h5 {
  clear: both;
}
.articleListWrap h6 {
  clear: both;
}
.articleListWrap p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.625;
}
.articleListWrap p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.articleListWrap h1:not([class]) {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.articleListWrap h1:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.articleListWrap h2:not([class]) {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 31px 30px;
  background: #ECF5FF;
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
  box-sizing: border-box;
}
.articleListWrap h3:not([class]) {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.articleListWrap h3:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.articleListWrap h4:not([class]) {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 18px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.articleListWrap h4:not([class])::after {
  content: "";
  width: 27%;
  height: 3px;
  display: inline-block;
  background: #36639A;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.articleListWrap h5:not([class]) {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin-top: 40px;
  margin-bottom: 25px;
}
.articleListWrap h6:not([class]) {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.363636;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #36639A;
}
.articleListWrap blockquote {
  position: relative;
  padding: 40px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #ECF5FF;
  clear: both;
}
.articleListWrap blockquote::before {
  content: "“";
  font-size: 7rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #ECF5FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleListWrap blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap blockquote p {
  font-style: normal;
}
.articleListWrap blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleListWrap .btnWrap {
  clear: both;
}
.articleListWrap img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.articleListWrap img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleListWrap img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleListWrap img.aligncenter {
  margin: 30px auto;
  display: block;
}
.articleListWrap ul:not([class]) {
  margin: 60px 0 36px;
  clear: both;
}
.articleListWrap ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  padding-left: 40px;
  position: relative;
}
.articleListWrap ul:not([class]) li::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_li.png) no-repeat center center #ECF5FF;
  background-size: 7px auto;
  background-position: 12px 10px;
  border-radius: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}
.articleListWrap ul:not([class]) li + li {
  margin-top: 26px;
}
.articleListWrap ul:not([class]) h6::before {
  content: none;
}
.articleListWrap ol:not([class]) {
  margin: 60px 0 36px;
  counter-reset: listNum;
  clear: both;
}
.articleListWrap ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  padding: 17px 10px 17px 76px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.articleListWrap ol:not([class]) li::before {
  counter-increment: listNum;
  content: "0" counter(listNum);
  font-size: 1.6rem;
  font-weight: 400;
  color: #36639A;
  width: 60px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.articleListWrap ol:not([class]) li::after {
  content: "";
  width: 60px;
  height: 100%;
  display: inline-block;
  background: #ECF5FF;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 0;
}
.articleListWrap ol:not([class]) li + li {
  margin-top: 10px;
}
.articleListWrap ol:not([class]) h6::before {
  content: none;
}
.articleListWrap > table {
  table-layout: fixed;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleListWrap p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .articleListWrap p:not([class]) + p:not([class]) {
    margin-top: 25px;
  }
  .articleListWrap h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleListWrap h1:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .articleListWrap h2:not([class]) {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .articleListWrap h3:not([class]) {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleListWrap h3:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .articleListWrap h4:not([class]) {
    font-size: 1.8rem;
    line-height: 1.27272;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleListWrap h5:not([class]) {
    font-size: 1.7rem;
    line-height: 1.27272;
    margin-bottom: 20px;
  }
  .articleListWrap h6:not([class]) {
    font-size: 1.6rem;
    padding-left: 8px;
    border-left: 3px solid #36639A;
  }
  .articleListWrap blockquote {
    padding: 50px 3.8461538462% 20px;
    margin: 40px 0 30px;
    clear: both;
  }
  .articleListWrap blockquote::before {
    font-size: 5rem;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 3.8461538462%;
  }
  .articleListWrap img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap img.aligncenter {
    margin: 15px auto 20px;
  }
  .articleListWrap ul:not([class]) {
    margin: 40px 0 20px;
  }
  .articleListWrap ul:not([class]) li {
    font-size: 1.4rem;
    padding-left: 35px;
  }
  .articleListWrap ul:not([class]) li::before {
    width: 25px;
    height: 25px;
    background-size: 7px auto;
    background-position: 10px 8px;
  }
  .articleListWrap ul:not([class]) li + li {
    margin-top: 20px;
  }
  .articleListWrap ol:not([class]) {
    margin: 40px 0 20px;
  }
  .articleListWrap ol:not([class]) li {
    font-size: 1.4rem;
    padding: 11px 10px 11px 57px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  }
  .articleListWrap ol:not([class]) li::before {
    font-size: 1.4rem;
    width: 45px;
  }
  .articleListWrap ol:not([class]) li::after {
    width: 45px;
  }
  .articleListWrap ol:not([class]) li + li {
    margin-top: 7px;
  }
}
.articleListWrap iframe {
  width: 100%;
}
.articleListWrap > h1:first-child {
  margin-top: 0 !important;
}
.articleListWrap > h2:first-child {
  margin-top: 0 !important;
}
.articleListWrap > h3:first-child {
  margin-top: 0 !important;
}
.articleListWrap > h4:first-child {
  margin-top: 0 !important;
}
.articleListWrap > h5:first-child {
  margin-top: 0 !important;
}
.articleListWrap > h6:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h1:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h2:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h3:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h4:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h5:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h6:first-child {
  margin-top: 0 !important;
}

/* singlePage */
.singlePage {
  padding: 17px 0 90px;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    こちらも読まれています
  ---------------------*/
  /*---------------------
    チェックポイント
  ---------------------*/
}
.singlePage h1 {
  clear: both;
}
.singlePage h2 {
  clear: both;
}
.singlePage h3 {
  clear: both;
}
.singlePage h4 {
  clear: both;
}
.singlePage h5 {
  clear: both;
}
.singlePage h6 {
  clear: both;
}
.singlePage p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.625;
}
.singlePage p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.singlePage h1:not([class]) {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.singlePage h1:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.singlePage h2:not([class]) {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 31px 30px;
  background: #ECF5FF;
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
  box-sizing: border-box;
}
.singlePage h3:not([class]) {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.singlePage h3:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage h4:not([class]) {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 18px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.singlePage h4:not([class])::after {
  content: "";
  width: 27%;
  height: 3px;
  display: inline-block;
  background: #36639A;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.singlePage h5:not([class]) {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin-top: 40px;
  margin-bottom: 25px;
}
.singlePage h6:not([class]) {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.363636;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #36639A;
}
.singlePage blockquote {
  position: relative;
  padding: 40px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #ECF5FF;
  clear: both;
}
.singlePage blockquote::before {
  content: "“";
  font-size: 7rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #ECF5FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote p {
  font-style: normal;
}
.singlePage blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage .btnWrap {
  clear: both;
}
.singlePage img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.singlePage img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage img.aligncenter {
  margin: 30px auto;
  display: block;
}
.singlePage ul:not([class]) {
  margin: 60px 0 36px;
  clear: both;
}
.singlePage ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  padding-left: 40px;
  position: relative;
}
.singlePage ul:not([class]) li::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_li.png) no-repeat center center #ECF5FF;
  background-size: 7px auto;
  background-position: 12px 10px;
  border-radius: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}
.singlePage ul:not([class]) li + li {
  margin-top: 26px;
}
.singlePage ul:not([class]) h6::before {
  content: none;
}
.singlePage ol:not([class]) {
  margin: 60px 0 36px;
  counter-reset: listNum;
  clear: both;
}
.singlePage ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  padding: 17px 10px 17px 76px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.singlePage ol:not([class]) li::before {
  counter-increment: listNum;
  content: "0" counter(listNum);
  font-size: 1.6rem;
  font-weight: 400;
  color: #36639A;
  width: 60px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.singlePage ol:not([class]) li::after {
  content: "";
  width: 60px;
  height: 100%;
  display: inline-block;
  background: #ECF5FF;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 0;
}
.singlePage ol:not([class]) li + li {
  margin-top: 10px;
}
.singlePage ol:not([class]) h6::before {
  content: none;
}
.singlePage > table {
  table-layout: fixed;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .singlePage p:not([class]) + p:not([class]) {
    margin-top: 25px;
  }
  .singlePage h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .singlePage h1:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .singlePage h2:not([class]) {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .singlePage h3:not([class]) {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .singlePage h3:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .singlePage h4:not([class]) {
    font-size: 1.8rem;
    line-height: 1.27272;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .singlePage h5:not([class]) {
    font-size: 1.7rem;
    line-height: 1.27272;
    margin-bottom: 20px;
  }
  .singlePage h6:not([class]) {
    font-size: 1.6rem;
    padding-left: 8px;
    border-left: 3px solid #36639A;
  }
  .singlePage blockquote {
    padding: 50px 3.8461538462% 20px;
    margin: 40px 0 30px;
    clear: both;
  }
  .singlePage blockquote::before {
    font-size: 5rem;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 3.8461538462%;
  }
  .singlePage img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage img.aligncenter {
    margin: 15px auto 20px;
  }
  .singlePage ul:not([class]) {
    margin: 40px 0 20px;
  }
  .singlePage ul:not([class]) li {
    font-size: 1.4rem;
    padding-left: 35px;
  }
  .singlePage ul:not([class]) li::before {
    width: 25px;
    height: 25px;
    background-size: 7px auto;
    background-position: 10px 8px;
  }
  .singlePage ul:not([class]) li + li {
    margin-top: 20px;
  }
  .singlePage ol:not([class]) {
    margin: 40px 0 20px;
  }
  .singlePage ol:not([class]) li {
    font-size: 1.4rem;
    padding: 11px 10px 11px 57px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  }
  .singlePage ol:not([class]) li::before {
    font-size: 1.4rem;
    width: 45px;
  }
  .singlePage ol:not([class]) li::after {
    width: 45px;
  }
  .singlePage ol:not([class]) li + li {
    margin-top: 7px;
  }
}
.singlePage iframe {
  width: 100%;
}
.singlePage .articleMv {
  margin: 30px auto 40px;
  text-align: center;
}
.singlePage .tocBox {
  margin: 40px auto 60px;
}
.singlePage .tocBox__inner {
  background: #FBFBFB;
  padding: 2.196193265vw;
}
.singlePage .tocBox__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center;
}
.singlePage .tocBox__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  vertical-align: middle;
  margin: -2px 11px 0 0;
}
.singlePage .tocBox .tocList__item {
  position: relative;
}
.singlePage .tocBox .tocList__item::before {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 0 0 0 20px;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #36639A;
  }
}
.singlePage .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.singlePage .tocBox .tocList__item .tocList {
  padding-left: 26px;
  margin-top: 10px;
}
.singlePage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #36639A;
  text-align: center;
  margin-top: 20px;
  padding: 8px 5px;
  cursor: pointer;
}
.singlePage .tocBox .tocMore::after {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: translateY(2px) rotate(-90deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #36639A;
    background: #fff;
  }
  .singlePage .tocBox .tocMore:hover::after {
    width: 14px;
    height: 9px;
    background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
    background-size: contain;
    transform: rotate(-180deg);
  }
}
.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.singlePage .tocBox:not(.open) .tocMore::after {
  transform: translateY(2px) rotate(90deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(0px);
  }
}
.singlePage .relrated {
  margin: 60px 0;
}
.singlePage .relrated__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-left: 17px;
  position: relative;
}
.singlePage .relrated__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.singlePage .relratedList__item {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
}
.singlePage .relratedList__item .itemWrap {
  padding: 30px;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relratedList__item .itemWrap {
    padding: 2.196193265vw;
  }
}
.singlePage .relratedList__item .itemWrap .thumb {
  width: 33%;
  max-width: 260px;
  margin-right: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relratedList__item .itemWrap .thumb {
    margin-right: 2.196193265vw;
  }
}
.singlePage .relratedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.singlePage .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (33% + 30px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (33% + 2.196193265vw));
  }
}
.singlePage .relratedList__item .itemWrap .textWrap {
  width: 100%;
}
.singlePage .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}
.singlePage .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
@media screen and (min-width: 767px) {
  .singlePage .relratedList__item:hover {
    background: #ECF5FF;
  }
}
.singlePage .relratedList__item + .relratedList__item {
  margin-top: 10px;
}
.singlePage .checkPoint {
  border: 1px dashed #D2D2D2;
  background: #fff;
  padding: 40px 2.9282576867vw 30px;
  margin: 60px 0;
}
.singlePage .checkPoint ul, .singlePage .checkPoint ol {
  margin: 0;
}
.singlePage .checkPoint h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h1::before, .singlePage .checkPoint h1::after {
  content: none;
}
.singlePage .checkPoint h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h2::before, .singlePage .checkPoint h2::after {
  content: none;
}
.singlePage .checkPoint h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h3::before, .singlePage .checkPoint h3::after {
  content: none;
}
.singlePage .checkPoint h4 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h4::before, .singlePage .checkPoint h4::after {
  content: none;
}
.singlePage .checkPoint h5 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h5::before, .singlePage .checkPoint h5::after {
  content: none;
}
.singlePage .checkPoint h6 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.singlePage .checkPoint h6::before, .singlePage .checkPoint h6::after {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
    こちらも読まれています
    ---------------------*/
    /*---------------------
      チェックポイント
    ---------------------*/
  }
  .singlePage .articleMv {
    margin: 20px auto;
  }
  .singlePage .tocBox {
    margin: 40px auto;
  }
  .singlePage .tocBox__inner {
    padding: 30px 4.358974359%;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .singlePage .tocBox__ttl::before {
    height: 22px;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .singlePage .relrated {
    margin: 60px 0;
  }
  .singlePage .relrated__ttl {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .singlePage .relrated__ttl::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .singlePage .relratedList__item {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  }
  .singlePage .relratedList__item .itemWrap {
    padding: 15px 3.5897435897%;
  }
  .singlePage .relratedList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .singlePage .relratedList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .singlePage .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .singlePage .relratedList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
  }
  .singlePage .relratedList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  .singlePage .checkPoint {
    padding: 25px 3.5897435897% 30px;
    margin: 30px auto;
  }
}
.singlePage > h1:first-child {
  margin-top: 0 !important;
}
.singlePage > h2:first-child {
  margin-top: 0 !important;
}
.singlePage > h3:first-child {
  margin-top: 0 !important;
}
.singlePage > h4:first-child {
  margin-top: 0 !important;
}
.singlePage > h5:first-child {
  margin-top: 0 !important;
}
.singlePage > h6:first-child {
  margin-top: 0 !important;
}
.singlePage__in {
  padding: 40px 2.9282576867vw 60px;
  background: #fff;
  border: 1px solid #CCC;
  border-top: 6px solid #36639A;
}
.singlePage__in .clinicWrap, .singlePage__in .areaBox {
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
}

/* 記事詳細ページ */
.articleDetail {
  padding: 40px 2.9282576867vw 40px;
  background: #fff;
  border: 1px solid #CCCCCC;
  border-top: 6px solid #36639A;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    こちらも読まれています
  ---------------------*/
  /*---------------------
    チェックポイント
  ---------------------*/
}
.articleDetail__in h1 {
  clear: both;
}
.articleDetail__in h2 {
  clear: both;
}
.articleDetail__in h3 {
  clear: both;
}
.articleDetail__in h4 {
  clear: both;
}
.articleDetail__in h5 {
  clear: both;
}
.articleDetail__in h6 {
  clear: both;
}
.articleDetail__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.625;
}
.articleDetail__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.articleDetail__in h1:not([class]) {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.articleDetail__in h1:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.articleDetail__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 31px 30px;
  background: #ECF5FF;
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
  box-sizing: border-box;
}
.articleDetail__in h3:not([class]) {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.articleDetail__in h3:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail__in h4:not([class]) {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 18px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.articleDetail__in h4:not([class])::after {
  content: "";
  width: 27%;
  height: 3px;
  display: inline-block;
  background: #36639A;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.articleDetail__in h5:not([class]) {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin-top: 40px;
  margin-bottom: 25px;
}
.articleDetail__in h6:not([class]) {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.363636;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #36639A;
}
.articleDetail__in blockquote {
  position: relative;
  padding: 40px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #ECF5FF;
  clear: both;
}
.articleDetail__in blockquote::before {
  content: "“";
  font-size: 7rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #ECF5FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote p {
  font-style: normal;
}
.articleDetail__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail__in .btnWrap {
  clear: both;
}
.articleDetail__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail__in img.aligncenter {
  margin: 30px auto;
  display: block;
}
.articleDetail__in ul:not([class]) {
  margin: 60px 0 36px;
  clear: both;
}
.articleDetail__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  padding-left: 40px;
  position: relative;
}
.articleDetail__in ul:not([class]) li::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_li.png) no-repeat center center #ECF5FF;
  background-size: 7px auto;
  background-position: 12px 10px;
  border-radius: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}
.articleDetail__in ul:not([class]) li + li {
  margin-top: 26px;
}
.articleDetail__in ul:not([class]) h6::before {
  content: none;
}
.articleDetail__in ol:not([class]) {
  margin: 60px 0 36px;
  counter-reset: listNum;
  clear: both;
}
.articleDetail__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  padding: 17px 10px 17px 76px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.articleDetail__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: "0" counter(listNum);
  font-size: 1.6rem;
  font-weight: 400;
  color: #36639A;
  width: 60px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.articleDetail__in ol:not([class]) li::after {
  content: "";
  width: 60px;
  height: 100%;
  display: inline-block;
  background: #ECF5FF;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 0;
}
.articleDetail__in ol:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail__in ol:not([class]) h6::before {
  content: none;
}
.articleDetail__in > table {
  table-layout: fixed;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail__in p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .articleDetail__in p:not([class]) + p:not([class]) {
    margin-top: 25px;
  }
  .articleDetail__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleDetail__in h1:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .articleDetail__in h2:not([class]) {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .articleDetail__in h3:not([class]) {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleDetail__in h3:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .articleDetail__in h4:not([class]) {
    font-size: 1.8rem;
    line-height: 1.27272;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleDetail__in h5:not([class]) {
    font-size: 1.7rem;
    line-height: 1.27272;
    margin-bottom: 20px;
  }
  .articleDetail__in h6:not([class]) {
    font-size: 1.6rem;
    padding-left: 8px;
    border-left: 3px solid #36639A;
  }
  .articleDetail__in blockquote {
    padding: 50px 3.8461538462% 20px;
    margin: 40px 0 30px;
    clear: both;
  }
  .articleDetail__in blockquote::before {
    font-size: 5rem;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 3.8461538462%;
  }
  .articleDetail__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail__in img.aligncenter {
    margin: 15px auto 20px;
  }
  .articleDetail__in ul:not([class]) {
    margin: 40px 0 20px;
  }
  .articleDetail__in ul:not([class]) li {
    font-size: 1.4rem;
    padding-left: 35px;
  }
  .articleDetail__in ul:not([class]) li::before {
    width: 25px;
    height: 25px;
    background-size: 7px auto;
    background-position: 10px 8px;
  }
  .articleDetail__in ul:not([class]) li + li {
    margin-top: 20px;
  }
  .articleDetail__in ol:not([class]) {
    margin: 40px 0 20px;
  }
  .articleDetail__in ol:not([class]) li {
    font-size: 1.4rem;
    padding: 11px 10px 11px 57px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  }
  .articleDetail__in ol:not([class]) li::before {
    font-size: 1.4rem;
    width: 45px;
  }
  .articleDetail__in ol:not([class]) li::after {
    width: 45px;
  }
  .articleDetail__in ol:not([class]) li + li {
    margin-top: 7px;
  }
}
.articleDetail__in iframe {
  width: 100%;
}
.articleDetail__in > h1:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h2:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h3:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h4:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h5:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h6:first-child {
  margin-top: 0 !important;
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.articleDetail .clinicWrap, .articleDetail .areaBox {
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
}
.articleDetail .articleMv {
  margin: 30px auto 40px;
  text-align: center;
}
.articleDetail .tocBox {
  margin: 40px auto 60px;
}
.articleDetail .tocBox__inner {
  background: #FBFBFB;
  padding: 2.196193265vw;
}
.articleDetail .tocBox__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center;
}
.articleDetail .tocBox__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  vertical-align: middle;
  margin: -2px 11px 0 0;
}
.articleDetail .tocBox .tocList__item {
  position: relative;
}
.articleDetail .tocBox .tocList__item::before {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 0 0 0 20px;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #36639A;
  }
}
.articleDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.articleDetail .tocBox .tocList__item .tocList {
  padding-left: 26px;
  margin-top: 10px;
}
.articleDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #36639A;
  text-align: center;
  margin-top: 20px;
  padding: 8px 5px;
  cursor: pointer;
}
.articleDetail .tocBox .tocMore::after {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: translateY(2px) rotate(-90deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #36639A;
    background: #fff;
  }
  .articleDetail .tocBox .tocMore:hover::after {
    width: 14px;
    height: 9px;
    background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
    background-size: contain;
    transform: rotate(-180deg);
  }
}
.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: translateY(2px) rotate(90deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(0px);
  }
}
.articleDetail .relrated {
  margin: 60px 0;
}
.articleDetail .relrated__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-left: 17px;
  position: relative;
}
.articleDetail .relrated__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.articleDetail .relratedList__item {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
}
.articleDetail .relratedList__item .itemWrap {
  padding: 30px;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relratedList__item .itemWrap {
    padding: 2.196193265vw;
  }
}
.articleDetail .relratedList__item .itemWrap .thumb {
  width: 33%;
  max-width: 260px;
  margin-right: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relratedList__item .itemWrap .thumb {
    margin-right: 2.196193265vw;
  }
}
.articleDetail .relratedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.articleDetail .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (33% + 30px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (33% + 2.196193265vw));
  }
}
.articleDetail .relratedList__item .itemWrap .textWrap {
  width: 100%;
}
.articleDetail .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}
.articleDetail .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
@media screen and (min-width: 767px) {
  .articleDetail .relratedList__item:hover {
    background: #ECF5FF;
  }
}
.articleDetail .relratedList__item + .relratedList__item {
  margin-top: 10px;
}
.articleDetail .checkPoint {
  border: 1px dashed #D2D2D2;
  background: #fff;
  padding: 40px 2.9282576867vw 30px;
  margin: 60px 0;
}
.articleDetail .checkPoint ul, .articleDetail .checkPoint ol {
  margin: 0;
}
.articleDetail .checkPoint h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h1::before, .articleDetail .checkPoint h1::after {
  content: none;
}
.articleDetail .checkPoint h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h2::before, .articleDetail .checkPoint h2::after {
  content: none;
}
.articleDetail .checkPoint h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h3::before, .articleDetail .checkPoint h3::after {
  content: none;
}
.articleDetail .checkPoint h4 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h4::before, .articleDetail .checkPoint h4::after {
  content: none;
}
.articleDetail .checkPoint h5 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h5::before, .articleDetail .checkPoint h5::after {
  content: none;
}
.articleDetail .checkPoint h6 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.articleDetail .checkPoint h6::before, .articleDetail .checkPoint h6::after {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
    こちらも読まれています
    ---------------------*/
    /*---------------------
      チェックポイント
    ---------------------*/
  }
  .articleDetail .articleMv {
    margin: 20px auto;
  }
  .articleDetail .tocBox {
    margin: 40px auto;
  }
  .articleDetail .tocBox__inner {
    padding: 30px 4.358974359%;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .articleDetail .tocBox__ttl::before {
    height: 22px;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .articleDetail .relrated {
    margin: 60px 0;
  }
  .articleDetail .relrated__ttl {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .articleDetail .relrated__ttl::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .articleDetail .relratedList__item {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  }
  .articleDetail .relratedList__item .itemWrap {
    padding: 15px 3.5897435897%;
  }
  .articleDetail .relratedList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .articleDetail .relratedList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .articleDetail .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .articleDetail .relratedList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
  }
  .articleDetail .relratedList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  .articleDetail .checkPoint {
    padding: 25px 3.5897435897% 30px;
    margin: 30px auto;
  }
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 0;
  }
  .singlePage > h1:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h2:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h3:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h4:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h5:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h6:first-child {
    width: 92.3076923077%;
    margin: 0 auto 20px;
  }
  .singlePage > h2:first-child {
    width: 100%;
    margin-left: 0;
  }
  .singlePage__in {
    padding: 30px 3.8461538462% 40px;
  }
  .singlePage__in .clinicWrap, .singlePage__in .areaBox {
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 30px 3.8461538462% 40px;
  }
  .articleDetail .clinicWrap, .articleDetail .areaBox {
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .articleDetail .clinicWrap .btn--search {
    width: 92.3076923077%;
    margin: 0 auto;
  }
  /* 記事一覧 */
  .articleList__item .itemWrap {
    padding: 20px 0;
  }
  .articleList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .articleList__item .itemWrap .thumb img {
    margin: 0 auto;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
  }
  .articleList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  /* 記事一覧ページ */
  .articleListWrap {
    padding: 0 3.8461538462%;
  }
  .articleListWrap__in .pager + h1 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h2 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h3 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h4 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h5 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h6 {
    margin-top: 50px;
  }
}
/*=================================
  下層ページ共通
=================================*/
/* 地域・クリニック詳細ページ */
.clinicDetail {
  padding: 40px 2.9282576867vw 40px;
  background: #fff;
  border: 1px solid #CCCCCC;
  border-top: 6px solid #36639A;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    こちらも読まれています
  ---------------------*/
  /*---------------------
    チェックポイント
  ---------------------*/
  /* 個別設定 */
  /*============================
    クリニック詳細
  ============================*/
  /* キャッチコピー */
  /* スライダー */
  /* 料金表 */
  /* タグ */
  /* 選ばれる理由 */
  /* アクセス */
}
.clinicDetail__in {
  /* 個別設定 */
}
.clinicDetail__in > :last-child.areaBox {
  margin-bottom: -40px;
}
.clinicDetail__in h1 {
  clear: both;
}
.clinicDetail__in h2 {
  clear: both;
}
.clinicDetail__in h3 {
  clear: both;
}
.clinicDetail__in h4 {
  clear: both;
}
.clinicDetail__in h5 {
  clear: both;
}
.clinicDetail__in h6 {
  clear: both;
}
.clinicDetail__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.625;
}
.clinicDetail__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.clinicDetail__in h1:not([class]) {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.clinicDetail__in h1:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.clinicDetail__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 31px 30px;
  background: #ECF5FF;
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
  box-sizing: border-box;
}
.clinicDetail__in h3:not([class]) {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.clinicDetail__in h3:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.clinicDetail__in h4:not([class]) {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 18px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.clinicDetail__in h4:not([class])::after {
  content: "";
  width: 27%;
  height: 3px;
  display: inline-block;
  background: #36639A;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.clinicDetail__in h5:not([class]) {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin-top: 40px;
  margin-bottom: 25px;
}
.clinicDetail__in h6:not([class]) {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.363636;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #36639A;
}
.clinicDetail__in blockquote {
  position: relative;
  padding: 40px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #ECF5FF;
  clear: both;
}
.clinicDetail__in blockquote::before {
  content: "“";
  font-size: 7rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #ECF5FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.clinicDetail__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.clinicDetail__in blockquote p {
  font-style: normal;
}
.clinicDetail__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.clinicDetail__in .btnWrap {
  clear: both;
}
.clinicDetail__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.clinicDetail__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.clinicDetail__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.clinicDetail__in img.aligncenter {
  margin: 30px auto;
  display: block;
}
.clinicDetail__in ul:not([class]) {
  margin: 60px 0 36px;
  clear: both;
}
.clinicDetail__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  padding-left: 40px;
  position: relative;
}
.clinicDetail__in ul:not([class]) li::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_li.png) no-repeat center center #ECF5FF;
  background-size: 7px auto;
  background-position: 12px 10px;
  border-radius: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}
.clinicDetail__in ul:not([class]) li + li {
  margin-top: 26px;
}
.clinicDetail__in ul:not([class]) h6::before {
  content: none;
}
.clinicDetail__in ol:not([class]) {
  margin: 60px 0 36px;
  counter-reset: listNum;
  clear: both;
}
.clinicDetail__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  padding: 17px 10px 17px 76px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.clinicDetail__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: "0" counter(listNum);
  font-size: 1.6rem;
  font-weight: 400;
  color: #36639A;
  width: 60px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.clinicDetail__in ol:not([class]) li::after {
  content: "";
  width: 60px;
  height: 100%;
  display: inline-block;
  background: #ECF5FF;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 0;
}
.clinicDetail__in ol:not([class]) li + li {
  margin-top: 10px;
}
.clinicDetail__in ol:not([class]) h6::before {
  content: none;
}
.clinicDetail__in > table {
  table-layout: fixed;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .clinicDetail__in p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .clinicDetail__in p:not([class]) + p:not([class]) {
    margin-top: 25px;
  }
  .clinicDetail__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .clinicDetail__in h1:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .clinicDetail__in h2:not([class]) {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .clinicDetail__in h3:not([class]) {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .clinicDetail__in h3:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .clinicDetail__in h4:not([class]) {
    font-size: 1.8rem;
    line-height: 1.27272;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .clinicDetail__in h5:not([class]) {
    font-size: 1.7rem;
    line-height: 1.27272;
    margin-bottom: 20px;
  }
  .clinicDetail__in h6:not([class]) {
    font-size: 1.6rem;
    padding-left: 8px;
    border-left: 3px solid #36639A;
  }
  .clinicDetail__in blockquote {
    padding: 50px 3.8461538462% 20px;
    margin: 40px 0 30px;
    clear: both;
  }
  .clinicDetail__in blockquote::before {
    font-size: 5rem;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 3.8461538462%;
  }
  .clinicDetail__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .clinicDetail__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .clinicDetail__in img.aligncenter {
    margin: 15px auto 20px;
  }
  .clinicDetail__in ul:not([class]) {
    margin: 40px 0 20px;
  }
  .clinicDetail__in ul:not([class]) li {
    font-size: 1.4rem;
    padding-left: 35px;
  }
  .clinicDetail__in ul:not([class]) li::before {
    width: 25px;
    height: 25px;
    background-size: 7px auto;
    background-position: 10px 8px;
  }
  .clinicDetail__in ul:not([class]) li + li {
    margin-top: 20px;
  }
  .clinicDetail__in ol:not([class]) {
    margin: 40px 0 20px;
  }
  .clinicDetail__in ol:not([class]) li {
    font-size: 1.4rem;
    padding: 11px 10px 11px 57px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  }
  .clinicDetail__in ol:not([class]) li::before {
    font-size: 1.4rem;
    width: 45px;
  }
  .clinicDetail__in ol:not([class]) li::after {
    width: 45px;
  }
  .clinicDetail__in ol:not([class]) li + li {
    margin-top: 7px;
  }
}
.clinicDetail__in iframe {
  width: 100%;
}
.clinicDetail__in > h1:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in > h2:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in > h3:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in > h4:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in > h5:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in > h6:first-child {
  margin-top: 0 !important;
}
.clinicDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.clinicDetail__in > table.left, .clinicDetail__in > table.infoTable {
  table-layout: auto;
}
.clinicDetail__in > table.left th, .clinicDetail__in > table.left td, .clinicDetail__in > table.infoTable th, .clinicDetail__in > table.infoTable td {
  text-align: left;
}
.clinicDetail__in > table.left .labelList, .clinicDetail__in > table.infoTable .labelList {
  border: none;
  padding: 0;
  margin: 0;
}
.clinicDetail .clinicWrap, .clinicDetail .areaBox {
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
}
.clinicDetail .articleMv {
  margin: 30px auto 40px;
  text-align: center;
}
.clinicDetail .tocBox {
  margin: 40px auto 60px;
}
.clinicDetail .tocBox__inner {
  background: #FBFBFB;
  padding: 2.196193265vw;
}
.clinicDetail .tocBox__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center;
}
.clinicDetail .tocBox__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  vertical-align: middle;
  margin: -2px 11px 0 0;
}
.clinicDetail .tocBox .tocList__item {
  position: relative;
}
.clinicDetail .tocBox .tocList__item::before {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.clinicDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 0 0 0 20px;
}
@media screen and (min-width: 767px) {
  .clinicDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #36639A;
  }
}
.clinicDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.clinicDetail .tocBox .tocList__item .tocList {
  padding-left: 26px;
  margin-top: 10px;
}
.clinicDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.clinicDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #36639A;
  text-align: center;
  margin-top: 20px;
  padding: 8px 5px;
  cursor: pointer;
}
.clinicDetail .tocBox .tocMore::after {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: translateY(2px) rotate(-90deg);
}
@media screen and (min-width: 767px) {
  .clinicDetail .tocBox .tocMore:hover {
    color: #36639A;
    background: #fff;
  }
  .clinicDetail .tocBox .tocMore:hover::after {
    width: 14px;
    height: 9px;
    background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
    background-size: contain;
    transform: rotate(-180deg);
  }
}
.clinicDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.clinicDetail .tocBox:not(.open) .tocMore::after {
  transform: translateY(2px) rotate(90deg);
}
@media screen and (min-width: 767px) {
  .clinicDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(0px);
  }
}
.clinicDetail .relrated {
  margin: 60px 0;
}
.clinicDetail .relrated__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-left: 17px;
  position: relative;
}
.clinicDetail .relrated__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.clinicDetail .relratedList__item {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
}
.clinicDetail .relratedList__item .itemWrap {
  padding: 30px;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .relratedList__item .itemWrap {
    padding: 2.196193265vw;
  }
}
.clinicDetail .relratedList__item .itemWrap .thumb {
  width: 33%;
  max-width: 260px;
  margin-right: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .relratedList__item .itemWrap .thumb {
    margin-right: 2.196193265vw;
  }
}
.clinicDetail .relratedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.clinicDetail .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (33% + 30px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (33% + 2.196193265vw));
  }
}
.clinicDetail .relratedList__item .itemWrap .textWrap {
  width: 100%;
}
.clinicDetail .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}
.clinicDetail .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
@media screen and (min-width: 767px) {
  .clinicDetail .relratedList__item:hover {
    background: #ECF5FF;
  }
}
.clinicDetail .relratedList__item + .relratedList__item {
  margin-top: 10px;
}
.clinicDetail .checkPoint {
  border: 1px dashed #D2D2D2;
  background: #fff;
  padding: 40px 2.9282576867vw 30px;
  margin: 60px 0;
}
.clinicDetail .checkPoint ul, .clinicDetail .checkPoint ol {
  margin: 0;
}
.clinicDetail .checkPoint h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h1::before, .clinicDetail .checkPoint h1::after {
  content: none;
}
.clinicDetail .checkPoint h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h2::before, .clinicDetail .checkPoint h2::after {
  content: none;
}
.clinicDetail .checkPoint h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h3::before, .clinicDetail .checkPoint h3::after {
  content: none;
}
.clinicDetail .checkPoint h4 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h4::before, .clinicDetail .checkPoint h4::after {
  content: none;
}
.clinicDetail .checkPoint h5 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h5::before, .clinicDetail .checkPoint h5::after {
  content: none;
}
.clinicDetail .checkPoint h6 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.clinicDetail .checkPoint h6::before, .clinicDetail .checkPoint h6::after {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .clinicDetail {
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
    こちらも読まれています
    ---------------------*/
    /*---------------------
      チェックポイント
    ---------------------*/
  }
  .clinicDetail .articleMv {
    margin: 20px auto;
  }
  .clinicDetail .tocBox {
    margin: 40px auto;
  }
  .clinicDetail .tocBox__inner {
    padding: 30px 4.358974359%;
  }
  .clinicDetail .tocBox__ttl {
    font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .clinicDetail .tocBox__ttl::before {
    height: 22px;
  }
  .clinicDetail .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .clinicDetail .relrated {
    margin: 60px 0;
  }
  .clinicDetail .relrated__ttl {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .clinicDetail .relrated__ttl::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .clinicDetail .relratedList__item {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  }
  .clinicDetail .relratedList__item .itemWrap {
    padding: 15px 3.5897435897%;
  }
  .clinicDetail .relratedList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .clinicDetail .relratedList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .clinicDetail .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .clinicDetail .relratedList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
  }
  .clinicDetail .relratedList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  .clinicDetail .checkPoint {
    padding: 25px 3.5897435897% 30px;
    margin: 30px auto;
  }
}
.clinicDetail .clinicList__item:first-child {
  border-top: none;
}
.clinicDetail .catchCopy {
  font-size: 1.6rem;
  font-weight: 400;
  margin: -10px 0 40px 0;
}
.clinicDetail .catchCopy + .labelList {
  margin-top: -30px;
}
.clinicDetail .photoWrap img {
  margin: 0 auto;
  max-height: 400px;
}
.clinicDetail .photoWrap .photoList .slick-track {
  display: flex;
  align-items: center;
}
.clinicDetail .photoWrap .slick-dots {
  position: static;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 20px);
  margin: 20px 0 0 -10px;
}
.clinicDetail .photoWrap .slick-dots li {
  width: calc((100% - 80px) / 4);
  height: initial;
  margin: 10px 10px;
}
.clinicDetail .priceTable {
  border: none;
}
.clinicDetail .priceTable th, .clinicDetail .priceTable td {
  font-size: 2.2rem;
  padding: 20px 20px;
  border-right: 1px dashed #D2D2D2;
  border-left: 1px dashed #D2D2D2;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .priceTable th, .clinicDetail .priceTable td {
    font-size: 1.9033674963vw;
    padding: 20px 1.4641288433vw;
  }
}
.clinicDetail .priceTable th {
  color: #333333;
  background: #FBFBFB;
  padding: 16px 15px;
}
.clinicDetail .priceTable td {
  padding: 18px 15px;
}
.clinicDetail .priceTable thead th {
  color: #333333;
  background: #FBFBFB;
}
.clinicDetail .priceTable thead td {
  border-bottom: none;
}
.clinicDetail .priceTable tbody td {
  border-bottom: none;
}
.clinicDetail .priceTable tbody th {
  border-bottom: none;
}
.clinicDetail .priceTable .text--blue {
  font-weight: bold;
  color: #36639A;
}
.clinicDetail .priceTable .text--gold {
  font-weight: bold;
  color: #AB9B4E;
}
.clinicDetail .priceTable .text--red {
  font-size: 3.4rem;
  font-weight: bold;
  color: #C62828;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .priceTable .text--red {
    font-size: 2.4890190337vw;
  }
}
.clinicDetail .priceTable .text--price {
  font-weight: bold;
}
.clinicDetail .priceTable .text--price .num {
  font-size: 3.8rem;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .priceTable .text--price .num {
    font-size: 2.7818448023vw;
  }
}
.clinicDetail .linkNext {
  text-align: right;
}
.clinicDetail .linkNext a {
  font-size: 1.8rem;
  text-decoration: none;
  padding-left: 23px;
  position: relative;
}
@media screen and (min-width: 767px) {
  .clinicDetail .linkNext a:hover {
    text-decoration: underline;
  }
}
.clinicDetail .linkNext a::before {
  content: "";
  width: 8px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px 0 0;
}
.clinicDetail .labelList {
  border-top: 1px dashed #D2D2D2;
  border-bottom: 1px dashed #D2D2D2;
  padding: 25px 0;
  margin: 30px 0;
}
.clinicDetail .labelList__item {
  font-size: 1.4rem;
  font-weight: 400;
  display: inline-block;
  background: #FBFBFB;
  padding: 5px 13px 4px;
  margin: 5px 3px;
}
.clinicDetail .labelList__item.menu {
  color: #fff;
  background: #36639A;
}
.clinicDetail .labelList.menu {
  border: none;
  padding: 0;
}
.clinicDetail .clinicPr {
  padding: 40px;
  margin: 40px 0 60px;
  background: #fff;
  border: 1px dashed #D2D2D2;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicDetail .clinicPr {
    padding: 40px 2.9282576867vw;
  }
}
.clinicDetail .clinicPr__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  margin-bottom: 16px;
  padding-left: 31px;
  position: relative;
}
.clinicDetail .clinicPr__ttl::before {
  content: "";
  width: 20px;
  height: 25px;
  display: inline-block;
  background: url(../img/icon/icon_tooth.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -2px;
  left: 0;
}
.clinicDetail .clinicPr__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
.clinicDetail .gMap {
  position: relative;
  padding-bottom: 47.67%;
  height: 0;
  overflow: hidden;
}
.clinicDetail .gMap iframe, .clinicDetail .gMap object, .clinicDetail .gMap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 検索パーツ */
.searchWrap .result {
  font-size: 1.6rem;
  margin: 30px 0 40px;
}
.searchWrap .result .num {
  font-weight: bold;
  color: #36639A;
}
.searchWrap .btnWrap {
  margin: 40px auto 37px;
}
.searchWrap .linkBack {
  text-align: center;
}
.searchWrap .linkBack a {
  font-size: 1.6rem;
  text-decoration: none;
  padding-left: 23px;
  position: relative;
}
@media screen and (min-width: 767px) {
  .searchWrap .linkBack a:hover {
    text-decoration: underline;
  }
}
.searchWrap .linkBack a::before {
  content: "";
  width: 10px;
  height: 15px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 3px;
  left: 0;
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  /* 地域・クリニック詳細ページ */
  .clinicDetail {
    padding: 30px 3.8461538462% 40px;
    /*============================
      クリニック詳細
    ============================*/
    /* キャッチコピー */
    /* スライダー */
    /* 料金表 */
    /* タグ */
    /* 選ばれる理由 */
    /* アクセス */
  }
  .clinicDetail__in {
    /* 個別設定 */
  }
  .clinicDetail__in > table.left, .clinicDetail__in > table.infoTable {
    display: table;
    white-space: initial;
  }
  .clinicDetail__in > table.left th, .clinicDetail__in > table.infoTable th {
    padding: 18px 8px;
  }
  .clinicDetail .clinicWrap, .clinicDetail .areaBox {
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .clinicDetail .clinicWrap .btn--search {
    width: 92.3076923077%;
    margin: 0 auto;
  }
  .clinicDetail .catchCopy {
    margin: -10px 0 30px 0;
  }
  .clinicDetail .catchCopy + .labelList {
    margin-top: -30px;
  }
  .clinicDetail .photoWrap img {
    max-height: 239px;
  }
  .clinicDetail .photoWrap .slick-dots {
    width: calc(100% + 10px);
    margin: 0 0 0 -5px;
  }
  .clinicDetail .photoWrap .slick-dots li {
    width: calc((100% - 30px) / 3);
    height: initial;
    margin: 11px 5px;
  }
  .clinicDetail .priceTable {
    display: table;
    white-space: initial;
  }
  .clinicDetail .priceTable th, .clinicDetail .priceTable td {
    font-size: 3.5897435897vw;
    padding: 11px 1.2820512821%;
  }
  .clinicDetail .priceTable th {
    padding: 11px 1.2820512821%;
  }
  .clinicDetail .priceTable td {
    padding: 7px 1.2820512821%;
  }
  .clinicDetail .priceTable thead {
    border: none;
  }
  .clinicDetail .priceTable tbody {
    border: none;
  }
  .clinicDetail .priceTable .text--red {
    font-size: 6.1538461538vw;
  }
  .clinicDetail .priceTable .text--price {
    font-weight: bold;
  }
  .clinicDetail .priceTable .text--price .num {
    font-size: 4.358974359vw;
  }
  .clinicDetail .linkNext a {
    font-size: 1.6rem;
    padding-left: 14px;
  }
  .clinicDetail .labelList__item {
    font-size: 1.2rem;
    border: 1px solid #36639A;
    border-radius: 2px;
    background: #fff;
    padding: 3px 9px 2px;
    margin: 3px 2px;
  }
  .clinicDetail .clinicPr {
    padding: 40px 7.6923076923% 37px;
    margin: 40px 0;
  }
  .clinicDetail .clinicPr__ttl {
    margin-bottom: 18px;
    padding-left: 27px;
  }
  .clinicDetail .gMap {
    padding-bottom: 93.203%;
  }
  /* 検索パーツ */
  .searchWrap .result {
    margin: 24px 0 40px;
  }
  .searchWrap .btnWrap {
    margin: 40px auto 30px;
  }
  .searchWrap .linkBack a {
    font-size: 1.4rem;
    padding-left: 19px;
  }
  .searchWrap .linkBack a::before {
    width: 9px;
    height: 14px;
    top: 2px;
  }
}
/*=================================
  お問い合わせ
=================================*/
.formBox h1 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h2 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h3 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h6 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox .infoBox {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.formBox .infoBox .thumb {
  width: 42%;
  max-width: 359px;
  margin-right: 46px;
}
.formBox .infoBox .thumb img {
  margin: 0;
}
.formBox .infoBox .contact {
  width: calc(100% - (42% + 46px));
}
.formBox .infoBox .contact .infoBoxTtl {
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 400;
}
.formBox .infoBox .contact .address .info--tel {
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .tel .num {
  font-size: 4.9rem;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .formBox .infoBox .contact .address .info--tel .tel .num {
    pointer-events: none;
  }
}
.formBox .infoBox .contact .address .info--tel .tel .num::before {
  content: "";
  width: 38px;
  height: 38px;
  display: inline-block;
  background: url(../img/icon/icon_tel.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
.formBox .infoBox .contact .address .info--tel .tel .num:hover {
  opacity: 0.8;
}
.formBox .infoBox .contact .address .info--tel .receptionTime {
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dt {
  width: 5em;
  font-weight: bold;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dd {
  width: calc(100% - 5em);
}
.formBox .step {
  display: inline-block;
  counter-reset: stepNum;
  margin-bottom: 55px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step::before {
  content: "";
  width: calc(100% - 5.5rem);
  height: 1px;
  display: inline-block;
  background: #D2D2D2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 26px;
  display: inline-block;
  position: relative;
}
.formBox .step__item::before {
  counter-increment: stepNum;
  content: "STEP0" counter(stepNum);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  color: #36639A;
  margin-bottom: 10px;
}
.formBox .step__item::after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50px;
  background: #D2D2D2;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item.active::after {
  background: #36639A;
}
.formBox .step__item + .step__item {
  margin-left: 59px;
}
.formBox .btnWrap {
  margin-top: 40px;
}
.formBox .btn--form {
  text-align: center;
}
.formBox .btn--form:not(.back) input {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 30px 68px 28px;
  border-radius: 5px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 5px 0 0px #776D37;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .formBox .btn--form:not(.back):hover input {
    box-shadow: none !important;
    transform: translateY(5px);
  }
}
.formBox .btn--form.back input {
  font-size: 1.4rem;
  color: #999;
  cursor: pointer;
}

/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}

.contactForm table {
  width: 100%;
}
.contactForm th {
  font-size: 1.6rem;
  padding: 15px 65px 15px 20px;
  text-align: left;
  width: 170px;
  position: relative;
}
.contactForm th .sub {
  font-size: 1.4rem;
  display: block;
}
.contactForm th span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.contactForm th .required {
  background: #C62828 !important;
}
.contactForm td {
  font-size: 1.6rem;
  padding: 17px 15px 17px 20px;
  width: calc(100% - 170px);
  text-align: left;
}
.contactForm td p {
  line-height: 1.65;
}
.contactForm td * {
  font-size: 1.6rem;
}
.contactForm td input, .contactForm td textarea, .contactForm td select {
  background: #fff;
  border: 2px solid #D2D2D2;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.contactForm td textarea {
  min-height: 170px;
}
.contactForm td .error {
  font-size: 1.4rem;
  color: #C62828;
  margin: 10px 0 0;
  display: inline-block;
}
.contactForm .confirmingTxt {
  text-align: center;
  margin: 40px 0;
}
.contactForm .confirmingTxt p {
  font-size: 1.6rem;
}
.contactForm .confirmingTxt .checkText {
  font-size: 1.8rem;
  margin-top: 40px;
  display: inline-block;
}
.contactForm .confirmingTxt .checkText input {
  margin-right: 10px;
}
.contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.contactForm .confirmingTxt a {
  font-weight: bold;
  text-decoration: none;
}
.contactForm .confirmingTxt a:hover {
  text-decoration: underline;
}

.consultForm__item {
  display: flex;
  align-items: center;
  background: #FBFBFB;
  padding: 38px 20px;
  border: 1px solid #D2D2D2;
}
.consultForm__item + .consultForm__item {
  margin-top: 20px;
}
.consultForm__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #36639A;
  width: 230px;
  padding-right: 55px;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: 1.4rem;
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.consultForm__ttl .required {
  background: #C62828 !important;
}
.consultForm__list {
  font-size: 1.6rem;
  width: calc(93% - 230px);
  padding-left: 27px;
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: 1.6rem;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #fff;
  border: 2px solid #D2D2D2;
  padding: 10px;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;
  background-color: #36639A;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 4px;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: 11px;
  height: 7px;
  display: inline-block;
  background: url(../img/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 5px;
}
.consultForm__list textarea {
  min-height: 170px;
}
.consultForm__list .error {
  font-size: 1.4rem;
  color: #C62828;
  margin: 10px 0 0;
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #36639A;
  width: 85px;
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - 85px);
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - 15px) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - 15px) / 2) !important;
  margin-left: 15px;
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: 15px;
}
.consultForm .confirmingTxt {
  text-align: center;
  margin: 37px 0;
}
.consultForm .confirmingTxt p {
  font-size: 1.4rem;
}
.consultForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.consultForm .confirmingTxt a {
  text-decoration: underline;
}
.consultForm .confirmingTxt a:hover {
  color: #36639A;
}
.consultForm + .consultForm {
  border-top: 1px dashed #D2D2D2;
  padding-top: 20px;
  margin-top: 20px;
}

.completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.06em;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #36639A;
  display: inline-block;
}
.completedForm p {
  font-size: 1.5rem;
  text-align: center;
  margin: 40px 0 0;
}
.completedForm table {
  width: initial;
  margin: 40px auto 45px;
}
.completedForm th, .completedForm td {
  width: auto;
}
.completedForm th {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td {
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td .tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 767px) {
  .completedForm td .tel {
    pointer-events: none;
  }
}
.completedForm td .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.completedForm td .receptionTime dt {
  font-size: 1.3rem;
  font-weight: bold;
  color: #36639A;
  width: 5.5em;
}
.completedForm td .receptionTime dd {
  font-size: 1.3rem;
  width: calc(100% - 5.5em);
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox h1 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h5 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox .infoBox {
    display: block;
    margin-top: 0;
    overflow: hidden;
  }
  .formBox .infoBox .thumb {
    width: 30%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  .formBox .infoBox .contact {
    width: 100%;
  }
  .formBox .infoBox .contact .infoBoxTtl {
    font-size: 1.5rem;
    line-height: 1.5;
    width: calc(100% - (30% + 15px));
    margin-left: auto;
  }
  .formBox .infoBox .contact .address .info--tel {
    margin-top: 0;
  }
  .formBox .infoBox .contact .address .info--tel .tel {
    width: calc(100% - (30% + 15px));
    margin-left: auto;
    margin-top: 15px;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num {
    font-size: 1.8rem;
    color: #fff;
    background: #36639A;
    display: block;
    padding: 10px 10px;
    border-radius: 3px;
    text-align: center;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: url(../img/icon/icon_tel_w.png) no-repeat center center;
    background-size: contain;
    margin: 0 7px -3px 0;
  }
  .formBox .infoBox .contact .address .info--tel .receptionTime {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 15px;
  }
  .formBox .step {
    margin-bottom: 40px;
  }
  .formBox .step::before {
    width: calc(100% - 5rem);
  }
  .formBox .step__item {
    font-size: 1.6rem;
    padding-top: 20px;
  }
  .formBox .step__item::before {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .formBox .step__item::after {
    width: 13px;
    height: 13px;
    top: -6px;
  }
  .formBox .step__item.active::after {
    background: #36639A;
  }
  .formBox .step__item + .step__item {
    margin-left: 15.1282051282vw;
  }
  .formBox .btnWrap {
    margin-top: 35px;
  }
  .formBox .btn--form:not(.back) input {
    font-size: 1.6rem;
    padding: 15px 3.5897435897% 13px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #776D37;
    width: calc(50% - 8px);
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm table {
    display: table;
    white-space: initial;
  }
  .contactForm th {
    font-size: 1.5rem;
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm th span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .contactForm td {
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm td p {
    line-height: 1.5;
  }
  .contactForm td input, .contactForm td textarea, .contactForm td select {
    padding: 12px;
  }
  .contactForm td .error {
    font-size: 1.3rem;
  }
  .contactForm .confirmingTxt {
    margin: 30px 0;
  }
  .contactForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .contactForm .confirmingTxt .checkText {
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .consultForm__item {
    display: block;
    padding: 10px 3.5897435897% 20px;
  }
  .consultForm__ttl {
    font-size: 1.5rem;
    padding: 15px 0;
    width: 100%;
    display: block;
  }
  .consultForm__ttl .sub {
    font-size: 1.4rem;
    display: inline-block;
  }
  .consultForm__ttl span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .consultForm__list {
    width: 100%;
    padding-left: 0;
  }
  .consultForm__list p {
    line-height: 1.5;
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 0.5rem;
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 0.5rem;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    width: 15px;
    height: 10px;
    top: 6px;
    left: 3px;
  }
  .consultForm__list .error {
    font-size: 1.3rem;
  }
  .consultForm__list .yoyaku {
    flex-wrap: wrap;
  }
  .consultForm__list .yoyaku .rank {
    width: 100%;
    margin-bottom: 10px;
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: 100%;
    display: block;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: 100% !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: 100% !important;
    margin: 10px 0 0;
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: 20px;
  }
  .consultForm .confirmingTxt {
    text-align: center;
    margin: 37px 0;
  }
  .consultForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .completedForm p {
    margin: 30px 0 0;
  }
  .completedForm table {
    display: table;
    white-space: initial;
    margin: 40px auto 45px;
  }
  .completedForm th, .completedForm td {
    width: auto;
  }
  .completedForm th {
    font-size: 1.4rem;
    padding: 15px 3.5897435897%;
  }
  .completedForm td {
    padding: 15px 3.5897435897%;
  }
  .completedForm td .tel {
    font-size: 3rem;
  }
  .completedForm td .receptionTime {
    text-align: left;
  }
}
/*=================================
  LP
=================================*/
.lpPage {
  padding: 17px 0 90px;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    こちらも読まれています
  ---------------------*/
  /*---------------------
    チェックポイント
  ---------------------*/
}
.lpPage h1 {
  clear: both;
}
.lpPage h2 {
  clear: both;
}
.lpPage h3 {
  clear: both;
}
.lpPage h4 {
  clear: both;
}
.lpPage h5 {
  clear: both;
}
.lpPage h6 {
  clear: both;
}
.lpPage p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.625;
}
.lpPage p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.lpPage h1:not([class]) {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.lpPage h1:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.lpPage h2:not([class]) {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 31px 30px;
  background: #ECF5FF;
  width: calc(100% + 5.8565153734vw);
  margin-left: -2.9282576867vw;
  box-sizing: border-box;
}
.lpPage h3:not([class]) {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
}
.lpPage h3:not([class])::before {
  content: "";
  width: 5px;
  height: 40px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.lpPage h4:not([class]) {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 18px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.lpPage h4:not([class])::after {
  content: "";
  width: 27%;
  height: 3px;
  display: inline-block;
  background: #36639A;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.lpPage h5:not([class]) {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin-top: 40px;
  margin-bottom: 25px;
}
.lpPage h6:not([class]) {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.363636;
  padding-left: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #36639A;
}
.lpPage blockquote {
  position: relative;
  padding: 40px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #ECF5FF;
  clear: both;
}
.lpPage blockquote::before {
  content: "“";
  font-size: 7rem;
  font-weight: bold;
  color: #36639A;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #ECF5FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.lpPage blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.lpPage blockquote p {
  font-style: normal;
}
.lpPage blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.lpPage .btnWrap {
  clear: both;
}
.lpPage img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.lpPage img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.lpPage img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.lpPage img.aligncenter {
  margin: 30px auto;
  display: block;
}
.lpPage ul:not([class]) {
  margin: 60px 0 36px;
  clear: both;
}
.lpPage ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  padding-left: 40px;
  position: relative;
}
.lpPage ul:not([class]) li::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_li.png) no-repeat center center #ECF5FF;
  background-size: 7px auto;
  background-position: 12px 10px;
  border-radius: 50px;
  position: absolute;
  top: -1px;
  left: 0;
}
.lpPage ul:not([class]) li + li {
  margin-top: 26px;
}
.lpPage ul:not([class]) h6::before {
  content: none;
}
.lpPage ol:not([class]) {
  margin: 60px 0 36px;
  counter-reset: listNum;
  clear: both;
}
.lpPage ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  padding: 17px 10px 17px 76px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.lpPage ol:not([class]) li::before {
  counter-increment: listNum;
  content: "0" counter(listNum);
  font-size: 1.6rem;
  font-weight: 400;
  color: #36639A;
  width: 60px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.lpPage ol:not([class]) li::after {
  content: "";
  width: 60px;
  height: 100%;
  display: inline-block;
  background: #ECF5FF;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 0;
}
.lpPage ol:not([class]) li + li {
  margin-top: 10px;
}
.lpPage ol:not([class]) h6::before {
  content: none;
}
.lpPage > table {
  table-layout: fixed;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .lpPage p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .lpPage p:not([class]) + p:not([class]) {
    margin-top: 25px;
  }
  .lpPage h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .lpPage h1:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .lpPage h2:not([class]) {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .lpPage h3:not([class]) {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .lpPage h3:not([class])::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .lpPage h4:not([class]) {
    font-size: 1.8rem;
    line-height: 1.27272;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .lpPage h5:not([class]) {
    font-size: 1.7rem;
    line-height: 1.27272;
    margin-bottom: 20px;
  }
  .lpPage h6:not([class]) {
    font-size: 1.6rem;
    padding-left: 8px;
    border-left: 3px solid #36639A;
  }
  .lpPage blockquote {
    padding: 50px 3.8461538462% 20px;
    margin: 40px 0 30px;
    clear: both;
  }
  .lpPage blockquote::before {
    font-size: 5rem;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 3.8461538462%;
  }
  .lpPage img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .lpPage img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .lpPage img.aligncenter {
    margin: 15px auto 20px;
  }
  .lpPage ul:not([class]) {
    margin: 40px 0 20px;
  }
  .lpPage ul:not([class]) li {
    font-size: 1.4rem;
    padding-left: 35px;
  }
  .lpPage ul:not([class]) li::before {
    width: 25px;
    height: 25px;
    background-size: 7px auto;
    background-position: 10px 8px;
  }
  .lpPage ul:not([class]) li + li {
    margin-top: 20px;
  }
  .lpPage ol:not([class]) {
    margin: 40px 0 20px;
  }
  .lpPage ol:not([class]) li {
    font-size: 1.4rem;
    padding: 11px 10px 11px 57px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.03);
  }
  .lpPage ol:not([class]) li::before {
    font-size: 1.4rem;
    width: 45px;
  }
  .lpPage ol:not([class]) li::after {
    width: 45px;
  }
  .lpPage ol:not([class]) li + li {
    margin-top: 7px;
  }
}
.lpPage iframe {
  width: 100%;
}
.lpPage .articleMv {
  margin: 30px auto 40px;
  text-align: center;
}
.lpPage .tocBox {
  margin: 40px auto 60px;
}
.lpPage .tocBox__inner {
  background: #FBFBFB;
  padding: 2.196193265vw;
}
.lpPage .tocBox__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center;
}
.lpPage .tocBox__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  vertical-align: middle;
  margin: -2px 11px 0 0;
}
.lpPage .tocBox .tocList__item {
  position: relative;
}
.lpPage .tocBox .tocList__item::before {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.lpPage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 0 0 0 20px;
}
@media screen and (min-width: 767px) {
  .lpPage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #36639A;
  }
}
.lpPage .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.lpPage .tocBox .tocList__item .tocList {
  padding-left: 26px;
  margin-top: 10px;
}
.lpPage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.lpPage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #36639A;
  text-align: center;
  margin-top: 20px;
  padding: 8px 5px;
  cursor: pointer;
}
.lpPage .tocBox .tocMore::after {
  content: "";
  width: 9px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: translateY(2px) rotate(-90deg);
}
@media screen and (min-width: 767px) {
  .lpPage .tocBox .tocMore:hover {
    color: #36639A;
    background: #fff;
  }
  .lpPage .tocBox .tocMore:hover::after {
    width: 14px;
    height: 9px;
    background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
    background-size: contain;
    transform: rotate(-180deg);
  }
}
.lpPage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.lpPage .tocBox:not(.open) .tocMore::after {
  transform: translateY(2px) rotate(90deg);
}
@media screen and (min-width: 767px) {
  .lpPage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(0px);
  }
}
.lpPage .relrated {
  margin: 60px 0;
}
.lpPage .relrated__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-left: 17px;
  position: relative;
}
.lpPage .relrated__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.lpPage .relratedList__item {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
}
.lpPage .relratedList__item .itemWrap {
  padding: 30px;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lpPage .relratedList__item .itemWrap {
    padding: 2.196193265vw;
  }
}
.lpPage .relratedList__item .itemWrap .thumb {
  width: 33%;
  max-width: 260px;
  margin-right: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lpPage .relratedList__item .itemWrap .thumb {
    margin-right: 2.196193265vw;
  }
}
.lpPage .relratedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.lpPage .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - (33% + 30px));
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lpPage .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - (33% + 2.196193265vw));
  }
}
.lpPage .relratedList__item .itemWrap .textWrap {
  width: 100%;
}
.lpPage .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}
.lpPage .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.625;
}
@media screen and (min-width: 767px) {
  .lpPage .relratedList__item:hover {
    background: #ECF5FF;
  }
}
.lpPage .relratedList__item + .relratedList__item {
  margin-top: 10px;
}
.lpPage .checkPoint {
  border: 1px dashed #D2D2D2;
  background: #fff;
  padding: 40px 2.9282576867vw 30px;
  margin: 60px 0;
}
.lpPage .checkPoint ul, .lpPage .checkPoint ol {
  margin: 0;
}
.lpPage .checkPoint h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h1::before, .lpPage .checkPoint h1::after {
  content: none;
}
.lpPage .checkPoint h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h2::before, .lpPage .checkPoint h2::after {
  content: none;
}
.lpPage .checkPoint h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h3::before, .lpPage .checkPoint h3::after {
  content: none;
}
.lpPage .checkPoint h4 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h4::before, .lpPage .checkPoint h4::after {
  content: none;
}
.lpPage .checkPoint h5 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h5::before, .lpPage .checkPoint h5::after {
  content: none;
}
.lpPage .checkPoint h6 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #36639A;
  line-height: 1.44444;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: none;
}
.lpPage .checkPoint h6::before, .lpPage .checkPoint h6::after {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .lpPage {
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
    こちらも読まれています
    ---------------------*/
    /*---------------------
      チェックポイント
    ---------------------*/
  }
  .lpPage .articleMv {
    margin: 20px auto;
  }
  .lpPage .tocBox {
    margin: 40px auto;
  }
  .lpPage .tocBox__inner {
    padding: 30px 4.358974359%;
  }
  .lpPage .tocBox__ttl {
    font-size: 1.8rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .lpPage .tocBox__ttl::before {
    height: 22px;
  }
  .lpPage .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .lpPage .relrated {
    margin: 60px 0;
  }
  .lpPage .relrated__ttl {
    font-size: 2rem;
    line-height: 1.27272;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .lpPage .relrated__ttl::before {
    width: 4px;
    height: 30px;
    top: -2px;
  }
  .lpPage .relratedList__item {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  }
  .lpPage .relratedList__item .itemWrap {
    padding: 15px 3.5897435897%;
  }
  .lpPage .relratedList__item .itemWrap .thumb {
    width: 37%;
    max-width: 140px;
    margin-right: 5.1282051282%;
  }
  .lpPage .relratedList__item .itemWrap .thumb + .textWrap {
    width: 57.8717948718%;
  }
  .lpPage .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .lpPage .relratedList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
  }
  .lpPage .relratedList__item .itemWrap .textWrap__date {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  .lpPage .checkPoint {
    padding: 25px 3.5897435897% 30px;
    margin: 30px auto;
  }
}
.lpPage > h1:first-child {
  margin-top: 0 !important;
}
.lpPage > h2:first-child {
  margin-top: 0 !important;
}
.lpPage > h3:first-child {
  margin-top: 0 !important;
}
.lpPage > h4:first-child {
  margin-top: 0 !important;
}
.lpPage > h5:first-child {
  margin-top: 0 !important;
}
.lpPage > h6:first-child {
  margin-top: 0 !important;
}
.lpPage img:not([class]) {
  margin-top: 0;
  margin-bottom: 0;
}
.lpPage h2:not([class]) {
  width: 100%;
  margin-left: 0;
  background: #36639A;
  color: #fff;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .lpPage h2:not([class]) {
    width: 100%;
    margin-left: 0;
  }
}
.lpPage__in {
  background: #fff;
}
.lpPage .text--summary {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.725;
  margin: 0 0 60px;
}
.lpPage .lpBtn--blue {
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 26px 46px 24px 20px;
  border-radius: 5px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 5px 0 0px #26456B;
  display: block;
  text-align: center;
  width: 100%;
  max-width: 70%;
  margin: 20px auto 50px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.lpPage .lpBtn--blue::after {
  content: "";
  width: 12px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .lpPage .lpBtn--blue:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.lpPage .lpBtn--gold {
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 26px 46px 24px 20px;
  border-radius: 5px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 5px 0 0px #776D37;
  display: block;
  text-align: center;
  width: 100%;
  max-width: 70%;
  margin: 20px auto 50px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.lpPage .lpBtn--gold::after {
  content: "";
  width: 12px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .lpPage .lpBtn--gold:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.lpPage .lp--kv {
  background: url(../img/lp/lp_kv_bg.jpg) no-repeat right top;
  background-size: cover;
  padding: 100px 1.5%;
}
.lpPage .lp--kv__cont {
  width: 70%;
}
.lpPage .lp--kv__copy {
  font-size: 4rem;
  color: #fff;
  line-height: 1.8;
  letter-spacing: 0.08em;
  box-decoration-break: clone;
  display: inline;
  padding: 10px;
  background-color: rgba(54, 99, 154, 0.8);
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lpPage .lp--kv__copy {
    font-size: 2.9282576867vw;
    line-height: 6.4421669107vw;
  }
}
.lpPage .lp--kv__description {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.75;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8);
  margin: 40px 0 0;
}
.lpPage .lp--cardList {
  display: flex;
  margin: 50px 0 0;
  counter-reset: cardNum;
}
.lpPage .lp--cardList__item {
  width: calc((100% - 60px) / 3);
  padding: 20px 15px 30px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  position: relative;
}
.lpPage .lp--cardList__item::before {
  content: "";
  border-style: solid;
  border-width: 70px 70px 0 0;
  border-color: rgba(171, 155, 75, 0.8) transparent transparent transparent;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.lpPage .lp--cardList__item::after {
  counter-increment: cardNum;
  content: counter(cardNum);
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 3px;
  left: 15px;
}
.lpPage .lp--cardList__item:not(:nth-child(3n+1)) {
  margin-left: 30px;
}
.lpPage .lp--cardList__thumb {
  margin: 0 0 20px;
}
.lpPage .lp--cardList__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-bottom: 20px;
  padding: 0 0 0 20px;
  position: relative;
}
.lpPage .lp--cardList__ttl::before {
  content: "";
  width: 5px;
  height: 100%;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.lpPage .lp--cardList__text {
  font-size: 1.6rem;
  line-height: 1.724;
  margin: 20px 0 0;
}
.lpPage .lp--cardList__btn {
  text-align: center;
  margin: 30px 0 0;
}
.lpPage .lp--cardList__btn a {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  border-radius: 50px;
  padding: 10px 30px;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.lpPage .lp--cardList__btn a::after {
  content: "";
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  display: inline-block;
  margin: 0 0 0 10px;
}
@media screen and (min-width: 767px) {
  .lpPage .lp--cardList__btn a:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.lpPage .section01__wrap {
  display: flex;
  align-items: flex-start;
}
.lpPage .section01__img {
  width: 24%;
  border: 1px solid #ccc;
  border-top: none;
}
.lpPage .section01__cont {
  width: 73%;
  margin-left: 3%;
  counter-reset: section01Num;
}
.lpPage .section01__item + .section01__item {
  margin-top: 40px;
}
.lpPage .section01__ttl {
  font-size: 2.4rem;
  font-weight: 700;
  font-style: italic;
  line-height: 1;
  color: #36639A;
  display: inline-block;
  background: linear-gradient(transparent 60%, #ffff82 60%);
  padding: 10px 7px 10px 70px;
  margin: 0 0 20px;
  position: relative;
}
.lpPage .section01__ttl::before {
  counter-increment: section01Num;
  content: "0" counter(section01Num);
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  color: #36639A;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.lpPage .section01__text em, .lpPage .section01__text strong {
  font-size: 110%;
}
.lpPage .section02__cont {
  margin: 80px 0 0;
}
.lpPage .section02__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.lpPage .section02__ttl {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-bottom: 40px;
  padding: 0 0 0 20px;
  position: relative;
}
.lpPage .section02__ttl::before {
  content: "";
  width: 5px;
  height: 100%;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}
.lpPage .section02__img {
  width: 30%;
  margin: 0 0 0 20px;
}
.lpPage .section02__table .em {
  font-size: 2rem;
  font-weight: 700;
  color: #C62828;
}
.lpPage .section02__table .num {
  font-family: "Roboto", sans-serif;
  font-size: 200%;
  display: inline-block;
  vertical-align: baseline;
}
.lpPage .section03__table td {
  text-align: left;
}
.lpPage .section03__table .ttl {
  display: block;
  font-weight: 700;
  margin: 0 0 10px;
}
.lpPage .section03__table .ttl::before {
  content: "";
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #36639A transparent transparent transparent;
  display: inline-block;
  margin: 0 5px 0 0;
}
.lpPage .section03__table .em {
  font-size: 2rem;
  font-weight: 700;
  color: #C62828;
}
.lpPage .section03__table .num {
  font-family: "Roboto", sans-serif;
  font-size: 200%;
}
.lpPage .section04 .flowItem {
  padding: 40px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.lpPage .section04 .flowItem .step {
  min-width: 380px;
  margin-right: 40px;
  position: relative;
}
.lpPage .section04 .flowItem .step .label {
  font-family: "Roboto", sans-serif;
  font-size: 2.2rem;
  color: #fff;
  text-align: center;
  background: rgba(109, 194, 255, 0.8);
  background-size: contain;
  border-radius: 50%;
  display: inline-block;
  width: 100px;
  line-height: 100px;
  border: 10px solid rgba(202, 234, 255, 0.8);
  position: absolute;
  top: -30px;
  left: -30px;
  box-sizing: content-box;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lpPage .section04 .flowItem .step .label {
    font-size: 2.3426061493vw;
    width: 10.2489019034vw;
    line-height: 10.2489019034vw;
    border-width: 0.878477306vw;
  }
}
.lpPage .section04 .flowItem .flowCont {
  width: calc(100% - 420px);
  min-width: 430px;
}
.lpPage .section04 .flowItem .flowCont__ttl {
  font-size: 2.8rem;
  font-weight: 700;
  color: #36639A;
  margin: 15px 0;
}
.lpPage .section04 .flowItem .flowCont__text {
  font-size: 1.8rem;
  line-height: 1.8888;
}
.lpPage .section04 .flowItem .flowCont__text em {
  background: none;
}
.lpPage .section04 .flowItem:not(:last-child) .step .label::after {
  content: "";
  width: 39px;
  height: 28px;
  display: inline-block;
  background: url(../img/lp/lp_flow_step.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -46px;
  margin: 0 auto;
}
.lpPage .section04 .flowItem + .flowItem {
  margin-top: 20px;
}
.lpPage .section04 .flowItem .lpBtn--blue, .lpPage .section04 .flowItem .lpBtn--gold {
  width: 100%;
  max-width: 510px;
  margin: 20px 0 30px;
}

/* =================================
  lp--header
================================= */
.lp--header {
  width: 100%;
  padding: 30px 1%;
}
.lp--header__inner {
  max-width: 1286px;
  margin: 0 auto;
  position: relative;
}
.lp--header .ttl--site {
  display: block;
  display: flex;
  justify-content: space-between;
}
.lp--header .ttl--site .logo {
  display: inline-block;
  margin-right: 33px;
}
.lp--header .ttl--site .subText {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .lp--header .ttl--site:hover .logo a {
    opacity: 0.8;
  }
}
.lp--header .gNavBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.lp--header .gNavBtnWrap a {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  padding: 16px 20px 9px;
  border-radius: 4px;
}
.lp--header .gNavBtnWrap a + a {
  margin-left: 20px;
}
.lp--header .gNavBtnWrap .gNav__btn--search {
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.lp--header .gNavBtnWrap .gNav__btn--search::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_search.png) no-repeat center center;
  background-size: contain;
  margin: 0 6px -3px 0;
}
@media screen and (min-width: 767px) {
  .lp--header .gNavBtnWrap .gNav__btn--search:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.lp--header .gNavBtnWrap .gNav__btn--gold {
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 4px 0 0px #776D37;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.lp--header .gNavBtnWrap .gNav__btn--gold::before {
  content: "";
  width: 9px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
@media screen and (min-width: 767px) {
  .lp--header .gNavBtnWrap .gNav__btn--gold:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.lp--header .gNavList {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  padding: 0 8px;
  margin-top: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lp--header .gNavList {
    padding: 0 0.5856515373vw;
    margin-top: 2.196193265vw;
  }
}
.lp--header .gNavList__inner {
  max-width: 1286px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.lp--header .gNavList__item {
  display: inline-block;
  margin: 0 14px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lp--header .gNavList__item {
    margin: 0 1.0248901903vw;
  }
}
.lp--header .gNavList__item::after {
  content: "";
  width: 100%;
  height: 0;
  display: block;
  background: #36639A;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .lp--header .gNavList__item:hover::after {
    height: 5px;
  }
}
.lp--header .gNavList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  color: #36639A;
  text-decoration: none;
  display: block;
  padding: 28px 0;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .lp--header .gNavList__item a {
    padding: 2.0497803807vw 0;
  }
}
.lp--header .gNavList__item.current-menu-item a::after {
  height: 5px;
}

/*=================================
  lp--cvBner
=================================*/
.lp--cvBnrWrap {
  margin: 40px auto 34px;
  max-width: 1000px;
}
.lp--cvBnrWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-left: 18px;
  position: relative;
}
.lp--cvBnrWrap__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}

.lp--cvBnr {
  background: #FBFBFB;
  border: 1px solid #CCCCCC;
  padding: 40px 30px;
}
.lp--cvBnr .contact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp--cvBnr .contact__wrap {
  margin-left: auto;
  margin-right: auto;
}
.lp--cvBnr .contact__wrap.tel {
  text-align: center;
}
.lp--cvBnr .contact__wrap.tel .num {
  font-size: 4.2rem;
  font-weight: bold;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .lp--cvBnr .contact__wrap.tel .num {
    pointer-events: none;
  }
}
.lp--cvBnr .contact__wrap.tel .num::before {
  content: "";
  width: 29px;
  height: 29px;
  display: inline-block;
  background: url(../img/icon/icon_tel.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px 0 0;
}
.lp--cvBnr .contact__wrap.tel .receptionTime {
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
}
.lp--cvBnr .contact__wrap.tel .receptionTime dt {
  font-size: 1.6rem;
  font-weight: bold;
}
.lp--cvBnr .contact__wrap.tel .receptionTime dd {
  font-size: 1.6rem;
}
.lp--cvBnr .contact__wrap.mail {
  text-align: center;
}
.lp--cvBnr .contact__wrap.mail .btn--mail {
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 32px 14px 27px;
  border-radius: 5px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 5px 0 0px #776D37;
  text-align: center;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .lp--cvBnr .contact__wrap.mail .btn--mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.lp--cvBnr .contact__wrap.mail .btn--mail + .btn--reserve {
  margin-top: 15px;
}
.lp--cvBnr .contact__wrap.mail .btn--reserve {
  font-size: 2.1rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 22px 14px 17px;
  border-radius: 5px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 5px 0 0px #26456B;
  text-align: center;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .lp--cvBnr .contact__wrap.mail .btn--reserve:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.lpBtn {
  display: none !important;
}
.lpBtn__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  writing-mode: vertical-rl;
  text-decoration: none;
  background: url(../img/lp/lp_site_icon.jpg) no-repeat center 10px #36639A;
  padding: 50px 15px 15px;
  box-shadow: 0 0 10px rgba(176, 176, 176, 0.8);
  border-radius: 5px 0 0 5px;
}
@media screen and (min-width: 767px) {
  .lpBtn__ttl:hover {
    opacity: 0.8;
  }
}
.lpBtn.float {
  position: fixed;
  bottom: 255px;
  right: 3%;
  display: block !important;
  z-index: 9999;
}

@media screen and (max-width: 767px) {
  /*=================================
    LP
  =================================*/
  .lpPage > h2:first-child {
    width: 100%;
    margin-left: 0;
  }
  .lpPage__in {
    padding: 30px 3.8461538462% 40px;
  }
  .lpPage table {
    display: table;
    white-space: normal;
  }
  .lpPage .text--summary {
    font-size: 1.5rem;
    margin: 0 0 50px;
  }
  .lpPage .lpBtn--blue {
    font-size: 2rem;
    padding: 16px 26px 14px 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #26456B;
    max-width: 100%;
    margin: 20px auto 40px;
  }
  .lpPage .lpBtn--blue::after {
    width: 10px;
    height: 14px;
  }
  .lpPage .lpBtn--gold {
    font-size: 2rem;
    padding: 16px 26px 14px 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #776D37;
    max-width: 100%;
    margin: 20px auto 40px;
  }
  .lpPage .lpBtn--gold::after {
    width: 10px;
    height: 14px;
  }
  .lpPage .lp--kv {
    background: url(../img/lp/lp_kv_bg_sp.jpg) no-repeat center top;
    background-size: cover;
    padding: 80px 2% 0;
  }
  .lpPage .lp--kv__cont {
    width: 90%;
  }
  .lpPage .lp--kv__copy {
    font-size: 2rem;
    line-height: 2.2;
    padding: 8px;
  }
  .lpPage .lp--kv__description {
    font-size: 1.6rem;
    margin: 20px 0 0;
  }
  .lpPage .lp--cardList {
    display: block;
    margin: 0;
  }
  .lpPage .lp--cardList__item {
    width: 100%;
    padding: 20px 3.5% 30px;
    margin: 20px 0 0;
  }
  .lpPage .lp--cardList__item::after {
    font-size: 2.6rem;
  }
  .lpPage .lp--cardList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .lpPage .lp--cardList__text {
    font-size: 1.5rem;
  }
  .lpPage .section01__wrap {
    display: block;
  }
  .lpPage .section01__img {
    width: 80%;
    margin: 0 auto 40px;
  }
  .lpPage .section01__cont {
    width: 100%;
    margin-left: 0;
  }
  .lpPage .section01__item + .section01__item {
    margin-top: 30px;
  }
  .lpPage .section01__ttl {
    font-size: 2.2rem;
    padding: 10px 7px 10px 60px;
    margin: 0 0 15px;
  }
  .lpPage .section01__ttl::before {
    font-size: 3.6rem;
  }
  .lpPage .section02__cont {
    margin: 30px 0 0;
  }
  .lpPage .section02__wrap {
    display: block;
  }
  .lpPage .section02__ttl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .lpPage .section02__img {
    width: 80%;
    margin: 0 auto;
  }
  .lpPage .section02__table .em {
    font-size: 1.8rem;
  }
  .lpPage .section03__table .em {
    font-size: 1.8rem;
  }
  .lpPage .section04 .flowItem {
    padding: 20px;
    display: block;
  }
  .lpPage .section04 .flowItem .step {
    min-width: inherit;
    margin: 0 0 13px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .lpPage .section04 .flowItem .step .label {
    font-size: 1.4rem;
    width: 80px;
    line-height: 80px;
    display: inline-block;
    border-width: 8px;
    position: relative;
    top: 0;
    left: 0;
  }
  .lpPage .section04 .flowItem .step .img {
    width: calc(100% - 105px);
  }
  .lpPage .section04 .flowItem .flowCont {
    width: 100%;
    min-width: inherit;
  }
  .lpPage .section04 .flowItem .flowCont__ttl {
    font-size: 1.6rem;
    margin: 0 0 12px;
  }
  .lpPage .section04 .flowItem .flowCont__text {
    font-size: 1.4rem;
  }
  .lpPage .section04 .flowItem:not(:last-child) .step .label::after {
    width: 25px;
    height: 18px;
    bottom: -33px;
  }
  .lpPage .section04 .flowItem + .flowItem {
    margin-top: 15px;
  }
  /* =================================
    lp--header
  ================================= */
  .lp--header {
    padding: 0;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    z-index: 9999;
    /*バーガーボタン設定*/
  }
  .lp--header .ttl--site {
    width: calc(100% - 70px);
    max-width: initial;
    height: 60px;
    padding: 15px 0 15px 5.1282051282%;
    position: relative;
    z-index: 9999;
  }
  .lp--header .ttl--site .logo {
    width: 38.4615384615vw;
    margin: 0;
    line-height: 1;
  }
  .lp--header .ttl--site .subText {
    display: none;
  }
  .lp--header .gNavBtnWrap a {
    font-size: clamp(14px, 3.8461538462vw, 16px);
    padding: clamp(10px, 2.5641025641vw, 14px);
    width: 100%;
    text-align: center;
  }
  .lp--header .gNavBtnWrap a + a {
    margin: 20px 0 0;
  }
  .lp--header .gNavBtnWrap .gNav__btn--search::before {
    width: 15px;
    height: 15px;
  }
  .lp--header .gNavBtnWrap .gNav__btn--gold::before {
    width: 8px;
    height: 14px;
    margin: 0 8px -2px 0;
  }
  .lp--header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
    margin-top: 0;
  }
  .lp--header .gNavList {
    background: #fff;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
    border-bottom: 2px dotted #CCCCCC;
  }
  .lp--header .gNavList__inner {
    justify-content: flex-start;
  }
  .lp--header .gNavList__item {
    display: block;
    margin: 0;
    width: 50%;
    border-top: 2px dotted #CCCCCC;
  }
  .lp--header .gNavList__item:nth-child(2n-1) {
    border-right: 2px dotted #CCCCCC;
    position: relative;
  }
  .lp--header .gNavList__item:nth-child(2n-1):last-child::before {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: block;
    position: absolute;
    top: -1%;
    right: -101%;
    border-top: 2px dotted #CCCCCC;
  }
  .lp--header .gNavList__item::after {
    content: none;
  }
  .lp--header .gNavList__item a {
    font-size: 1.7rem;
    padding: 27px 10px;
  }
  .lp--header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .lp--header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .lp--header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: #fff;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .lp--header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .lp--header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .lp--header .burger {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background: transparent;
    margin: auto;
    position: absolute;
    right: 5.1282051282%;
    top: 15px;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .lp--header .burger span {
    width: 27px;
    height: 3px;
    display: block;
    background: #333333;
    position: absolute;
    top: 9px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .lp--header .burger span.burger--top {
    transform: translateY(0);
  }
  .lp--header .burger span.burger--middle {
    transform: translateY(7px);
    position: relative;
    background: none;
  }
  .lp--header .burger span.burger--middle::before {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #333333;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .lp--header .burger span.burger--middle::after {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #333333;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .lp--header .burger span.burger--bottom {
    transform: translateY(14px);
    width: 17px;
    left: 4px;
    right: initial;
  }
  .lp--header .burger.is-open {
    background: #333;
  }
  .lp--header .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .lp--header .burger.is-open .burger--middle::before {
    width: 18px;
    height: 2px;
    transform: rotate(135deg);
    background: #fff;
    left: 5px;
  }
  .lp--header .burger.is-open .burger--middle::after {
    width: 18px;
    height: 2px;
    transform: rotate(45deg);
    background: #fff;
    left: 5px;
  }
  .lp--header .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
  .lp--header.show {
    position: fixed;
    animation: burger-show 1s ease-in;
  }
  /*=================================
    lp--cvBner
  =================================*/
  .lp--cvBnrWrap__ttl {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
  .lp--cvBnrWrap__ttl::before {
    height: 100%;
  }
  .lp--cvBnr {
    padding: 20px 3%;
  }
  .lp--cvBnr .contact {
    display: block;
  }
  .lp--cvBnr .contact__wrap.tel .num {
    font-size: 3rem;
  }
  .lp--cvBnr .contact__wrap.tel .num::before {
    width: 26px;
    height: 26px;
    margin: 0 12px 0 0;
  }
  .lp--cvBnr .contact__wrap.tel .receptionTime {
    margin: 8px 0 0;
  }
  .lp--cvBnr .contact__wrap.tel .receptionTime dt {
    font-size: 1.4rem;
  }
  .lp--cvBnr .contact__wrap.tel .receptionTime dd {
    font-size: 1.4rem;
  }
  .lp--cvBnr .contact__wrap.mail {
    margin: 20px 0 0;
  }
  .lp--cvBnr .contact__wrap.mail .btn--mail {
    font-size: 2rem;
    padding: 22px 14px 17px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #776D37;
  }
  .lp--cvBnr .contact__wrap.mail .btn--mail + .btn--reserve {
    margin-top: 10px;
  }
  .lp--cvBnr .contact__wrap.mail .btn--reserve {
    font-size: 2rem;
    padding: 22px 14px 17px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #26456B;
  }
  .lpBtn__ttl {
    font-size: 1.4rem;
    padding: 50px 18px 15px;
    box-shadow: 0 0 10px rgba(176, 176, 176, 0.8);
    border-radius: 5px 0 0 5px;
  }
  .lpBtn.float {
    bottom: 100px;
  }
}
/* =================================
  header
================================= */
.header {
  width: 100%;
  padding: 30px 1%;
}
.header__inner {
  max-width: 1286px;
  margin: 0 auto;
  position: relative;
}
.header .ttl--site {
  display: block;
  max-width: calc(100% - 535px);
}
.header .ttl--site .logo {
  display: inline-block;
  margin-right: 33px;
}
.header .ttl--site .subText {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .header .ttl--site:hover .logo a {
    opacity: 0.8;
  }
}
.header .gNavBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header .gNavBtnWrap a {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  padding: 16px 20px 9px;
  border-radius: 4px;
}
.header .gNavBtnWrap a + a {
  margin-left: 20px;
}
.header .gNavBtnWrap .gNav__btn--search {
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.header .gNavBtnWrap .gNav__btn--search::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_search.png) no-repeat center center;
  background-size: contain;
  margin: 0 6px -3px 0;
}
@media screen and (min-width: 767px) {
  .header .gNavBtnWrap .gNav__btn--search:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.header .gNavBtnWrap .gNav__btn--gold {
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 4px 0 0px #776D37;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.header .gNavBtnWrap .gNav__btn--gold::before {
  content: "";
  width: 9px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
@media screen and (min-width: 767px) {
  .header .gNavBtnWrap .gNav__btn--gold:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.header .gNav {
  margin-top: -40px;
}
.header .gNavList {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  padding: 0 8px;
  margin-top: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList {
    padding: 0 0.5856515373vw;
    margin-top: 2.196193265vw;
  }
}
.header .gNavList__inner {
  max-width: 1286px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.header .gNavList__item {
  display: inline-block;
  margin: 0 14px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item {
    margin: 0 1.0248901903vw;
  }
}
.header .gNavList__item::after {
  content: "";
  width: 100%;
  height: 0;
  display: block;
  background: #36639A;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover::after {
    height: 5px;
  }
}
.header .gNavList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  color: #36639A;
  text-decoration: none;
  display: block;
  padding: 28px 0;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item a {
    padding: 2.0497803807vw 0;
  }
}
.header .gNavList__item.current-menu-item a::after {
  height: 5px;
}
.header .gNavList__item.implant::after {
  background: #FBFBFB;
}
.header .gNavList__item.implant a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.implant a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.implant a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_implant.png) no-repeat center center #FBFBFB;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.implant a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.ceramic::after {
  background: #EFCF5C;
}
.header .gNavList__item.ceramic a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.ceramic a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.ceramic a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_ceramic.png) no-repeat center center #EFCF5C;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.ceramic a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.whitening::after {
  background: #7DCAD4;
}
.header .gNavList__item.whitening a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.whitening a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.whitening a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_whitening.png) no-repeat center center #7DCAD4;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.whitening a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.correction::after {
  background: #85C485;
}
.header .gNavList__item.correction a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.correction a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.correction a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_correction.png) no-repeat center center #85C485;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.correction a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.dentition::after {
  background: #BD9BD2;
}
.header .gNavList__item.dentition a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.dentition a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.dentition a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_dentition.png) no-repeat center center #BD9BD2;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.dentition a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.denture::after {
  background: #9CB6D1;
}
.header .gNavList__item.denture a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.denture a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.denture a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_denture.png) no-repeat center center #9CB6D1;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.denture a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.tooth-decay::after {
  background: #D1AA9A;
}
.header .gNavList__item.tooth-decay a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.tooth-decay a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.tooth-decay a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_tooth-decay.png) no-repeat center center #D1AA9A;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.tooth-decay a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}
.header .gNavList__item.dental-treatment::after {
  background: #FCA477;
}
.header .gNavList__item.dental-treatment a {
  padding-left: 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.dental-treatment a {
    padding-left: 4.39238653vw;
  }
}
.header .gNavList__item.dental-treatment a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url(../img/cat_dental-treatment.png) no-repeat center center #FCA477;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .gNavList__item.dental-treatment a::before {
    width: 2.9282576867vw;
    height: 2.9282576867vw;
  }
}

@media screen and (min-width: 921px) and (max-width: 1023px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 2.196193265vw 1%;
  }
  .header .ttl--site {
    max-width: calc(100% - 350px);
  }
  .header .ttl--site .logo {
    margin-right: 10px;
  }
  .header .ttl--site .subText {
    font-size: 1.3rem;
    margin-top: 8px;
  }
  .header .gNavBtnWrap {
    flex-wrap: wrap;
    max-width: 54.9048316252vw;
    margin-left: auto;
  }
  .header .gNavBtnWrap a {
    font-size: 1.4rem;
    padding: 16px 0.7320644217vw 9px;
    text-align: center;
    width: 100%;
    max-width: 240px;
    margin: 0.7320644217vw;
  }
  .header .gNavBtnWrap a + a {
    margin: 0.7320644217vw;
  }
  .header .gNav {
    margin-top: -5.8565153734vw;
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    z-index: 9999;
    /*バーガーボタン設定*/
  }
  .header__inner {
    max-width: 1286px;
    margin: 0 auto;
    position: relative;
  }
  .header .ttl--site {
    width: calc(100% - 60px);
    max-width: initial;
    height: 60px;
    padding: 15px 0 15px 5.1282051282%;
    position: relative;
    z-index: 9999;
  }
  .header .ttl--site .logo {
    width: 45.3846153846vw;
    margin: 0;
    line-height: 1;
  }
  .header .ttl--site .subText {
    display: none;
  }
  .header .gNavBtnWrap {
    display: block;
    background: #FBFBFB;
    padding: 7.6923076923% 3.8461538462%;
  }
  .header .gNavBtnWrap a {
    font-size: 1.9rem;
    padding: 25px 20px;
    width: 100%;
    text-align: center;
  }
  .header .gNavBtnWrap a + a {
    margin: 20px 0 0;
  }
  .header .gNavBtnWrap .gNav__btn--search {
    box-shadow: 0px 5px 0 0px #26456B;
  }
  .header .gNavBtnWrap .gNav__btn--search::before {
    width: 18px;
    height: 18px;
    margin: 0 7px -3px 0;
  }
  .header .gNavBtnWrap .gNav__btn--gold {
    box-shadow: 0px 5px 0 0px #776D37;
  }
  .header .gNavBtnWrap .gNav__btn--gold::before {
    width: 11px;
    height: 17px;
    margin: 0 12px -2px 0;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
    margin-top: 0;
  }
  .header .gNavList {
    background: #fff;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
    border-bottom: 2px dotted #CCCCCC;
  }
  .header .gNavList__inner {
    justify-content: flex-start;
  }
  .header .gNavList__item {
    display: block;
    margin: 0;
    width: 50%;
    border-top: 2px dotted #CCCCCC;
  }
  .header .gNavList__item:nth-child(2n-1) {
    border-right: 2px dotted #CCCCCC;
    position: relative;
  }
  .header .gNavList__item:nth-child(2n-1):last-child::before {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: block;
    position: absolute;
    top: -1%;
    right: -101%;
    border-top: 2px dotted #CCCCCC;
  }
  .header .gNavList__item::after {
    content: none;
  }
  .header .gNavList__item a {
    font-size: 1.7rem;
    padding: 27px 3px;
  }
  .header .gNavList__item.implant a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.implant a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.ceramic a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.ceramic a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.whitening a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.whitening a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.correction a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.correction a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.dentition a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.dentition a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.denture a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.denture a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.tooth-decay a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.tooth-decay a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNavList__item.dental-treatment a {
    padding-left: 17.9487179487vw;
  }
  .header .gNavList__item.dental-treatment a::before {
    width: 12.8205128205vw;
    height: 12.8205128205vw;
    left: 3.8461538462%;
    max-width: 50px;
    max-height: 50px;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: #fff;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .header .burger {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background: transparent;
    margin: auto;
    position: absolute;
    right: 5.1282051282%;
    top: 15px;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger span {
    width: 27px;
    height: 3px;
    display: block;
    background: #333333;
    position: absolute;
    top: 9px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .header .burger span.burger--top {
    transform: translateY(0);
  }
  .header .burger span.burger--middle {
    transform: translateY(7px);
    position: relative;
    background: none;
  }
  .header .burger span.burger--middle::before {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #333333;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--middle::after {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #333333;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--bottom {
    transform: translateY(14px);
    width: 17px;
    left: 4px;
    right: initial;
  }
  .header .burger.is-open {
    background: #333;
  }
  .header .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .header .burger.is-open .burger--middle::before {
    width: 18px;
    height: 2px;
    transform: rotate(135deg);
    background: #fff;
    left: 5px;
  }
  .header .burger.is-open .burger--middle::after {
    width: 18px;
    height: 2px;
    transform: rotate(45deg);
    background: #fff;
    left: 5px;
  }
  .header .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
  .header.show {
    position: fixed;
    animation: burger-show 1s ease-in;
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #36639A;
  padding: 57px 1% 34px;
}
.footer__inner {
  max-width: 1286px;
  margin: 0 auto;
  background: url(../img/bg_footer.png) no-repeat right center;
  background-size: contain;
  padding-top: 20%;
  display: flex;
}
.footer__add {
  width: 400px;
}
.footer__add .logo {
  max-width: 290px;
  margin-bottom: 34px;
  display: block;
}
.footer__add .add {
  font-size: 1.4rem;
  line-height: 2;
  color: #fff;
}
.footer__cont {
  width: calc(100% - 400px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__btn {
  margin-bottom: 10px;
}
.footer__btn a {
  font-size: 1.7rem;
  font-weight: bold;
  color: #36639A;
  text-decoration: none;
  background: #fff;
  box-shadow: 0 4px 0 #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 25px 68px 21px;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.footer__btn a::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
  background: url(../img/icon/icon_search_n.png) no-repeat center center;
  background-size: contain;
}
@media screen and (min-width: 767px) {
  .footer__btn a:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.footer .fNavList {
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.footer .fNavList__item {
  width: 50%;
}
.footer .fNavList__item a {
  font-size: 1.4rem;
  line-height: 2.857;
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
  }
}
.footer .copy {
  font-size: 1.4rem;
  color: #fff;
  width: 100%;
  text-align: right;
}

.pageTop {
  width: 59px;
  cursor: pointer;
  position: absolute;
  bottom: 90px;
  right: 3%;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .pageTop:hover {
    transform: translateY(-10%);
  }
}
.pageTop.float {
  position: fixed;
  bottom: 40px;
  right: 3%;
  z-index: 5000;
}

.fixBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  padding: 20px;
  max-width: 500px;
  position: fixed;
  right: -100%;
  bottom: 120px;
  z-index: 9999;
  transition: right 1.2s ease-out;
}
.fixBnr__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}
.fixBnr__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  margin: 0 10px -5px 0;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
}
.fixBnr .contact {
  display: flex;
  justify-content: space-between;
}
.fixBnr .contact__wrap {
  width: 48%;
}
.fixBnr .contact__wrap .contact__btn {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 12px 10px;
  border-radius: 4px;
  text-align: center;
  display: block;
}
.fixBnr .contact__wrap .contact__text {
  font-size: 1.2rem;
  line-height: 1.454545;
  margin-top: 10px;
}
.fixBnr .contact__wrap.tel .contact__btn {
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.fixBnr .contact__wrap.tel .contact__btn::before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_tel_w.png) no-repeat center center;
  background-size: contain;
  margin: 0 6px -3px 0;
}
@media screen and (min-width: 767px) {
  .fixBnr .contact__wrap.tel .contact__btn {
    pointer-events: none;
  }
  .fixBnr .contact__wrap.tel .contact__btn:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.fixBnr .contact__wrap.mail .contact__btn {
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 4px 0 0px #776D37;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.fixBnr .contact__wrap.mail .contact__btn::before {
  content: "";
  width: 20px;
  height: 15px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
@media screen and (min-width: 767px) {
  .fixBnr .contact__wrap.mail .contact__btn:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.fixBnr.show {
  right: 0;
}

@media screen and (min-width: 767px) and (max-width: 1230px) {
  /*=================================
  footer
  =================================*/
  .footer__btn {
    margin-bottom: 20px;
  }
  .footer__btn a {
    padding: 25px 20px 21px;
  }
  .footer .fNavList {
    max-width: 100%;
  }
  .footer .fNavList__item {
    width: auto;
    margin-right: 15px;
  }
  .footer .fNavList__item a {
    font-size: 1.4rem;
    line-height: 2.857;
    color: #fff;
    text-decoration: none;
  }
}
@media screen and (max-width: 767px) {
  /*=================================
  footer
  =================================*/
  .footer {
    padding: 60px 0 43px;
  }
  .footer__inner {
    background: none;
    padding: 0;
    display: block;
  }
  .footer__add {
    width: 100%;
    padding: 0 3.8461538462%;
  }
  .footer__add .logo {
    max-width: 230px;
    margin-bottom: 20px;
  }
  .footer__cont {
    width: 100%;
    display: block;
    background: url(../img/bg_footer.png) no-repeat right -120px top;
    background-size: contain;
    padding: 0 3.8461538462%;
  }
  .footer__btn {
    margin: 40px 0 0;
  }
  .footer__btn a {
    font-size: 1.8rem;
    padding: 25px 10px 21px;
    display: block;
    text-align: center;
  }
  .footer .fNavList {
    max-width: 100%;
    justify-content: space-between;
    margin: 40px 0 0;
  }
  .footer .fNavList__item a {
    font-size: 1.3rem;
  }
  .footer .copy {
    font-size: 1.3rem;
    text-align: center;
    display: block;
    margin-top: 43px;
  }
  .pageTop.float {
    bottom: 3%;
    right: 3%;
  }
  .fixBnr {
    box-shadow: none;
    max-width: initial;
    padding: 9px 2.8205128205vw 5px;
    width: 100%;
    right: 0;
    left: 0;
    bottom: -100%;
    transition: bottom 1s ease-out;
    z-index: 9000;
  }
  .fixBnr__ttl {
    font-size: 1.6rem;
    margin-bottom: 9px;
  }
  .fixBnr__ttl::before {
    width: 5px;
    height: 22px;
    margin: 0 10px -5px 0;
  }
  .fixBnr .contact__wrap .contact__btn {
    font-size: 4.1025641026vw;
    padding: 12px 3px;
    border-radius: 3px;
  }
  .fixBnr .contact__wrap .contact__text {
    font-size: 1.1rem;
    margin-top: 9px;
  }
  .fixBnr .contact__wrap.tel {
    width: 52%;
  }
  .fixBnr .contact__wrap.tel .contact__btn {
    font-size: 4.6153846154vw;
    padding: 12px 3px 7px;
  }
  .fixBnr .contact__wrap.tel .contact__btn::before {
    width: 4.358974359vw;
    height: 4.358974359vw;
    margin: 0 3px -2px 0;
  }
  .fixBnr .contact__wrap.mail {
    width: 46%;
  }
  .fixBnr .contact__wrap.mail .contact__btn {
    font-size: 4.1025641026vw;
    padding: 13px 3px 9px;
  }
  .fixBnr .contact__wrap.mail .contact__btn::before {
    width: 3.8461538462vw;
    height: 2.8205128205vw;
    margin: 0 4px 0 0;
  }
  .fixBnr.show {
    bottom: 0;
    right: inherit;
  }
}
/* =================================
  side nav
================================= */
.sideNav {
  width: 306px;
}
.sideNav__inner {
  width: 306px;
}
.sideNav .sideSec__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
.sideNav .sideSec__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 2px;
  left: 0;
}
.sideNav .sideSec__cont .newClinicList__item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px dashed #D2D2D2;
}
.sideNav .sideSec__cont .newClinicList__thumb {
  width: 156px;
  margin-right: 15px;
  position: relative;
}
.sideNav .sideSec__cont .newClinicList__thumb + .newClinicList__text {
  width: calc(100% - 156px - 15px);
}
.sideNav .sideSec__cont .newClinicList__label {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.83333;
  color: #fff;
  background: rgba(54, 99, 154, 0.8);
  padding: 6px 12px;
  position: absolute;
  top: 0;
  left: 0;
}
.sideNav .sideSec__cont .newClinicList__text {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.375;
  text-decoration: none;
}
.sideNav .sideSec__cont .categoryList__item a {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  background: transparent;
  padding: 22px 10px 21px;
  border-bottom: 1px dashed #D2D2D2;
  position: relative;
}
.sideNav .sideSec__cont .categoryList__item.implant a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.implant a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_implant.png) no-repeat center center #FBFBFB;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.implant a:hover {
    background: #FBFBFB;
  }
}
.sideNav .sideSec__cont .categoryList__item.ceramic a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.ceramic a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_ceramic.png) no-repeat center center #EFCF5C;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.ceramic a:hover {
    background: #EFCF5C;
  }
}
.sideNav .sideSec__cont .categoryList__item.whitening a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.whitening a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_whitening.png) no-repeat center center #7DCAD4;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.whitening a:hover {
    background: #7DCAD4;
  }
}
.sideNav .sideSec__cont .categoryList__item.correction a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.correction a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_correction.png) no-repeat center center #85C485;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.correction a:hover {
    background: #85C485;
  }
}
.sideNav .sideSec__cont .categoryList__item.dentition a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.dentition a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_dentition.png) no-repeat center center #BD9BD2;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.dentition a:hover {
    background: #BD9BD2;
  }
}
.sideNav .sideSec__cont .categoryList__item.denture a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.denture a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_denture.png) no-repeat center center #9CB6D1;
  background-size: 75% auto;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.denture a:hover {
    background: #9CB6D1;
  }
}
.sideNav .sideSec__cont .categoryList__item.tooth-decay a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.tooth-decay a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_tooth-decay.png) no-repeat center center #D1AA9A;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.tooth-decay a:hover {
    background: #D1AA9A;
  }
}
.sideNav .sideSec__cont .categoryList__item.dental-treatment a {
  padding-left: 60px;
}
.sideNav .sideSec__cont .categoryList__item.dental-treatment a::before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url(../img/cat_dental-treatment.png) no-repeat center center #FCA477;
  background-size: auto 75%;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .categoryList__item.dental-treatment a:hover {
    background: #FCA477;
  }
}
.sideNav .sideSec__cont .relratedList {
  counter-reset: relratedNum;
}
.sideNav .sideSec__cont .relratedList__item {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.06);
  position: relative;
}
.sideNav .sideSec__cont .relratedList__item:nth-child(-n+3)::before {
  content: counter(relratedNum) "位";
  counter-increment: relratedNum;
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  padding: 7px 14px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.sideNav .sideSec__cont .relratedList__item:nth-child(1)::before {
  background: rgba(171, 156, 79, 0.8);
}
.sideNav .sideSec__cont .relratedList__item:nth-child(2)::before {
  background: rgba(54, 99, 154, 0.8);
}
.sideNav .sideSec__cont .relratedList__item:nth-child(3)::before {
  background: rgba(51, 51, 51, 0.8);
}
.sideNav .sideSec__cont .relratedList__item + .relratedList__item {
  margin-top: 20px;
}
.sideNav .sideSec__cont .relratedList__thumb {
  position: relative;
}
.sideNav .sideSec__cont .relratedList__thumb img {
  width: 100%;
}
.sideNav .sideSec__cont .relratedList__text {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.375;
  text-decoration: none;
  display: block;
  padding: 20px 20px 17px;
}
.sideNav .sideSec + .sideSec {
  margin-top: 45px;
}

@media screen and (max-width: 920px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    width: 100%;
    margin-top: 37px;
    padding: 0 3.8461538462% 40px;
  }
  .sideNav__inner {
    width: 100%;
  }
  .sideNav .sideSec.clinic, .sideNav .sideSec.category {
    display: none;
  }
  .sideNav .sideSec__cont .relratedList {
    display: flex;
    flex-wrap: wrap;
  }
  .sideNav .sideSec__cont .relratedList__item {
    width: calc((100% - 20px) / 2);
    margin-bottom: 20px;
  }
  .sideNav .sideSec__cont .relratedList__item:nth-child(2n) {
    margin-left: 20px;
  }
  .sideNav .sideSec__cont .relratedList__item:nth-child(-n+3)::before {
    padding: 5px 11px;
  }
  .sideNav .sideSec__cont .relratedList__item + .relratedList__item {
    margin-top: 0;
  }
  .sideNav .sideSec__cont .relratedList__text {
    font-size: 1.4rem;
    padding: 16px 16px 24px;
  }
  .sideNav .sideSec + .sideSec {
    margin-top: 35px;
  }
}
/*=================================
  visual
=================================*/
.mv {
  background: url(../img/top_mv_pc.jpg) no-repeat left top;
  background-size: cover;
  padding: 36px 1% 82px;
}
.mv__inner {
  max-width: 1286px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.mv__catch {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.5;
  padding-left: 40px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv__catch {
    font-size: 3.2210834553vw;
    padding-left: 0;
  }
}
.mv__catch .marker {
  padding: 4px 10px;
  background: rgba(255, 255, 255, 0.8);
}
.mv .areaBox {
  width: calc(64% - 37px);
  margin-left: 37px;
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
  padding: 37px 16px 40px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv .areaBox {
    width: calc(64% - 2.7086383602vw);
    margin-left: 2.7086383602vw;
    padding: 37px 1.1713030747vw 40px;
  }
}
.mv .areaBox__ttl {
  font-size: 2.4rem;
  color: #36639A;
  margin-bottom: 33px;
}
.mv .areaBox__ttl::before {
  width: 20px;
  height: 20px;
  background: url(../img/icon/icon_search_n.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
.mv .areaBox__in {
  box-shadow: none;
  padding: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv .areaBox__in {
    padding: 0;
  }
}
.mv .areaBox .btnWrap {
  margin: 20px 0 0;
}
.mv .areaList__cont .prefecture {
  margin-left: 1px;
}
.mv .areaList__cont .prefecture__item {
  margin: 0 1px 6px 0;
}
@media screen and (min-width: 767px) {
  .mv .areaList__cont .prefecture__item:hover {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 921px) {
  .mvWrap {
    position: relative;
  }
  .mvWrap .header__inner {
    position: static;
  }
  .mvWrap .gNavList {
    max-width: 1286px;
    width: 98%;
    margin-top: 0;
    position: absolute;
    top: calc(100% - 47px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
  }
}
@media screen and (max-width: 920px) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: none;
    padding: 0;
  }
  .mv__inner {
    display: block;
  }
  .mv__catch {
    font-size: 7.1794871795vw;
    line-height: 1.53571;
    padding: 14.1025641026vw 4.358974359% 3.3333333333vw;
    background: url(../img/top_mv_sp.jpg) no-repeat center top;
    background-size: cover;
  }
  .mv__catch .marker {
    padding: 4px 6px;
  }
  .mv .areaBox {
    width: 89.7435897436%;
    margin: 40px auto;
    box-shadow: none;
    padding: 0;
  }
  .mv .areaBox__ttl {
    padding: 20px 5px 0;
  }
  .mv .areaBox__in {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
  }
  .mv .areaList__cont .prefecture {
    margin-left: 6px;
  }
  .mv .areaList__cont .prefecture__item {
    margin: 0 6px 10px 0;
  }
}
@media screen and (max-width: 767px) {
  /*=================================
    visual
  =================================*/
  .mv .areaBox__ttl {
    font-size: 4.358974359vw;
    color: #fff;
    background: #36639A;
    margin-bottom: 0;
    padding: 11px 5px;
  }
  .mv .areaBox__ttl::before {
    width: 4.1025641026vw;
    height: 4.1025641026vw;
    background: url(../img/icon/icon_search.png) no-repeat center center;
    background-size: contain;
  }
}
/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  margin: 40px auto 34px;
}
.cvBnrWrap__ttl {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-left: 18px;
  position: relative;
}
.cvBnrWrap__ttl::before {
  content: "";
  width: 5px;
  height: 24px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
}

.cvBnr {
  background: #FBFBFB;
  border: 1px solid #CCCCCC;
  padding: 40px 30px;
}
.cvBnr .contact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cvBnr .contact__wrap {
  margin-left: auto;
  margin-right: auto;
}
.cvBnr .contact__wrap.tel {
  text-align: center;
}
.cvBnr .contact__wrap.tel .num {
  font-size: 4.2rem;
  font-weight: bold;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .cvBnr .contact__wrap.tel .num {
    pointer-events: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.tel .num {
    font-size: 3.074670571vw;
  }
}
.cvBnr .contact__wrap.tel .num::before {
  content: "";
  width: 29px;
  height: 29px;
  display: inline-block;
  background: url(../img/icon/icon_tel.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.tel .num::before {
    width: 2.1229868228vw;
    height: 2.1229868228vw;
  }
}
.cvBnr .contact__wrap.tel .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.cvBnr .contact__wrap.tel .receptionTime dt {
  font-size: 1.6rem;
  font-weight: bold;
  width: 5.5em;
}
.cvBnr .contact__wrap.tel .receptionTime dd {
  font-size: 1.6rem;
  width: calc(100% - 5.5em);
}
.cvBnr .contact__wrap.tel + .mail {
  width: 315px;
  margin: 10px 0 10px 28px;
}
.cvBnr .contact__wrap.mail {
  text-align: center;
}
.cvBnr .contact__wrap.mail .btn--mail {
  font-size: 2.1rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 22px 14px 17px;
  border-radius: 5px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 5px 0 0px #776D37;
  text-align: center;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.mail .btn--mail {
    font-size: 1.5373352855vw;
    padding: 22px 1.0248901903vw 17px;
  }
}
@media screen and (min-width: 767px) {
  .cvBnr .contact__wrap.mail .btn--mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.cvBnr .contact__wrap.mail .btn--mail::before {
  content: "";
  width: 24px;
  height: 18px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin: 0 5px -2px 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.mail .btn--mail::before {
    width: 1.756954612vw;
    height: 1.317715959vw;
  }
}
.cvBnr .contact__wrap.mail .btn--mail + .btn--reserve {
  margin-top: 15px;
}
.cvBnr .contact__wrap.mail .btn--reserve {
  font-size: 2.1rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 22px 14px 17px;
  border-radius: 5px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 5px 0 0px #26456B;
  text-align: center;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.mail .btn--reserve {
    font-size: 1.5373352855vw;
    padding: 22px 1.0248901903vw 17px;
  }
}
@media screen and (min-width: 767px) {
  .cvBnr .contact__wrap.mail .btn--reserve:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.cvBnr .contact__wrap.mail .btn--reserve::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  background: url(../img/icon/icon_calendar.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr .contact__wrap.mail .btn--reserve::before {
    width: 1.6105417277vw;
    height: 1.6105417277vw;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    margin: 40px auto;
  }
  .cvBnrWrap__ttl {
    font-size: 1.8rem;
    margin-bottom: 26px;
    padding-left: 14px;
  }
  .cvBnrWrap__ttl::before {
    width: 4px;
    height: 30px;
    top: -4px;
  }
  .cvBnr {
    padding: 28px 7.1794871795%;
  }
  .cvBnr .contact {
    display: block;
  }
  .cvBnr .contact__wrap.tel .num {
    font-size: 7.1794871795vw;
  }
  .cvBnr .contact__wrap.tel .num::before {
    width: 7.1794871795vw;
    height: 7.1794871795vw;
    margin: 0 4px -5px 0;
  }
  .cvBnr .contact__wrap.tel .receptionTime {
    display: block;
    margin: 12px 0 0;
    text-align: left;
  }
  .cvBnr .contact__wrap.tel .receptionTime dt {
    font-size: 1.4rem;
    display: block;
    width: 100%;
  }
  .cvBnr .contact__wrap.tel .receptionTime dd {
    font-size: 1.4rem;
    display: block;
    width: 100%;
  }
  .cvBnr .contact__wrap.tel + .mail {
    width: 100%;
    margin: 30px 0 0;
  }
  .cvBnr .contact__wrap.mail .btn--mail {
    font-size: 4.6153846154vw;
    padding: 20px 3.5897435897% 15px;
    border-radius: 4px;
  }
  .cvBnr .contact__wrap.mail .btn--mail::before {
    width: 20px;
    height: 15px;
    margin: 0 6px -2px 0;
  }
  .cvBnr .contact__wrap.mail .btn--mail + .btn--reserve {
    margin-top: 12px;
  }
  .cvBnr .contact__wrap.mail .btn--reserve {
    font-size: 4.6153846154vw;
    padding: 20px 3.5897435897% 15px;
    border-radius: 4px;
  }
  .cvBnr .contact__wrap.mail .btn--reserve::before {
    width: 20px;
    height: 20px;
    margin: 0 6px -2px 0;
  }
}
/*=================================
  area
=================================*/
.areaBox {
  background: #FBFBFB;
  padding: 60px 2.9282576867vw 50px;
}
.areaBox__ttl {
  font-size: 2.9rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
}
.areaBox__ttl::before {
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  background: url(../img/icon/icon_search_g.png) no-repeat center center;
  background-size: contain;
  margin: 0 9px -2px 0;
}
.areaBox__in {
  background: #fff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
  padding: 40px 54px 32px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .areaBox__in {
    padding: 2.9282576867vw 3.953147877vw 2.3426061493vw;
  }
}
.areaBox .btnWrap {
  margin: 40px 0 0;
}

.areaList__item {
  display: flex;
  align-items: flex-start;
}
.areaList__name {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  width: 8em;
  margin-right: 10px;
  margin-top: 8px;
}
.areaList__cont {
  width: calc(100% - 8em - 10px);
}
.areaList__cont .prefecture {
  margin-left: -3px;
}
.areaList__cont .prefecture__item {
  display: inline-block;
  margin: 0 3px 7px 0;
}
.areaList__cont .prefecture__item a {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  display: block;
  text-decoration: none;
  padding: 9px 13px 7px;
  border-radius: 2px;
  background: rgb(251, 251, 251);
  background: -moz-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: -webkit-linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  background: linear-gradient(0deg, rgb(251, 251, 251) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
  border-bottom: 2px solid #D2D2D2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .areaList__cont .prefecture__item:hover {
    margin-bottom: 9px;
  }
  .areaList__cont .prefecture__item:hover a {
    background: rgb(240, 240, 240);
    background: -moz-linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    background: -webkit-linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
    border: none;
    transform: translateY(2px);
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    area
  =================================*/
  .areaBox {
    padding: 40px 3.8461538462%;
    width: calc(107.6923076923% + 2px);
    margin-left: calc(-3.8461538462% - 1px);
  }
  .areaBox__ttl {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  .areaBox__ttl::before {
    width: 17px;
    height: 17px;
    margin: 0 6px -2px 0;
  }
  .areaBox__in {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    padding: 0;
  }
  .areaBox .btnWrap {
    margin: 30px 0 0;
  }
  .areaList__item {
    display: block;
  }
  .areaList__name {
    font-size: 1.7rem;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px 5.1282051282%;
    position: relative;
  }
  .areaList__name::after {
    content: "";
    width: 16px;
    height: 10px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 4.1025641026%;
    transform: translateY(-50%);
  }
  .areaList__cont {
    width: 100%;
    border-top: 1px solid #D2D2D2;
    background: #FBFBFB;
    padding: 3.5897435897% calc(3.5897435897% - 6px) calc(3.5897435897% - 10px);
  }
  .areaList__cont .prefecture__item {
    margin: 0 3px 10px 3px;
  }
  .areaList__cont .prefecture__item a {
    font-size: 1.6rem;
    padding: 9px 15px 7px;
    border-bottom: 4px solid #D2D2D2;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  }
  .areaList + .areaList {
    border-top: 1px solid #D2D2D2;
  }
  .js-area-pulldown-item {
    display: none;
  }
  .js-area-pulldown.open::after {
    transform: translateY(-50%) rotate(180deg);
  }
}
/*=================================
  clinic
=================================*/
.clinicList__item {
  border-top: 6px solid #36639A;
  background: #fff;
  padding: 45px 2.9282576867vw;
}
.clinicList__item .tagList {
  display: flex;
  flex-wrap: wrap;
}
.clinicList__item .tagList__item {
  font-size: 1.3rem;
  font-weight: 400;
  color: #36639A;
  padding: 3px 11px;
  border: 1px solid #36639A;
  border-radius: 3px;
  margin: 0 10px 14px 0;
}
.clinicList__item .tagList__item.menu {
  color: #fff;
  background-color: #36639A;
}
.clinicList__item .tagList + .caption {
  margin-top: 10px;
}
.clinicList__item .caption {
  font-size: 1.6rem;
  margin-bottom: 10px;
}
.clinicList__item .name {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.26666;
}
.clinicList__item .name a {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.26666;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .clinicList__item .name a:hover {
    text-decoration: underline;
  }
}
.clinicList__item .label {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #fff;
  background: #C62828;
  display: inline-block;
  padding: 2px 6px 0px 8px;
  margin-left: 4px;
  vertical-align: top;
}
.clinicList__item .contWrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.clinicList__item .contWrap__left {
  width: 46%;
  max-width: 385px;
}
.clinicList__item .contWrap__right {
  width: calc(55% - 2.9282576867vw);
  margin-left: 2.9282576867vw;
}
.clinicList__item .contWrap .thumb {
  margin-bottom: 20px;
}
.clinicList__item .contWrap .thumb img {
  margin: 0 auto;
}
.clinicList__item .contWrap .contact__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
  padding-left: 9px;
  position: relative;
}
.clinicList__item .contWrap .contact__ttl::before {
  content: "";
  width: 4px;
  height: 18px;
  display: inline-block;
  background: #36639A;
  background: -moz-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: -webkit-linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  background: linear-gradient(0deg, #36639A 0%, #36639A 50%, #AB9B4E 50%, #AB9B4E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
  position: absolute;
  top: -2px;
  left: 0;
}
.clinicList__item .contWrap .contact .tel {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  display: block;
  background: #FBFBFB;
  padding: 15px 5px 5px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicList__item .contWrap .contact .tel {
    font-size: 2.7818448023vw;
  }
}
@media screen and (min-width: 767px) {
  .clinicList__item .contWrap .contact .tel {
    pointer-events: none;
  }
}
.clinicList__item .contWrap .contact .tel::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/icon/icon_tel.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicList__item .contWrap .contact .tel::before {
    width: 1.756954612vw;
    height: 1.756954612vw;
    margin: 0 1.0248901903vw 0 0;
  }
}
.clinicList__item .contWrap .contact .receptionTime {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  background: #FBFBFB;
  padding: 5px 30px 13px;
}
.clinicList__item .contWrap .contact .receptionTime dt {
  font-weight: bold;
  width: 5em;
}
.clinicList__item .contWrap .contact .receptionTime dd {
  width: calc(100% - 5em);
}
.clinicList__item .contWrap .feature {
  background: #fff;
  margin: 0 0 20px;
}
.clinicList__item .contWrap .feature__row {
  border: 1px solid #D2D2D2;
}
.clinicList__item .contWrap .feature__ttl {
  font-size: 1.7rem;
  font-weight: bold;
  color: #C62828;
  background: #FBF2F2;
  width: 120px;
  text-align: center;
  padding: 12px 10px;
  border: none;
}
.clinicList__item .contWrap .feature__item {
  font-size: 1.8rem;
  font-weight: bold;
  color: #C62828;
  width: calc(100% - 120px);
  text-align: center;
  padding: 12px 10px;
  border: none;
}
.clinicList__item .contWrap .info {
  background: #fff;
  border: none;
  margin: 0;
}
.clinicList__item .contWrap .info__row {
  border-bottom: 1px dashed #D2D2D2;
}
.clinicList__item .contWrap .info__ttl {
  font-weight: 400;
  color: #333333;
  background: #FBFBFB;
  width: 120px;
  text-align: center;
  padding: 12px 10px;
  border: none;
}
.clinicList__item .contWrap .info__item {
  width: calc(100% - 120px);
  text-align: left;
  padding: 12px 10px;
  border: none;
}
.clinicList__item .contWrap .btnWrap {
  margin: 20px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .clinicList__item .contWrap .btnWrap {
    flex-wrap: wrap;
  }
  .clinicList__item .contWrap .btnWrap > a {
    width: 100%;
  }
  .clinicList__item .contWrap .btnWrap > a + a {
    margin: 10px 0 0;
  }
}
.clinicList__item .contWrap .btn--more {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 20px 10px 16px;
  border-radius: 4px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  width: 45%;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.clinicList__item .contWrap .btn--more::before {
  content: "";
  width: 8px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .clinicList__item .contWrap .btn--more:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.clinicList__item .contWrap .btn--mail {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 20px 10px 16px;
  border-radius: 4px;
  background: rgb(171, 155, 78);
  background: -moz-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  background: linear-gradient(0deg, rgb(171, 155, 78) 0%, rgba(171, 155, 78, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
  box-shadow: 0px 4px 0 0px #776D37;
  width: 55%;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.clinicList__item .contWrap .btn--mail::before {
  content: "";
  width: 18px;
  height: 13px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px -2px 0;
}
@media screen and (min-width: 767px) {
  .clinicList__item .contWrap .btn--mail:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}
.clinicList__item .contWrap .btn--reserve {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 20px 10px 16px;
  border-radius: 4px;
  background: rgb(54, 99, 154);
  background: -moz-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: -webkit-linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  background: linear-gradient(0deg, rgb(54, 99, 154) 0%, rgba(54, 99, 154, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
  box-shadow: 0px 4px 0 0px #26456B;
  width: 45%;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.clinicList__item .contWrap .btn--reserve::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_calendar.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .clinicList__item .contWrap .btn--reserve:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
}

@media screen and (min-width: 767px) and (max-width: 1020px) {
  /*=================================
    clinic
  =================================*/
  .clinicList__item .tagList__item {
    padding: 3px 8px;
    margin: 0 8px 10px 0;
  }
  .clinicList__item .caption {
    font-size: 1.5rem;
  }
  .clinicList__item .contWrap {
    margin-top: 20px;
    display: block;
  }
  .clinicList__item .contWrap__left {
    width: 100%;
    max-width: initial;
  }
  .clinicList__item .contWrap__right {
    width: 100%;
    margin: 20px 0 0;
  }
  .clinicList__item .contWrap .thumb {
    text-align: center;
  }
  .clinicList__item .contWrap .btn--mail::before {
    margin: 0 10px -2px 0;
  }
}
@media screen and (max-width: 767px) {
  /*=================================
    clinic
  =================================*/
  .clinicList__item {
    padding: 20px 3.8461538462%;
  }
  .clinicList__item .tagList__item {
    font-size: 1.2rem;
    padding: 2px 9px;
    border-radius: 2px;
    margin: 0 8px 7px 0;
  }
  .clinicList__item .tagList + .caption {
    margin-top: 20px;
  }
  .clinicList__item .caption {
    font-size: 1.4rem;
  }
  .clinicList__item .name {
    font-size: 2.2rem;
  }
  .clinicList__item .name a {
    font-size: 2.2rem;
  }
  .clinicList__item .label {
    margin-left: 8px;
  }
  .clinicList__item .contWrap {
    margin-top: 27px;
    display: block;
  }
  .clinicList__item .contWrap__left {
    width: 100%;
    max-width: initial;
    overflow: hidden;
  }
  .clinicList__item .contWrap__right {
    width: 100%;
    margin: 20px 0 0;
  }
  .clinicList__item .contWrap .thumb {
    margin: 0 0 20px;
  }
  .clinicList__item .contWrap .contact__ttl {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .clinicList__item .contWrap .contact .tel {
    font-size: 8.2051282051vw;
    background: transparent;
    padding: 0;
    text-align: left;
  }
  .clinicList__item .contWrap .contact .tel::before {
    width: 7.1794871795vw;
    height: 7.1794871795vw;
    margin: 0 3px 0 0;
  }
  .clinicList__item .contWrap .contact .receptionTime {
    justify-content: flex-start;
    background: transparent;
    padding: 0;
    width: 100%;
    margin-top: 14px;
  }
  .clinicList__item .contWrap .feature {
    display: table;
    white-space: initial;
    margin: 0 0 15px;
  }
  .clinicList__item .contWrap .feature__ttl {
    font-size: 1.4rem;
    width: 92px;
    padding: 10px 10px;
  }
  .clinicList__item .contWrap .feature__item {
    width: calc(100% - 92px);
    padding: 10px 10px;
  }
  .clinicList__item .contWrap .info {
    display: table;
    white-space: initial;
  }
  .clinicList__item .contWrap .info__ttl {
    font-size: 1.4rem;
    width: 92px;
    padding: 12px 10px;
    vertical-align: top;
  }
  .clinicList__item .contWrap .info__item {
    font-size: 1.4rem;
    width: calc(100% - 92px);
    padding: 12px 15px;
  }
  .clinicList__item .contWrap .btnWrap {
    margin: 30px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clinicList__item .contWrap .btn--more {
    font-size: 1.4rem;
    padding: 19px 5px 15px;
    width: 100%;
    max-width: 48%;
  }
  .clinicList__item .contWrap .btn--more::before {
    width: 8px;
    height: 13px;
    margin: 0 7px -2px 0;
  }
  .clinicList__item .contWrap .btn--more + .btn--mail {
    width: 49.4358974359%;
    margin: 0 0 0 2.5641025641%;
  }
  .clinicList__item .contWrap .btn--mail {
    font-size: 1.4rem;
    padding: 19px 5px 15px;
    width: 100%;
    max-width: 52%;
  }
  .clinicList__item .contWrap .btn--mail::before {
    width: 18px;
    height: 13px;
    margin: 0 5px -2px 0;
  }
  .clinicList__item .contWrap .btn--mail + .btn--reserve {
    width: 49.4358974359%;
    margin: 0 0 0 2.5641025641%;
  }
  .clinicList__item .contWrap .btn--reserve {
    font-size: 1.4rem;
    padding: 19px 5px 15px;
    width: 100%;
    max-width: 48%;
  }
  .clinicList__item .contWrap .btn--reserve::before {
    width: 14px;
    height: 14px;
    margin: 0 7px -2px 0;
  }
  .clinicList__item .contWrap .btn--reserve + .btn--mail {
    width: 49.4358974359%;
    margin: 0 0 0 2.5641025641%;
  }
}