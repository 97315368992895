@charset "utf-8";

/* =================================
  header
================================= */
.header {
  width: 100%;
  padding: 30px 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
  }
  .ttl--site {
    display: block;
    max-width: calc(100% - 535px);
    .logo {
      display: inline-block;
      margin-right: 33px;
    }
    .subText {
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 10px;
      display: inline-block;
    }
    @include mq-desktop {
      &:hover {
        .logo a {
          opacity: .8;
        }
      }
    }
  }
  .gNavBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      font-size: 1.5rem;
      font-weight: bold;
      color: #fff;
      display: inline-block;
      text-decoration: none;
      padding: 16px 20px 9px;
      border-radius: 4px;
      & + a {
        margin-left: 20px;
      }
    }
    .gNav__btn {
      &--search {
        background: rgb(54,99,154);
        background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
        box-shadow: 0px 4px 0 0px #26456B;
        @include animation-base(all, 0s);
        &::before {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url(../img/icon/icon_search.png) no-repeat center center;
          background-size: contain;
          margin: 0 6px -3px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
      &--gold {
        background: rgb(171,155,78);
        background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
        box-shadow: 0px 4px 0 0px #776D37;
        @include animation-base(all,0s);
        &::before {
          content: "";
          width: 9px;
          height: 13px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
          background-size: contain;
          margin: 0 8px -2px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
    }
  }
  .gNav {
    margin-top: -40px;
    &List {
      background: #fff;
      box-shadow: 0 10px 26px rgba(0,0,0, .06);
      padding: 0 8px;
      margin-top: 30px;
      @include mq-tab {
        padding: 0 vw(8);
        margin-top: vw(30);
      }
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      &__item {
        display: inline-block;
        margin: 0 14px;
        position: relative;
        @include mq-tab {
          margin: 0 vw(14);
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          display: block;
          background: $color-theme;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          z-index: 0;
          @include animation-base(all,.1s);
        }
        @include mq-desktop {
          &:hover {
            &::after {
              height: 5px;
            }
          }
        }
        a {
          font-size: 1.6rem;
          font-weight: bold;
          color: $color-theme;
          text-decoration: none;
          display: block;
          padding: 28px 0;
          position: relative;
          z-index: 2;
          @include mq-tab {
            padding: vw(28) 0;
          }
        }
        &.current-menu-item {
          a {
            &::after {
              height: 5px;
            }
          }
        }
        $categoryBgs:(
          implant: (
            icon-color: #{$color-implant},
            icon-size: #{$size-implant}
          ),
          ceramic: (
            icon-color: #{$color-ceramic},
            icon-size: #{$size-ceramic}
          ),
          whitening: (
            icon-color: #{$color-whitening},
            icon-size: #{$size-whitening}
          ),
          correction: (
            icon-color: #{$color-correction},
            icon-size: #{$size-correction}
          ),
          dentition: (
            icon-color: #{$color-dentition},
            icon-size: #{$size-dentition}
          ),
          denture: (
            icon-color: #{$color-denture},
            icon-size: #{$size-denture}
          ),
          tooth-decay: (
            icon-color: #{$color-tooth-decay},
            icon-size: #{$size-tooth-decay}
          ),
          dental-treatment: (
            icon-color: #{$color-dental-treatment},
            icon-size: #{$size-dental-treatment}
          )
        );
        @each $category, $props in $categoryBgs {
          &.#{$category} {
            &::after {
              background: map-get($props, "icon-color");
            }
            a {
              padding-left: 60px;
              @include mq-tab {
                padding-left: vw(60);
              }
              &::before {
                content: "";
                width: 40px;
                height: 40px;
                display: inline-block;
                border-radius: 3px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: url(../img/cat_#{$category}.png) no-repeat center center map-get($props, "icon-color");
                background-size: map-get($props, "icon-size");
                @include mq-tab {
                  width: vw(40);
                  height: vw(40);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 921px) and (max-width: 1023px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: vw(30) 1%;
    .ttl--site {
      max-width: calc(100% - 350px);
      .logo {
        margin-right: 10px;
      }
      .subText {
        font-size: 1.3rem;
        margin-top: 8px;
      }
    }
    .gNavBtnWrap {
      flex-wrap: wrap;
      max-width: vw(750);
      margin-left: auto;
      a {
        font-size: 1.4rem;
        padding: 16px vw(10) 9px;
        text-align: center;
        width: 100%;
        max-width: 240px;
        margin: vw(10);
        & + a {
          margin: vw(10);
        }
      }
    }
    .gNav {
      margin-top: vw(-80);
    }
  }
}

// @media screen and (min-width: 920px) { // ヘッダー追従
//   .gNavList {
//     &.fixedNav {
//       position: fixed;
//       top: 0;
//       left: 0;
//       right: 0;
//       width: 100%;
//       background: #fff;
//       box-shadow: 10px 3px 36px rgba(0, 0, 0, 0.1);
//       z-index: 999;
//     }
//   }
// }

@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    box-shadow: none;
    background: rgba(255, 255, 255, .8);
    position: relative;
    z-index: 9999;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
      position: relative;
    }
    .ttl--site {
      width: calc(100% - 60px);
      max-width: initial;
      height: 60px;
      padding: 15px 0 15px per(20);
      position: relative;
      z-index: 9999;
      .logo {
        width: vw(177,$sp-width);
        margin: 0;
        line-height: 1;
      }
      .subText {
        display: none;
      }
    }
    .gNavBtnWrap {
      display: block;
      background: $bg-color;
      padding: per(30) per(15);
      a {
        font-size: 1.9rem;
        padding: 25px 20px;
        width: 100%;
        text-align: center;
        & + a {
          margin: 20px 0 0;
        }
      }
      .gNav__btn {
        &--search {
          box-shadow: 0px 5px 0 0px #26456B;
          &::before {
            width: 18px;
            height: 18px;
            margin: 0 7px -3px 0;
          }
        }
        &--gold {
          box-shadow: 0px 5px 0 0px #776D37;
          &::before {
            width: 11px;
            height: 17px;
            margin: 0 12px -2px 0;
          }
        }
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 9999;
      transform: scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      margin-top: 0;
      &List {
        background: #fff;
        box-shadow: none;
        padding: 0;
        margin-top: 0;
        border-bottom: 2px dotted #CCCCCC;
        &__inner {
          justify-content: flex-start;
        }
        &__item {
          display: block;
          margin: 0;
          width: calc(100% / 2);
          border-top: 2px dotted #CCCCCC;
          &:nth-child(2n - 1) {
            border-right: 2px dotted #CCCCCC;
            position: relative;
            &:last-child {
              &::before {
                content: "";
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                display: block;
                position: absolute;
                top: -1%;
                right: -101%;
                border-top: 2px dotted #CCCCCC;
              }
            }
          }
          &::after {
            content: none;
          }
          a {
            font-size: 1.7rem;
            padding: 27px 3px;
          }
          $categorys: implant, ceramic, whitening, correction, dentition, denture, tooth-decay, dental-treatment;
          @each $category in $categorys {
            &.#{$category} {
              a {
                padding-left: vw(70,$sp-width);
                &::before {
                  width: vw(50,$sp-width);
                  height: vw(50,$sp-width);
                  left: per(15);
                  max-width: 50px;
                  max-height: 50px;
                }
              }
            }
          }
        }
      }
      &.show {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.7);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover {
      height: 0;
      width: 100vw;
      background: #fff;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      background: transparent;
      margin: auto;
      position: absolute;
      right: per(20);
      top: 15px;
      cursor: pointer;
      z-index: 9999;
      span {
        width: 27px;
        height: 3px;
        display: block;
        background: #333333;
        position: absolute;
        top: 9px;
        right: 0;
        left: 0;
        margin: 0 auto;
        transition: width 0.2s, right 0.2s, left 0.2s;
        &.burger--top{
          transform: translateY(0);
        }
        &.burger--middle{
          transform: translateY(7px);
          position: relative;
          background: none;
          &::before{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #333333;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #333333;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
        }
        &.burger--bottom{
          transform: translateY(14px);
          width: 17px;
          left: 4px;
          right: initial;
        }
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: #333;
        .burger--top{
          left: 100%;
          width: 0px;
        }
        .burger--middle {
          &::before{
            width: 18px;
            height: 2px;
            transform: rotate(135deg);
            background: #fff;
            left: 5px;
          }
          &::after {
            width: 18px;
            height: 2px;
            transform: rotate(45deg);
            background: #fff;
            left: 5px;
          }
        }
        .burger--bottom{
          right: 100%;
          width: 0px;
        }
      }
    }
    &.show {
      position: fixed;
      animation: burger-show 1s ease-in;
    }
  }
}