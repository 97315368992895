@charset "utf-8";

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  > * + * {
    margin-top: 9px;
  }
  .btn--gold {
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 18px 14px 16px;
    border-radius: 5px;
    background: rgb(171,155,78);
    background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
    box-shadow: 0px 5px 0 0px #776D37;
    &::after {
      content: "";
      width: 9px;
      height: 13px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      margin: 0 0 0 8px;
    }
  }
  .btn--blue {
    font-size: 1.9rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 27px 14px 22px;
    border-radius: 4px;
    background: rgb(54,99,154);
    background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
    box-shadow: 0px 5px 0 0px #26456B;
    width: 100%;
    display: block;
    text-align: center;
  }
  .btn--gray {
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-theme;
    text-decoration: none;
    padding: 22px per(14) 18px;
    border-radius: 4px;
    background: $bg-color;
    box-shadow: 0px 4px 0 0px #CCCCCC;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
    text-align: center;
    &::before {
      content: "";
      width: 10px;
      height: 15px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
      background-size: contain;
      margin: 0 13px -1px 0;
      transform: rotate(180deg);
    }
  }
  .btn--search {
    font-size: 1.9rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 27px 14px 22px;
    border-radius: 4px;
    background: rgb(54,99,154);
    background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
    box-shadow: 0px 5px 0 0px #26456B;
    width: 100%;
    display: block;
    &::before {
      content: "";
      width: 17px;
      height: 17px;
      display: inline-block;
      background: url(../img/icon/icon_search.png) no-repeat center center;
      background-size: contain;
      margin: 0 8px -3px 0;
    }
  }
}

@media screen and (min-width: $display-width-s){
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    > * + * {
      margin: 0 0 0 20px;
    }
    .btn--gold {
      font-size: 2rem;
      padding: 22px 20px 20px;
      border-radius: 5px;
      box-shadow: 0px 5px 0 0px #776D37;
      @include animation-base(all,0s);
      &::after {
        width: 12px;
        height: 16px;
        margin: 0 0 0 10px;
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(5px);
        }
      }
    }
    .btn--blue {
      font-size: 2rem;
      padding: 35px 20px 28px;
      border-radius: 7px;
      box-shadow: 0px 7px 0 0px #26456B;
      width: 100%;
      max-width: 70%;
      margin: 0 auto;
      @include animation-base(all,0s);
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(7px);
        }
      }
    }
    .btn--gray {
      font-size: 1.6rem;
      padding: 27px 14px 22px;
      max-width: 476px;
      display: block;
      @include animation-base(all,0s);
      &::before {
        width: 11px;
        height: 17px;
        margin: 0 15px -2px 0;
        transform: rotate(180deg);
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(4px);
        }
      }
    }
    .btn--search {
      font-size: 2rem;
      padding: 35px 20px 28px;
      border-radius: 7px;
      box-shadow: 0px 7px 0 0px #26456B;
      width: 100%;
      max-width: 70%;
      margin: 0 auto;
      @include animation-base(all,0s);
      &::before {
        width: 21px;
        height: 21px;
        margin: 0 10px -3px 0;
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(7px);
        }
      }
    }
  }
}