@charset "utf-8";
/* =================================
  side nav
================================= */
.sideNav {
  width: 306px;
  &__inner {
    width: 306px;
  }
  .sideSec {
    &__ttl {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 15px;
      padding-left: 20px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 24px;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
    &__cont {
      .newClinicList {
        &__item {
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px dashed #D2D2D2;
        }
        &__thumb {
          width: 156px;
          margin-right: 15px;
          position: relative;
          & + .newClinicList__text {
            width: calc(100% - 156px - 15px);
          }
        }
        &__label {
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.83333;
          color: #fff;
          background: rgba(54, 99, 154, .8);
          padding: 6px 12px;
          position: absolute;
          top: 0;
          left: 0;
        }
        &__text {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.375;
          text-decoration: none;
        }
      }
      .categoryList {
        &__item {
          a {
            font-size: 1.6rem;
            font-weight: bold;
            text-decoration: none;
            display: block;
            background: transparent;
            padding: 22px 10px 21px;
            border-bottom: 1px dashed #D2D2D2;
            position: relative;
          }
          $categoryBgs:(
            implant: (
              icon-color: #{$color-implant},
              icon-size: #{$size-implant}
            ),
            ceramic: (
              icon-color: #{$color-ceramic},
              icon-size: #{$size-ceramic}
            ),
            whitening: (
              icon-color: #{$color-whitening},
              icon-size: #{$size-whitening}
            ),
            correction: (
              icon-color: #{$color-correction},
              icon-size: #{$size-correction}
            ),
            dentition: (
              icon-color: #{$color-dentition},
              icon-size: #{$size-dentition}
            ),
            denture: (
              icon-color: #{$color-denture},
              icon-size: #{$size-denture}
            ),
            tooth-decay: (
              icon-color: #{$color-tooth-decay},
              icon-size: #{$size-tooth-decay}
            ),
            dental-treatment: (
              icon-color: #{$color-dental-treatment},
              icon-size: #{$size-dental-treatment}
            )
          );
          @each $category, $props in $categoryBgs {
            &.#{$category} {
              a {
                padding-left: 60px;
                &::before {
                  content: "";
                  width: 40px;
                  height: 40px;
                  display: inline-block;
                  border-radius: 3px;
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  transform: translateY(-50%);
                  background: url(../img/cat_#{$category}.png) no-repeat center center map-get($props, "icon-color");
                  background-size: map-get($props, "icon-size");
                }
                @include mq-desktop {
                  &:hover {
                    background: map-get($props, "icon-color");
                  }
                }
              }
            }
          }
        }
      }
      .relratedList {
        counter-reset: relratedNum;
        &__item {
          background: #fff;
          box-shadow: 0 10px 26px rgba(0, 0, 0, .06);
          position: relative;
          &:nth-child(-n + 3) {
            &::before {
              content: counter(relratedNum)"位";
              counter-increment: relratedNum;
              font-size: 1.2rem;
              color: #fff;
              display: inline-block;
              padding: 7px 14px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }
          }
          $rankList : (rgba(171, 156, 79, .8), rgba(54, 99, 154, .8), rgba(51, 51, 51, .8));
          @each $val in $rankList {
            $key : index($rankList, $val);
            &:nth-child(#{$key}) {
              &::before {
                background: $val;
              }
            }
          }
          & + .relratedList__item {
            margin-top: 20px;
          }
        }
        &__thumb {
          position: relative;
          img {
            width: 100%;
          }
        }
        &__text {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.375;
          text-decoration: none;
          display: block;
          padding: 20px 20px 17px;
        }
      }
    }
    & + .sideSec {
      margin-top: 45px;
    }
  }
}

@media screen and (max-width: 920px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    width: 100%;
    margin-top: 37px;
    padding: 0 per(15) 40px;
    &__inner {
      width: 100%;
    }
    .sideSec {
      &.clinic,&.category {
        display: none;
      }
      &__cont {
        .relratedList {
          display: flex;
          flex-wrap: wrap;
          &__item {
            width: calc((100% - 20px) / 2);
            margin-bottom: 20px;
            &:nth-child(2n) {
              margin-left: 20px;
            }
            &:nth-child(-n + 3) {
              &::before {
                padding: 5px 11px;
              }
            }
            & + .relratedList__item {
              margin-top: 0;
            }
          }
          &__text {
            font-size: 1.4rem;
            padding: 16px 16px 24px;
          }
        }
      }
      & + .sideSec {
        margin-top: 35px;
      }
    }
  }
}