@charset "utf-8";

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.47058;
  margin-bottom: 40px;
  padding-left: 20px;
  position: relative;
  &::before {
    content: "";
    width: 5px;
    height: 40px;
    display: inline-block;
    background: $color-theme;
    background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
    background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
    background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
    position: absolute;
    top: 2px;
    left: 0;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    margin-bottom: 22px;
    padding-left: 18px;
    &::before {
      width: 4px;
      height: 30px;
      top: 0;
    }
  }
}