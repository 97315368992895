@charset "utf-8";

/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
  &__item {
    font-size: 2.2rem;
    line-height: 1.363636;
    font-weight: 500;
    color: $font-color-base;
    background: #F3F3F3;
    padding: 30px 24px;
    text-align: center;
    width: 100%;
    &:not(.active) {
      cursor: pointer;
    }
    &.active {
      background: $color-theme;
      color: #fff;
    }
    @include mq-desktop {
      &:hover:not(.active) {
        background: $color-theme-light;
      }
    }
  }
}
.tabItem {
  background: #fff;
  min-height: 190px;
  .tabList {
    display: none;
  }
  .tabList.show {
    display: block!important;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    tab
  =================================*/
  .tabMenu {
    &__item {
      font-size: 1.6rem;
      padding: 27px per(17);
    }
  }
  .tabItem {
    min-height: inherit;
  }
}