@charset "utf-8";

//transition初期設定
@mixin animation-base($property:all, $duration:.2s, $timing:ease-out) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes vibrate {
  0% {
      transform: rotate(0deg);
  }
  25% {
      transform: rotate(15deg);
  }
  50% {
      transform: rotate(-15deg);
  }
  75% {
      transform: rotate(15deg);
  }
  100% {
      transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
      transform: rotate(0deg) scale(1.2);
  }
  5% {
      transform: rotate(15deg) scale(1.2);
  }
  10% {
      transform: rotate(-15deg) scale(1.2);
  }
  15% {
      transform: rotate(15deg) scale(1.2);
  }
  20% {
      transform: rotate(0deg) scale(1);
  }
  100% {
      transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    top: -20%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}