@charset "utf-8";
/*=================================
  area
=================================*/
.areaBox {
  background: $bg-color;
  padding: 60px vw(40) 50px;
  &__ttl {
    font-size: 2.9rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
    &::before {
      content: "";
      width: 26px;
      height: 26px;
      display: inline-block;
      background: url(../img/icon/icon_search_g.png) no-repeat center center;
      background-size: contain;
      margin: 0 9px -2px 0;
    }
  }
  &__in {
    background: #fff;
    box-shadow: 0 10px 26px rgba(0, 0, 0, .05);
    padding: 40px 54px 32px;
    @include mq-tab {
      padding: vw(40) vw(54) vw(32);
    }
  }
  .btnWrap {
    margin: 40px 0 0;
  }
}
.areaList {
  &__item {
    display: flex;
    align-items: flex-start;
  }
  &__name {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    width: 8em;
    margin-right: 10px;
    margin-top: 8px;
  }
  &__cont {
    width: calc(100% - 8em - 10px);
    .prefecture {
      margin-left: -3px;
      &__item {
        display: inline-block;
        margin: 0 3px 7px 0;
        a {
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1;
          display: block;
          text-decoration: none;
          padding: 9px 13px 7px;
          border-radius: 2px;
          background: rgb(251,251,251);
          background: -moz-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
          background: -webkit-linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
          background: linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(255,255,255,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
          border-bottom: 2px solid #D2D2D2;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
          @include animation-base(all,0s);
        }
        @include mq-desktop {
          &:hover {
            margin-bottom: 9px;
            a {
              background: rgb(240,240,240);
              background: -moz-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
              background: -webkit-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
              background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfbfb",endColorstr="#ffffff",GradientType=1);
              border: none;
              transform: translateY(2px);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    area
  =================================*/
  .areaBox {
    padding: 40px per(15);
    width: calc(100% + per(30) + 2px);
    margin-left: calc(per(-15) - 1px);
    &__ttl {
      font-size: 1.8rem;
      margin-bottom: 30px;
      &::before {
        width: 17px;
        height: 17px;
        margin: 0 6px -2px 0;
      }
    }
    &__in {
      box-shadow: 0 3px 6px rgba(0, 0, 0, .18);
      padding: 0;
    }
    .btnWrap {
      margin: 30px 0 0;
    }
  }
  .areaList {
    &__item {
      display: block;
    }
    &__name {
      font-size: 1.7rem;
      text-align: left;
      width: 100%;
      margin: 0;
      padding: 14px per(20);
      position: relative;
      &::after {
        content: "";
        width: 16px;
        height: 10px;
        display: inline-block;
        background: url(../img/icon/icon_arrow_d.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: per(16);
        transform: translateY(-50%);
      }
    }
    &__cont {
      width: 100%;
      border-top: 1px solid #D2D2D2;
      background: #FBFBFB;
      padding: per(14) calc(per(14) - 6px) calc(per(14) - 10px);
      .prefecture {
        &__item {
          margin: 0 3px 10px 3px;
          a {
            font-size: 1.6rem;
            padding: 9px 15px 7px;
            border-bottom: 4px solid #D2D2D2;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, .16);
          }
        }
      }
    }
    & + .areaList {
      border-top: 1px solid #D2D2D2;
    }
  }
  .js-area-pulldown {
    &-item {
      display: none;
    }
    &.open {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}