@charset "utf-8";
/*=================================
  visual
=================================*/
.mv {
  background: url(../img/top_mv_pc.jpg) no-repeat left top;
  background-size: cover;
  padding: 36px 1% 82px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__catch {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.5;
    padding-left: 40px;
    @include mq-tab {
      font-size: vw(44);
      padding-left: 0;
    }
    .marker {
      padding: 4px 10px;
      background: rgba(255, 255, 255, .8);
    }
  }
  .areaBox {
    width: calc(64% - 37px);
    margin-left: 37px;
    background: #fff;
    box-shadow: 0 10px 26px rgba(0, 0, 0, .05);
    padding: 37px 16px 40px;
    @include mq-tab {
      width: calc(64% - vw(37));
      margin-left: vw(37);
      padding: 37px vw(16) 40px;
    }
    &__ttl {
      font-size: 2.4rem;
      color: $color-theme;
      margin-bottom: 33px;
      &::before {
        width: 20px;
        height: 20px;
        background: url(../img/icon/icon_search_n.png) no-repeat center center;
        background-size: contain;
        margin: 0 7px -2px 0;
      }
    }
    &__in {
      box-shadow: none;
      padding: 0;
      @include mq-tab {
        padding: 0;
      }
    }
    .btnWrap {
      margin: 20px 0 0;
    }
  }
  .areaList {
    &__cont {
      .prefecture {
        margin-left: 1px;
        &__item {
          margin: 0 1px 6px 0;
          @include mq-desktop {
            &:hover {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 921px) {
  .mvWrap {
    position: relative;
    .header__inner {
      position: static;
    }
    .gNavList {
      max-width: $base-width;
      width: 98%;
      margin-top: 0;
      position: absolute;
      top: calc(100% - 47px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999;
    }
  }
}

@media screen and (max-width: 920px) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: none;
    padding: 0;
    &__inner {
      display: block;
    }
    &__catch {
      font-size: vw(28,$sp-width);
      line-height: 1.53571;
      padding: vw(55,$sp-width) per(17) vw(13,$sp-width);
      background: url(../img/top_mv_sp.jpg) no-repeat center top;
      background-size: cover;
      .marker {
        padding: 4px 6px;
      }
    }
    .areaBox {
      width: calc(100% - per(40));
      margin: 40px auto;
      box-shadow: none;
      padding: 0;
      &__ttl {
        padding: 20px 5px 0;
      }
      &__in {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .18);
      }
    }
    .areaList {
      &__cont {
        .prefecture {
          margin-left: 6px;
          &__item {
            margin: 0 6px 10px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    visual
  =================================*/
  .mv {
    .areaBox {
      &__ttl {
        font-size: vw(17,$sp-width);
        color: #fff;
        background: $color-theme;
        margin-bottom: 0;
        padding: 11px 5px;
        &::before {
          width: vw(16,$sp-width);
          height: vw(16,$sp-width);
          background: url(../img/icon/icon_search.png) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}