@charset "utf-8";

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  padding: 0 1% 12px;
  ul {
    line-height: 1;
    li {
      font-size: 1.4rem;
      line-height: 1;
      padding-right: 30px;
      display: inline-block;
      position: relative;
      &:not(:last-child) {
        &::before {
          content: "";
          width: 9px;
          height: 13px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r_g.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 7px;
          transform: translateY(-50%);
        }
      }
      a,span {
        font-size: 1.4rem;
        line-height: 1;
        display: block;
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 per(17);
    ul {
      margin-bottom: 0;
      li {
        font-size: 1.2rem;
        a,span {
          font-size: 1.2rem;
          padding: 8px 6px 10px;
        }
        & + li {
          &::before {
            font-size: 1rem;
          }
        }
      }
    }
  }
}