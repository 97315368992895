@charset "utf-8";
/*=================================
  下層ページ共通
=================================*/
/* 地域・クリニック詳細ページ */
.clinicDetail {
  padding: 40px vw(40) 40px;
  background: #fff;
  border: 1px solid #CCCCCC;
  border-top: 6px solid $color-theme;
  &__in {
    & > :last-child.areaBox {
      margin-bottom: -40px;
    }
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
    /* 個別設定 */
    & > {
      table {
        &.left,&.infoTable {
          table-layout: auto;
          th,td {
            text-align: left;
          }
          .labelList {
            border: none;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
  .clinicWrap,.areaBox {
    width: calc(100% + vw(80));
    margin-left: vw(-40);
  }
  @include singleStyle-parts;
  /* 個別設定 */
  .clinicList__item {
    &:first-child {
      border-top: none;
    }
  }

  /*============================
    クリニック詳細
  ============================*/
  /* キャッチコピー */
  .catchCopy {
    font-size: 1.6rem;
    font-weight: 400;
    margin: -10px 0 40px 0;
    & + .labelList {
      margin-top: -30px;
    }
  }
  /* スライダー */
  .photoWrap {
    img {
      margin: 0 auto;
      max-height: 400px;
    }
    .photoList {
      .slick-track {
        display: flex;
        align-items: center;
      }
    }
    .slick-dots {
      position: static;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 20px);
      margin: 20px 0 0 -10px;
      li {
        width: calc((100% - 80px) / 4);
        height: initial;
        margin: 10px 10px;
      }
    }
  }
  /* 料金表 */
  .priceTable {
    border: none;
    th,td {
      font-size: 2.2rem;
      padding: 20px 20px;
      border-right: 1px dashed #D2D2D2;
      border-left: 1px dashed #D2D2D2;
      @include mq-tab {
        font-size: vw(26);
        padding: 20px vw(20);
      }
    }
    th {
      color: $font-color-base;
      background: $bg-color;
      padding: 16px 15px;
    }
    td {
      padding: 18px 15px;
    }
    thead {
      th {
        color: $font-color-base;
        background: $bg-color;
      }
      td {
        border-bottom: none;
      }
    }
    tbody {
      td {
        border-bottom: none;
      }
      th {
        border-bottom: none;
      }
    }
    .text--blue {
      font-weight: bold;
      color: $color-theme;
    }
    .text--gold {
      font-weight: bold;
      color: $color-gold;
    }
    .text--red {
      font-size: 3.4rem;
      font-weight: bold;
      color: $color-red;
      @include mq-tab {
        font-size: vw(34);
      }
    }
    .text--price {
      font-weight: bold;
      .num {
        font-size: 3.8rem;
        @include mq-tab {
          font-size: vw(38);
        }
      }
    }
  }
  .linkNext {
    text-align: right;
    a {
      font-size: 1.8rem;
      text-decoration: none;
      padding-left: 23px;
      position: relative;
      @include mq-desktop {
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: "";
        width: 8px;
        height: 13px;
        display: inline-block;
        background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
        background-size: contain;
        margin: 0 7px 0 0;
      }
    }
  }
  /* タグ */
  .labelList {
    border-top: 1px dashed #D2D2D2;
    border-bottom: 1px dashed #D2D2D2;
    padding: 25px 0;
    margin: 30px 0;
    &__item {
      font-size: 1.4rem;
      font-weight: 400;
      display: inline-block;
      background: $bg-color;
      padding: 5px 13px 4px;
      margin: 5px 3px;
      &.menu {
        color: #fff;
        background: $color-theme;
      }
    }
    &.menu {
      border: none;
      padding: 0;
    }
  }
  /* 選ばれる理由 */
  .clinicPr {
    padding: 40px;
    margin: 40px 0 60px;
    background: #fff;
    border: 1px dashed #D2D2D2;
    @include mq-tab {
      padding: 40px vw(40);
    }
    &__ttl {
      font-size: 1.8rem;
      font-weight: bold;
      color: $color-theme;
      margin-bottom: 16px;
      padding-left: 31px;
      position: relative;
      &::before {
        content: "";
        width: 20px;
        height: 25px;
        display: inline-block;
        background: url(../img/icon/icon_tooth.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 1.625;
    }
  }

  /* アクセス */
  .gMap {
    position: relative;
    padding-bottom: 47.67%;
    height: 0;
    overflow: hidden;
    iframe,object,embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* 検索パーツ */
.searchWrap {
  .result {
    font-size: 1.6rem;
    margin: 30px 0 40px;
    .num {
      font-weight: bold;
      color: $color-theme;
    }
  }
  .btnWrap {
    margin: 40px auto 37px;
  }
  .linkBack {
    text-align: center;
    a {
      font-size: 1.6rem;
      text-decoration: none;
      padding-left: 23px;
      position: relative;
      @include mq-desktop {
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        content: "";
        width: 10px;
        height: 15px;
        display: inline-block;
        background: url(../img/icon/icon_arrow_r_b.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 3px;
        left: 0;
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* 地域・クリニック詳細ページ */
  .clinicDetail {
    padding: 30px per(15) 40px;
    &__in {
      /* 個別設定 */
      & > {
        table {
          &.left,&.infoTable {
            display: table;
            white-space: initial;
            th {
              padding: 18px 8px;
            }
          }
        }
      }
    }
    .clinicWrap,.areaBox {
      width: calc(100% + per(30) + 2px);
      margin-left: calc(per(-15) - 1px);
    }
    .clinicWrap {
      .btn--search {
        width: calc(100% - per(30));
        margin: 0 auto;
      }
    }
    /*============================
      クリニック詳細
    ============================*/
    /* キャッチコピー */
    .catchCopy {
      margin: -10px 0 30px 0;
      & + .labelList {
        margin-top: -30px;
      }
    }
    /* スライダー */
    .photoWrap {
      img {
        max-height: 239px;
      }
      .slick-dots {
        width: calc(100% + 10px);
        margin: 0 0 0 -5px;
        li {
          width: calc((100% - 30px) / 3);
          height: initial;
          margin: 11px 5px;
        }
      }
    }
    /* 料金表 */
    .priceTable {
      display: table;
      white-space: initial;
      th,td {
        font-size: vw(14,$sp-width);
        padding: 11px per(5);
      }
      th {
        padding: 11px per(5);
      }
      td {
        padding: 7px per(5);
      }
      thead {
        border: none;
      }
      tbody {
        border: none;
      }
      .text--red {
        font-size: vw(24,$sp-width);
      }
      .text--price {
        font-weight: bold;
        .num {
          font-size: vw(17,$sp-width);
        }
      }
    }
    .linkNext {
      a {
        font-size: 1.6rem;
        padding-left: 14px;
      }
    }
    /* タグ */
    .labelList {
      &__item {
        font-size: 1.2rem;
        border: 1px solid $color-theme;
        border-radius: 2px;
        background: #fff;
        padding: 3px 9px 2px;
        margin: 3px 2px;
      }
    }
    /* 選ばれる理由 */
    .clinicPr {
      padding: 40px per(30) 37px;
      margin: 40px 0;
      &__ttl {
        margin-bottom: 18px;
        padding-left: 27px;
      }
    }
    /* アクセス */
    .gMap {
      padding-bottom: 93.203%;
    }
  }

  /* 検索パーツ */
  .searchWrap {
    .result {
      margin: 24px 0 40px;
    }
    .btnWrap {
      margin: 40px auto 30px;
    }
    .linkBack {
      a {
        font-size: 1.4rem;
        padding-left: 19px;
        &::before {
          width: 9px;
          height: 14px;
          top: 2px;
        }
      }
    }
  }
}