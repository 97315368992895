@charset "utf-8";
/*=================================
  LP
=================================*/
.lpPage {
  padding: 17px 0 90px;
  @include singleStyle-base;
  @include singleStyle-parts;
  @for $i from 1 through 6 {
    & > h#{$i}:first-child {
      margin-top: 0!important;
    }
  }
  img:not([class]) {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2:not([class]) {
    width: 100%;
    margin-left: 0;
    background: $color-theme;
    color: #fff;
  }
  @include mq-sp {
    h2:not([class]) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__in {
    background: #fff;
  }
  .text--summary {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.725;
    margin: 0 0 60px;
  }
  .lpBtn--blue {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 26px 46px 24px 20px;
    border-radius: 5px;
    background: rgb(54,99,154);
    background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
    box-shadow: 0px 5px 0 0px #26456B;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 70%;
    margin: 20px auto 50px;
    position: relative;
    @include animation-base(all,0s);
    &::after {
      content: "";
      width: 12px;
      height: 16px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    @include mq-desktop {
      &:hover {
        box-shadow: none;
        transform: translateY(5px);
      }
    }
  }
  .lpBtn--gold {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 26px 46px 24px 20px;
    border-radius: 5px;
    background: rgb(171,155,78);
    background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
    box-shadow: 0px 5px 0 0px #776D37;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 70%;
    margin: 20px auto 50px;
    position: relative;
    @include animation-base(all,0s);
    &::after {
      content: "";
      width: 12px;
      height: 16px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    @include mq-desktop {
      &:hover {
        box-shadow: none;
        transform: translateY(5px);
      }
    }
  }
  .lp--kv {
    background: url(../img/lp/lp_kv_bg.jpg) no-repeat right top;
    background-size: cover;
    padding: 100px 1.5%;
    &__cont {
      width: 70%;
    }
    &__copy {
      font-size: 4rem;
      color: #fff;
      line-height: 1.8;
      letter-spacing: .08em;
      box-decoration-break: clone;
      display: inline;
      padding: 10px;
      background-color: rgba(54, 99, 154, .8);
      @include mq-tab {
        font-size: vw(40);
        line-height: vw(88);
      }
    }
    &__description {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.75;
      text-shadow: 0 0 10px rgba(255, 255, 255, .8),0 0 10px rgba(255, 255, 255, .8);
      margin: 40px 0 0;
    }
  }
  .lp--cardList {
    display: flex;
    margin: 50px 0 0;
    counter-reset: cardNum;
    &__item {
      width: calc((100% - 60px) / 3);
      padding: 20px 15px 30px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, .12);
      position: relative;
      &::before {
        content: "";
        border-style: solid;
        border-width: 70px 70px 0 0;
        border-color: rgba(171, 155, 75, .8) transparent transparent transparent;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after {
        counter-increment: cardNum;
        content: counter(cardNum);
        @include font-roboto;
        font-size: 2.4rem;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 3px;
        left: 15px;
      }
      &:not(:nth-child(3n + 1)) {
        margin-left: 30px;
      }
    }
    &__thumb {
      margin: 0 0 20px;
    }
    &__ttl {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.47058;
      margin-bottom: 20px;
      padding: 0 0 0 20px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 100%;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 1.724;
      margin: 20px 0 0;
    }
    &__btn {
      text-align: center;
      margin: 30px 0 0;
      a {
        font-size: 1.5rem;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        background: rgb(54,99,154);
        background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
        box-shadow: 0px 4px 0 0px #26456B;
        border-radius: 50px;
        padding: 10px 30px;
        @include animation-base(all, 0s);
        &::after {
          content: "";
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: #fff transparent transparent transparent;
          display: inline-block;
          margin: 0 0 0 10px;

        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
    }
  }
  .section01 {
    &__wrap {
      display: flex;
      align-items: flex-start;
    }
    &__img {
      width: 24%;
      border: 1px solid #ccc;
      border-top: none;
    }
    &__cont {
      width: 73%;
      margin-left: 3%;
      counter-reset: section01Num;
    }
    &__item {
      & + .section01__item {
        margin-top: 40px;
      }
    }
    &__ttl {
      font-size: 2.4rem;
      font-weight: 700;
      font-style: italic;
      line-height: 1;
      color: $color-theme;
      display: inline-block;
      background:linear-gradient(transparent 60%, #ffff82 60%);
      padding: 10px 7px 10px 70px;
      margin: 0 0 20px;
      position: relative;
      &::before {
        counter-increment: section01Num;
        content: "0" counter(section01Num);
        @include font-roboto;
        font-size: 4rem;
        font-weight: 700;
        line-height: 1;
        color: $color-theme;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
      }
    }
    &__text {
      em,strong {
        font-size: 110%;
      }
    }
  }
  .section02 {
    &__cont {
      margin: 80px 0 0;
    }
    &__wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__ttl {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.47058;
      margin-bottom: 40px;
      padding: 0 0 0 20px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 100%;
        display: inline-block;
        background: $color-theme;
        background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__img {
      width: 30%;
      margin: 0 0 0 20px;
    }
    &__table {
      .em {
        font-size: 2rem;
        font-weight: 700;
        color: $color-red;
      }
      .num {
        @include font-roboto;
        font-size: 200%;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  .section03 {
    &__table {
      td {
        text-align: left;
      }
      .ttl {
        display: block;
        font-weight: 700;
        margin: 0 0 10px;
        &::before {
          content: "";
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $color-theme transparent transparent transparent;
          display: inline-block;
          margin: 0 5px 0 0;
        }
      }
      .em {
        font-size: 2rem;
        font-weight: 700;
        color: $color-red;
      }
      .num {
        @include font-roboto;
        font-size: 200%;
      }
    }
  }
  .section04 {
    .flow {
      &Item {
        padding: 40px;
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        .step {
          min-width: 380px;
          margin-right: 40px;
          position: relative;
          .label {
            @include font-roboto;
            font-size: 2.2rem;
            color: #fff;
            text-align: center;
            background: rgba(109, 194, 255, 0.8);
            background-size: contain;
            border-radius: 50%;
            display: inline-block;
            width: 100px;
            line-height: 100px;
            border: 10px solid rgba(202, 234, 255, 0.8);
            position: absolute;
            top: -30px;
            left: -30px;
            box-sizing: content-box;
            @include mq-tab {
              font-size: vw(32);
              width: vw(140);
              line-height: vw(140);
              border-width: vw(12);
            }
          }
        }
        .flowCont {
          width: calc(100% - 420px);
          min-width: 430px;
          &__ttl {
            font-size: 2.8rem;
            font-weight: 700;
            color: $color-theme;
            margin: 15px 0;
          }
          &__text {
            font-size: 1.8rem;
            line-height: 1.8888;
            em {
              background: none;
            }
          }
        }
        &:not(:last-child) {
          .step {
            .label::after {
              content: "";
              width: 39px;
              height: 28px;
              display: inline-block;
              background: url(../img/lp/lp_flow_step.png) no-repeat center center;
              background-size: contain;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -46px;
              margin: 0 auto;
            }
          }
        }
        & + .flowItem {
          margin-top: 20px;
        }
        .lpBtn--blue,.lpBtn--gold {
          width: 100%;
          max-width: 510px;
          margin: 20px 0 30px;
        }
      }
    }
  }
}

/* =================================
  lp--header
================================= */
.lp--header {
  width: 100%;
  padding: 30px 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
  }
  .ttl--site {
    display: block;
    display: flex;
    justify-content: space-between;
    .logo {
      display: inline-block;
      margin-right: 33px;
    }
    .subText {
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 10px;
      display: inline-block;
    }
    @include mq-desktop {
      &:hover {
        .logo a {
          opacity: .8;
        }
      }
    }
  }
  .gNavBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      font-size: 1.5rem;
      font-weight: bold;
      color: #fff;
      display: inline-block;
      text-decoration: none;
      padding: 16px 20px 9px;
      border-radius: 4px;
      & + a {
        margin-left: 20px;
      }
    }
    .gNav__btn {
      &--search {
        background: rgb(54,99,154);
        background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
        box-shadow: 0px 4px 0 0px #26456B;
        @include animation-base(all, 0s);
        &::before {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url(../img/icon/icon_search.png) no-repeat center center;
          background-size: contain;
          margin: 0 6px -3px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
      &--gold {
        background: rgb(171,155,78);
        background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
        box-shadow: 0px 4px 0 0px #776D37;
        @include animation-base(all,0s);
        &::before {
          content: "";
          width: 9px;
          height: 13px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
          background-size: contain;
          margin: 0 8px -2px 0;
        }
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(4px);
          }
        }
      }
    }
  }
  .gNav {
    &List {
      background: #fff;
      box-shadow: 0 10px 26px rgba(0,0,0, .06);
      padding: 0 8px;
      margin-top: 30px;
      @include mq-tab {
        padding: 0 vw(8);
        margin-top: vw(30);
      }
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      &__item {
        display: inline-block;
        margin: 0 14px;
        position: relative;
        @include mq-tab {
          margin: 0 vw(14);
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          display: block;
          background: $color-theme;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          z-index: 0;
          @include animation-base(all,.1s);
        }
        @include mq-desktop {
          &:hover {
            &::after {
              height: 5px;
            }
          }
        }
        a {
          font-size: 1.6rem;
          font-weight: bold;
          color: $color-theme;
          text-decoration: none;
          display: block;
          padding: 28px 0;
          position: relative;
          z-index: 2;
          @include mq-tab {
            padding: vw(28) 0;
          }
        }
        &.current-menu-item {
          a {
            &::after {
              height: 5px;
            }
          }
        }
      }
    }
  }
}

/*=================================
  lp--cvBner
=================================*/
.lp--cvBnrWrap {
  margin: 40px auto 34px;
  max-width: 1000px;
  &__ttl {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    padding-left: 18px;
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 24px;
      display: inline-block;
      background: $color-theme;
      background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.lp--cvBnr {
  background: $bg-color;
  border: 1px solid #CCCCCC;
  padding: 40px 30px;
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    &__wrap {
      margin-left: auto;
      margin-right: auto;
      &.tel {
        text-align: center;
        .num {
          font-size: 4.2rem;
          font-weight: bold;
          text-decoration: none;
          @include mq-desktop {
            pointer-events: none;
          }
          &::before {
            content: "";
            width: 29px;
            height: 29px;
            display: inline-block;
            background: url(../img/icon/icon_tel.png) no-repeat center center;
            background-size: contain;
            margin: 0 14px 0 0;
          }
        }
        .receptionTime {
          display: flex;
          justify-content: center;
          margin: 10px 0 0;
          dt {
            font-size: 1.6rem;
            font-weight: bold;
          }
          dd {
            font-size: 1.6rem;
          }
        }
      }
      &.mail {
        text-align: center;
        .btn--mail {
          font-size: 2.4rem;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          padding: 32px 14px 27px;
          border-radius: 5px;
          background: rgb(171,155,78);
          background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
          box-shadow: 0px 5px 0 0px #776D37;
          text-align: center;
          display: block;
          @include animation-base(all,0s);
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
          & + .btn--reserve {
            margin-top: 15px;
          }
        }
        .btn--reserve {
          font-size: 2.1rem;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          padding: 22px 14px 17px;
          border-radius: 5px;
          background: rgb(54,99,154);
          background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
          box-shadow: 0px 5px 0 0px #26456B;
          text-align: center;
          display: block;
          @include animation-base(all,0s);
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
        }
      }
    }
  }
}

.lpBtn {
  display: none!important;
  &__ttl {
    font-size: 1.6rem;
    font-weight: 700;
    color: #fff;
    writing-mode: vertical-rl;
    text-decoration: none;
    background: url(../img/lp/lp_site_icon.jpg) no-repeat center 10px $color-theme;
    padding: 50px 15px 15px;
    box-shadow: 0 0 10px rgba(176, 176, 176, .8);
    border-radius: 5px 0 0 5px;
    @include mq-desktop {
      &:hover {
        opacity: .8;
      }
    }
  }
  &.float {
    position: fixed;
    bottom: 255px;
    right: 3%;
    display: block!important;
    z-index: 9999;
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    LP
  =================================*/
  .lpPage {
    & > h2:first-child {
      width: 100%;
      margin-left: 0;
    }
    &__in {
      padding: 30px per(15) 40px;
    }
    table {
      display: table;
      white-space: normal;
    }
    .text--summary {
      font-size: 1.5rem;
      margin: 0 0 50px;
    }
    .lpBtn--blue {
      font-size: 2rem;
      padding: 16px 26px 14px 10px;
      border-radius: 4px;
      box-shadow: 0px 4px 0 0px #26456B;
      max-width: 100%;
      margin: 20px auto 40px;
      &::after {
        width: 10px;
        height: 14px;
      }
    }
    .lpBtn--gold {
      font-size: 2rem;
      padding: 16px 26px 14px 10px;
      border-radius: 4px;
      box-shadow: 0px 4px 0 0px #776D37;
      max-width: 100%;
      margin: 20px auto 40px;
      &::after {
        width: 10px;
        height: 14px;
      }
    }
    .lp--kv {
      background: url(../img/lp/lp_kv_bg_sp.jpg) no-repeat center top;
      background-size: cover;
      padding: 80px 2% 0;
      &__cont {
        width: 90%;
      }
      &__copy {
        font-size: 2rem;
        line-height: 2.2;
        padding: 8px;
      }
      &__description {
        font-size: 1.6rem;
        margin: 20px 0 0;
      }
    }
    .lp--cardList {
      display: block;
      margin: 0;
      &__item {
        width: 100%;
        padding: 20px 3.5% 30px;
        margin: 20px 0 0;
        &::after {
          font-size: 2.6rem;
        }
        &:not(:nth-child(3n + 1)) {
          margin-left: 0;
        }
      }
      &__text {
        font-size: 1.5rem;
      }
    }
    .section01 {
      &__wrap {
        display: block;
      }
      &__img {
        width: 80%;
        margin: 0 auto 40px;
      }
      &__cont {
        width: 100%;
        margin-left: 0;
      }
      &__item {
        & + .section01__item {
          margin-top: 30px;
        }
      }
      &__ttl {
        font-size: 2.2rem;
        padding: 10px 7px 10px 60px;
        margin: 0 0 15px;
        &::before {
          font-size: 3.6rem;
        }
      }
    }
    .section02 {
      &__cont {
        margin: 30px 0 0;
      }
      &__wrap {
        display: block;
      }
      &__ttl {
        font-size: 2rem;
        margin-bottom: 30px;
      }
      &__img {
        width: 80%;
        margin: 0 auto;
      }
      &__table {
        .em {
          font-size: 1.8rem;
        }
      }
    }
    .section03 {
      &__table {
        .em {
          font-size: 1.8rem;
        }
      }
    }
    .section04 {
      .flow {
        &Item {
          padding: 20px;
          display: block;
          .step {
            min-width: inherit;
            margin: 0 0 13px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .label {
              font-size: 1.4rem;
              width: 80px;
              line-height: 80px;
              display: inline-block;
              border-width: 8px;
              position: relative;
              top: 0;
              left: 0;
            }
            .img {
              width: calc(100% - 105px);
            }
          }
          .flowCont {
            width: 100%;
            min-width: inherit;
            &__ttl {
              font-size: 1.6rem;
              margin: 0 0 12px;
            }
            &__text {
              font-size: 1.4rem;
            }
          }
          &:not(:last-child) {
            .step {
              .label::after {
                width: 25px;
                height: 18px;
                bottom: -33px;
              }
            }
          }
          & + .flowItem {
            margin-top: 15px;
          }
        }
      }
    }
  }
  /* =================================
    lp--header
  ================================= */
  .lp--header {
    padding: 0;
    box-shadow: none;
    background: rgba(255, 255, 255, .8);
    position: relative;
    z-index: 9999;
    .ttl--site {
      width: calc(100% - 70px);
      max-width: initial;
      height: 60px;
      padding: 15px 0 15px per(20);
      position: relative;
      z-index: 9999;
      .logo {
        width: vw(150,$sp-width);
        margin: 0;
        line-height: 1;
      }
      .subText {
        display: none;
      }
    }
    .gNavBtnWrap {
      a {
        font-size: clamp(14px, vw(15,$sp-width), 16px);
        padding: clamp(10px, vw(10,$sp-width), 14px);
        width: 100%;
        text-align: center;
        & + a {
          margin: 20px 0 0;
        }
      }
      .gNav__btn {
        &--search {
          &::before {
            width: 15px;
            height: 15px;
          }
        }
        &--gold {
          &::before {
            width: 8px;
            height: 14px;
            margin: 0 8px -2px 0;
          }
        }
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 9999;
      transform: scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      margin-top: 0;
      &List {
        background: #fff;
        box-shadow: none;
        padding: 0;
        margin-top: 0;
        border-bottom: 2px dotted #CCCCCC;
        &__inner {
          justify-content: flex-start;
        }
        &__item {
          display: block;
          margin: 0;
          width: calc(100% / 2);
          border-top: 2px dotted #CCCCCC;
          &:nth-child(2n - 1) {
            border-right: 2px dotted #CCCCCC;
            position: relative;
            &:last-child {
              &::before {
                content: "";
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                display: block;
                position: absolute;
                top: -1%;
                right: -101%;
                border-top: 2px dotted #CCCCCC;
              }
            }
          }
          &::after {
            content: none;
          }
          a {
            font-size: 1.7rem;
            padding: 27px 10px;
          }
        }
      }
      &.show {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.7);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover {
      height: 0;
      width: 100vw;
      background: #fff;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      background: transparent;
      margin: auto;
      position: absolute;
      right: per(20);
      top: 15px;
      cursor: pointer;
      z-index: 9999;
      span {
        width: 27px;
        height: 3px;
        display: block;
        background: #333333;
        position: absolute;
        top: 9px;
        right: 0;
        left: 0;
        margin: 0 auto;
        transition: width 0.2s, right 0.2s, left 0.2s;
        &.burger--top{
          transform: translateY(0);
        }
        &.burger--middle{
          transform: translateY(7px);
          position: relative;
          background: none;
          &::before{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #333333;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #333333;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
        }
        &.burger--bottom{
          transform: translateY(14px);
          width: 17px;
          left: 4px;
          right: initial;
        }
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: #333;
        .burger--top{
          left: 100%;
          width: 0px;
        }
        .burger--middle {
          &::before{
            width: 18px;
            height: 2px;
            transform: rotate(135deg);
            background: #fff;
            left: 5px;
          }
          &::after {
            width: 18px;
            height: 2px;
            transform: rotate(45deg);
            background: #fff;
            left: 5px;
          }
        }
        .burger--bottom{
          right: 100%;
          width: 0px;
        }
      }
    }
    &.show {
      position: fixed;
      animation: burger-show 1s ease-in;
    }
  }
  /*=================================
    lp--cvBner
  =================================*/
  .lp--cvBnrWrap {
    &__ttl {
      font-size: 2.4rem;
      margin-bottom: 30px;
      &::before {
        height: 100%;
      }
    }
  }
  .lp--cvBnr {
    padding: 20px 3%;
    .contact {
      display: block;
      &__wrap {
        &.tel {
          .num {
            font-size: 3rem;
            &::before {
              width: 26px;
              height: 26px;
              margin: 0 12px 0 0;
            }
          }
          .receptionTime {
            margin: 8px 0 0;
            dt {
              font-size: 1.4rem;
            }
            dd {
              font-size: 1.4rem;
            }
          }
        }
        &.mail {
          margin: 20px 0 0;
          .btn--mail {
            font-size: 2rem;
            padding: 22px 14px 17px;
            border-radius: 4px;
            box-shadow: 0px 4px 0 0px #776D37;
            & + .btn--reserve {
              margin-top: 10px;
            }
          }
          .btn--reserve {
            font-size: 2rem;
            padding: 22px 14px 17px;
            border-radius: 4px;
            box-shadow: 0px 4px 0 0px #26456B;
          }
        }
      }
    }
  }
  .lpBtn {
    &__ttl {
      font-size: 1.4rem;
      padding: 50px 18px 15px;
      box-shadow: 0 0 10px rgba(176, 176, 176, .8);
      border-radius: 5px 0 0 5px;
    }
    &.float {
      bottom: 100px;
    }
  }
}