@charset "utf-8";
/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  margin: 40px auto 34px;
  &__ttl {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    padding-left: 18px;
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 24px;
      display: inline-block;
      background: $color-theme;
      background: -moz-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: -webkit-linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      background: linear-gradient(0deg, $color-theme 0%, $color-theme 50%, $color-gold 50%, $color-gold 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#ab9b4e",GradientType=1);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.cvBnr {
  background: $bg-color;
  border: 1px solid #CCCCCC;
  padding: 40px 30px;
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    &__wrap {
      margin-left: auto;
      margin-right: auto;
      &.tel {
        text-align: center;
        .num {
          font-size: 4.2rem;
          font-weight: bold;
          text-decoration: none;
          @include mq-desktop {
            pointer-events: none;
          }
          @include mq-tab {
            font-size: vw(42);
          }
          &::before {
            content: "";
            width: 29px;
            height: 29px;
            display: inline-block;
            background: url(../img/icon/icon_tel.png) no-repeat center center;
            background-size: contain;
            margin: 0 14px 0 0;
            @include mq-tab {
              width: vw(29);
              height: vw(29);
            }
          }
        }
        .receptionTime {
          display: flex;
          margin: 10px 0 0;
          dt {
            font-size: 1.6rem;
            font-weight: bold;
            width: 5.5em;
          }
          dd {
            font-size: 1.6rem;
            width: calc(100% - 5.5em);
          }
        }
        & + .mail {
          width: 315px;
          margin: 10px 0 10px 28px;
        }
      }
      &.mail {
        text-align: center;
        .btn--mail {
          font-size: 2.1rem;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          padding: 22px 14px 17px;
          border-radius: 5px;
          background: rgb(171,155,78);
          background: -moz-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          background: linear-gradient(0deg, rgba(171,155,78,1) 0%, rgba(171,155,78,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ab9b4e",endColorstr="#ab9b4e",GradientType=1);
          box-shadow: 0px 5px 0 0px #776D37;
          text-align: center;
          display: block;
          @include animation-base(all,0s);
          @include mq-tab {
            font-size: vw(21);
            padding: 22px vw(14) 17px;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
          &::before {
            content: "";
            width: 24px;
            height: 18px;
            display: inline-block;
            background: url(../img/icon/icon_mail.png) no-repeat center center;
            background-size: contain;
            margin: 0 5px -2px 0;
            @include mq-tab {
              width: vw(24);
              height: vw(18);
            }
          }
          & + .btn--reserve {
            margin-top: 15px;
          }
        }
        .btn--reserve {
          font-size: 2.1rem;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          padding: 22px 14px 17px;
          border-radius: 5px;
          background: rgb(54,99,154);
          background: -moz-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: -webkit-linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          background: linear-gradient(0deg, rgba(54,99,154,1) 0%, rgba(54,99,154,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36639a",endColorstr="#36639a",GradientType=1);
          box-shadow: 0px 5px 0 0px #26456B;
          text-align: center;
          display: block;
          @include animation-base(all,0s);
          @include mq-tab {
            font-size: vw(21);
            padding: 22px vw(14) 17px;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
          &::before {
            content: "";
            width: 22px;
            height: 22px;
            display: inline-block;
            background: url(../img/icon/icon_calendar.png) no-repeat center center;
            background-size: contain;
            margin: 0 7px -2px 0;
            @include mq-tab {
              width: vw(22);
              height: vw(22);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    margin: 40px auto;
    &__ttl {
      font-size: 1.8rem;
      margin-bottom: 26px;
      padding-left: 14px;
      &::before {
        width: 4px;
        height: 30px;
        top: -4px;
      }
    }
  }
  .cvBnr {
    padding: 28px per(28);
    .contact {
      display: block;
      &__wrap {
        &.tel {
          .num {
            font-size: vw(28,$sp-width);
            &::before {
              width: vw(28,$sp-width);
              height: vw(28,$sp-width);
              margin: 0 4px -5px 0;
            }
          }
          .receptionTime {
            display: block;
            margin: 12px 0 0;
            text-align: left;
            dt {
              font-size: 1.4rem;
              display: block;
              width: 100%;
            }
            dd {
              font-size: 1.4rem;
              display: block;
              width: 100%;
            }
          }
          & + .mail {
            width: 100%;
            margin: 30px 0 0;
          }
        }
        &.mail {
          .btn--mail {
            font-size: vw(18,$sp-width);
            padding: 20px per(14) 15px;
            border-radius: 4px;
            &::before {
              width: 20px;
              height: 15px;
              margin: 0 6px -2px 0;
            }
            & + .btn--reserve {
              margin-top: 12px;
            }
          }
          .btn--reserve {
            font-size: vw(18,$sp-width);
            padding: 20px per(14) 15px;
            border-radius: 4px;
            &::before {
              width: 20px;
              height: 20px;
              margin: 0 6px -2px 0;
            }
          }
        }
      }
    }
  }
}